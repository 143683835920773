// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Theme, Grid ,Dialog, DialogTitle,IconButton, DialogContent, Typography, Button, DialogActions} from '@material-ui/core';
import { Link } from 'react-router-dom';
import CourseCardController,{ Course, Props, configJSON } from './CourseCardController.web';
import { FaCircle, FaCircleCheck, FaCirclePlay, FaHeart, FaStar } from 'react-icons/fa6';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import { PiVideoFill } from 'react-icons/pi';
import { board, board2, lecturesIcon } from '../../blocks/catalogue/src/assets';
import { HiMiniClock } from 'react-icons/hi2';
import { IoLanguageOutline } from 'react-icons/io5';
import { MdCurrencyRupee } from 'react-icons/md';
import NotificationAlertMessage from './NotificationAlertMessage';
import { Close as CloseIcon ,Check as CheckIcon} from '@material-ui/icons';
import {
  BundleCoursesAttributes,
  LaunchedCourses,
  LaunchedCoursesAttributes,
  getNewLaunchCoursesWeb,
  CardType,
  BundleCourses,
  getBundleCoursesWebCard
} from "../../blocks/landingpage/src/LandingPage.web";

const styles = (theme: Theme) => ({
  courses: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    gap: 16,
    alignItems: 'center',
    position: 'relative' as const,
    zIndex: 5,
    fontFamily: 'Inter',
    padding: '1px !important',
    paddingTop: "10px !important",
    paddingBottom: '30px !important',
    margin: '0 !important',
    '@media (min-width: 1080px) and (max-width: 1300px)':{
      gap: 14
    },
    '& .heartIconList': {
      width: '24px !important'
    },
    '& p': {
      margin: 0,
      padding: 0,
      cursor: 'default'
    },
    '& .MuiGrid-grid-lg-3': {
      flexBasis: "24%",
    },
    '& .card' : {
      boxShadow: '0px 1.5px 7px 0px #00000046 !important'
    },
    '& .cardContainer': {
      textDecoration: "none",
      width: "100%",
      maxWidth: '314px',
      position: 'relative' as const,
      backgroundColor: 'white',
      margin: '15px 0 !important',
      borderRadius: '15.073px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.27)',
      '@media (min-width: 1440px) and (max-width: 1480px)':{
        maxWidth: '330px !important'
      },
      '@media (min-width: 1480px) and (max-width: 1680px)':{
        maxWidth: '330px !important'
      },
      '@media (min-width: 1740px) and (max-width: 1920px)': {
        maxWidth: '345px !important'
      }
    },
    '& .info': {
      height: '20px',
      justifyContent: 'flex-start',
      '& p': {
        color: 'white',
        fontSize: '0.8rem',
        fontFamily: 'Inter'
      },
    },
    '& .course-price': {
      fontFamily: 'Inter',
      fontSize: 32,
      '@media (min-width: 1330px) and (max-width: 1440px)': {
          fontSize: 30,
     },
     '@media (min-width: 1240px) and (max-width: 1330px)': {
      fontSize: 26,
     },
    },
    '& .course-price-discount': {
      fontSize: 20,
      fontFamily: 'Inter'
    },
    '& .cartPurchased': {
     '& p': {
        display: 'flex',
        alignItems: "center"
     }
    },
    '& .cartPurchasedList': {
      '& p': {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
      }
    },
    '& .imgWrapperList': {
      width: '30%',
      maxWidth: '300px',
      display: 'flex',
      flexDirection: 'column' as const,
      justifyontent: 'center',
      alignItems: 'center',
      position: 'relative' as const,
      borderRadius: '15.073px',
      marginRight: '10px'
    },
  '& .instructor-name':{
    textWrap: 'nowrap',
    overflow: 'hidden',
  },
  '& .addcartText': {
    textWrap: 'nowrap'
  },
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 550,
      minWidth: 500
    },
  },

  dialogTitleRoot: {
    padding: theme.spacing(2),
    position: 'relative' as const,
    borderBottom: '2px solid #000'
  },
  closeButton: {
    position: 'absolute' as const,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#000',
  },

  dialogTitle: {
    textAlign: 'start' as const,
    fontWeight: 600,
    fontSize: 28.068172454833984,
    letterSpacing: -0.3255172073841095,
    color: '#000000ff',
    fontFamily: 'Inter'
  },
  dialogContent: {
    textAlign: 'start' as const,
  },
  subTitleCourseValidity: {
    fontWeight: 400,
    fontSize: 25,
    letterSpacing: -0.2899344563484192,
    color: '#000000ff',
    fontFamily: 'Inter',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word' as const,
    WebkitLineClamp: 3,
    display: "-webkit-inline-box",
    WebkitBoxOrient: "vertical" as const,
    textTransform: "capitalize" as const,
    hyphens: 'auto' as const
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative' as const
  },
  validityPopupPrice : {
    position: 'absolute' as const, 
    right: 0 , 
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
     fontFamily: 'Inter',
     fontSize: 24
  },
  checkIcon: {
    marginRight: theme.spacing(1),
    background: '#fff',
    color: '#366A76',
    borderRadius: '50%',
    fontSize: '16px',
    position: 'absolute' as const,
    left: 0
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    borderRadius: '25px',
    padding: theme.spacing(1, 3),
    fontSize: 18,
    fontFamily: 'Inter',
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  cancelButton: {
    color: '#000',
    width: '50%',
    fontFamily: 'Inter',
    background: "#EDEDED",
    fontSize: '18px',
    fontWeight: 400,
    borderRadius: 8,
    '&:hover': {
      color: '#000',
      backgroundColor: "#EDEDED"
    }
  },
  applyButton: {
    width: '50%',
    color: '#fff',
    backgroundColor: "#4C8491",
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    borderRadius: 8,
    '&:hover': {
      color: '#fff',
      backgroundColor: "#4C8491"
    }
  },
  '@media screen and (min-width: 680px)': {
    courses: {
      // justifyContent: 'space-between'
    }
  }
});
// Customizable Area End

class CourseCard extends CourseCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
  renderListCard = (course: Course) => {
    const value = course.attributes;
    const isPurchasedOrCart = value.is_purchased;
    const isInCart = value.is_purchased;
    const courseType = course.type;
    const courseImageSrc = courseType === "course" ?
      value.course_thumbnail :
      value.bundle_course_thumbnail;
    const courseCategoryColor = courseType === "course" ?
      value?.course_child_category?.color : "black";
         const courseCategoryName = courseType === "course" ?
         value?.course_child_category?.name :
         value?.course_category?.name;

       const courseRating = courseType === "course" ?
         value?.average_rating?.Average_rating :
         value.rating;

       const totalStudents = courseType === "course" ?
         value.student_count :
         value.total_student;

       const courseName = courseType === "course" ?
         value.course_name :
         value.bundle_name;
    return (
      <div data-test-id="cardWrapperList" className="cardWrapperList" style={{ padding: 13}}>
        <div className="imgWrapperList">
          <div className="thumbnailImgList" style={{ height: 173}}>
            <img
              src={courseImageSrc}
              alt="course-thumbnail-image"
              className="thumbnailList"
              style={{ height: 173 , width: '289.69px', aspectRatio: '16/9'}}
            />
          </div>
          <FaCirclePlay className="playIcon" color={"white"} />
        </div>
        <div className="listInfoLeft">
          <div className="listContent">
            <div className="courseHeadingList">
              <div className="courseNameList"
                style={{
                  borderBottom: `2px solid ${courseCategoryColor}`,
                }}
              >
                <FaCircle
                  className="circleIconList"
                  color={courseCategoryColor}
                />
                <p>{courseCategoryName}</p>
              </div>
              <div className="courseRatingInfoList">
                <FaStar className="starIcon starIconList" color={"#ffc108"} />
                <p>
                  {courseRating} | <span>{totalStudents || 0} Students</span>
                </p>
              </div>
              <div className="courseRatingInfoList">
                <img
                  src={board}
                  alt="icon-of-a-chalkboard"
                  className="boardIcon"
                />
                &nbsp;
                <p style={{ color: "#4C8491" }}>
                  Instructor: {value.instructor_name}
                </p>
              </div>
            </div>
            <div className="courseDescList">
              <p style={{fontFamily: 'Inter', fontSize: 28, fontWeight: 600, height: 68 }}>{courseName}</p>
            </div>
          </div>

          <div className="purchaseInfoList">
            <div className="priceWrapper">
              <div className="priceList">
                <LiaRupeeSignSolid className="rupeeIconList" />
                <p style={{fontFamily: 'Inter', fontSize: 47, fontWeight: 600 }}>{value.discounted_price ? value.discounted_price : "0"}/-</p>
              </div>
              <div className="priceList">
                <LiaRupeeSignSolid
                  className="rupeeIconList"
                  color={"#bdbcbc"}
                />
                <p style={{fontFamily: 'Inter', fontSize: 32, fontWeight: 600 }}>{value.first_year_price ? value.first_year_price : null}/-</p>
                <div className="stroke"></div>
              </div>
            </div>
            <div className="cartWrapper">
              <div className="wishlistIconWrapperList">
                {value.is_wishlist == "true" ? (
                  <FaHeart className="heartIconList" color={"red"} />
                ) : (
                  <FaHeart className="heartIconList" color={"DBDBDB"} />
                )}
              </div>
              <div
                className={
                  isPurchasedOrCart || isInCart
                    ? "cartPurchasedList"
                    : "cartList"
                }
              >
                {isPurchasedOrCart ? (
                  <p>
                    <FaCircleCheck color={"#00BE10"} />
                    &nbsp;{configJSON.PurchesedText}
                  </p>
                ) : isInCart ? (
                  <p>
                        <FaCircleCheck className="check" />
                        &nbsp; {configJSON.Addedtext}
                    </p>
                ) : (
                  <p>+ {configJSON.AddToCartText}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHoveredListCard = (course: Course, index: number) => {
    const value = course.attributes;
        const isPurchasedOrCart = value.is_purchased;
        const isInCart = value.is_purchased;
        const courseType = course.type;
        const list = course.type === "course" ? "newLaunchCourses" : "bundleCourses"
           const courseCategoryName = courseType === "course" ?
           value?.course_child_category?.name :
           value?.course_category?.name;

         const courseRating = courseType === "course" ?
           value?.average_rating?.Average_rating :
           value.rating;

         const totalStudents = courseType === "course" ?
           value.student_count :
           value.total_student;

         const courseName = courseType === "course" ?
           value.course_name :
           value.bundle_name;
    return (
      <div
        style={{ display: this.state.hoveredIndex === index ? "flex" : "none" }}
        className="cardWrapperList2 cardWrapperList3"
      >
        <div className="listInfoLeft2">
          <div className="courseHeadingList, courseHeadingList2">
            <div
              style={{ marginRight: "10px", width: '100%', maxWidth: 200 }}
              className="courseHeading2 courseHeading2List"
            >
              <p style={{ color: "rgb(18, 32, 193)", fontSize: 14 , fontFamily: 'Inter', fontWeight: 400, width: 160 }}>{courseCategoryName}</p>
            </div>
            <div className="courseDesc3">
              <p style={{fontFamily: 'Inter', fontSize: 28, fontWeight: 600 }}>{courseName}</p>
            </div>
          </div>
          <div className="courseDescList, courseDescList2">
            <p style={{ fontWeight: 400, fontSize: 22, fontFamily: 'Inter',height: "auto" }}>
              {value.short_description}
            </p>
          </div>
          <div className="courseInfo courseInfoList" style={{gap: 20}}>
            <div className="info infoList">
              <PiVideoFill className="infoIconList" color={"#fff"} />
              &nbsp;
              <p>{value.total_content} Chapters</p>
            </div>
            <div className="info infoList">
              <img src={lecturesIcon} alt="a-lecture-board-icon" className="boardIcon"/>
              &nbsp;
              <p>{value.course_class_count ? value.course_class_count : "0"} Lectures</p>
            </div>
            <div className="info infoList">
              <HiMiniClock className="infoIcon infoIconList" color={"#fff"} />
              &nbsp;
              <p>{typeof(value.all_course_class_duration) === 'number' ? value.all_course_class_duration.toFixed(2) : value.all_course_class_duration} Minutes</p>
            </div>
            <div className="info, infoList">
              <FaStar className="starIcon2" color={"#ffc108"} />
              &nbsp;
              <p>
              {courseRating} | <span>{totalStudents} Students</span>
              </p>
            </div>
            <div className="info, infoList">
              <img
                src={board2}
                alt="icon-of-a-chalkboard"
                className="boardIcon boardIcon2"
                style={{ width: "15px", height: "15px" }}
              />
              &nbsp;
              <p>{configJSON.Instructor}: {value.instructor_name}</p>
            </div>
            <div className="info infoList">
              <IoLanguageOutline className="infoIcon infoIconList" color={"#fff"} />
              <p>{configJSON.language}:&nbsp;</p>
              <p>{value.language_name}</p>
            </div>
          </div>
        </div>

        <div className="purchaseInfoList purchaseInfoList2">
          <div className="priceWrapper priceWrapperList">
            <div className="priceList priceList2" style={{marginTop: 12 }}>
              <LiaRupeeSignSolid className="rupeeIconList rupeeIconList2" />
              <p style={{fontFamily: 'Inter', fontSize: 47, fontWeight: 600 }}>{value.discounted_price ? value.discounted_price : 0}/-</p>
            </div>
            <div className="priceList priceList2">
              <LiaRupeeSignSolid
                className="rupeeIconList rupeeIconList2"
                color={"#bdbcbc"}
              />
              <p style={{fontFamily: 'Inter', fontSize: 32, fontWeight: 600 }}>{value.first_year_price ? value.first_year_price : 0} /-</p>
              <div style={{ border: "1px solid red" }} className="stroke"></div>
            </div>
          </div>
          <div className="cartWrapper cartWrapperList">
          {list === "bundleCourses" ? null : (
            <div data-test-id="wishlistIcon" className="wishlistIconWrapperList wishlistIconWrapperList2" 
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.handleWishlistStatus(this.stringToBoolean(value.is_wishlist), course.id)
              }}>
              {value.is_wishlist == "true" ? (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"red"}
                />
              ) : (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"#4C8491"}
                />
              )}
            </div>
          )}
            <div
            data-test-id="addToCartBtn"
              className={
                isPurchasedOrCart || isInCart
                  ? "cartPurchasedList cartPurchasedList2"
                  : "cartList cartList2"
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.handleOpenCourseValidityPopup(isPurchasedOrCart || isInCart, course, list, index)
              }}
            >
              {isPurchasedOrCart ? (
                <p style={{ color: "#316773" }}>
                  <FaCircleCheck color={"#4C8491"} />
                  &nbsp;{configJSON.PurchesedText}
                </p>
              ) : isInCart ? (
                <p>
                      <FaCircleCheck className="check" />
                      &nbsp; {configJSON.Addedtext}
                  </p>
              ) : (
                <p style={{ color: "#316773" }}>+ {configJSON.AddToCartText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "10px"
        }}
      >
        <Typography
          style={{
            display: "inline-flex",
            columnGap: "10px",
            alignItems: "center",
            fontWeight: "bold",
            margin: "auto 0",
            fontSize: value.discounted_price && value.discounted_price.toString().length > 3 ? '26px' : ''
          }}
          variant="h5"
          component="h3"
          className="course-price"
        >
          <MdCurrencyRupee color="inherit" fontSize="inherit" />
          {Math.round(value?.discounted_price || 0)}/-
        </Typography>
      </div>
    );
  }
// Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,  gridView = true} = this.props;
    const {Courses: courses, selectedValidity, courseValidityDetails ,courseListname} = this.state;
    const courseDescription = courseListname === "bundleCourses"  ? courseValidityDetails?.attributes?.description : courseValidityDetails?.attributes?.short_description;
    const course_name = courseListname === "bundleCourses"  ? courseValidityDetails?.attributes?.bundle_name : courseValidityDetails?.attributes?.course_name
    return (
        <>
        <Dialog className={classes.dialogContainer} open={this.state.CourseValidityDialog} onClose={this.onClose} style={{ padding: 24 }}>
          <DialogTitle disableTypography className={classes.dialogTitleRoot}>
            <Typography data-test-id="courseValidityTitle" className={classes.dialogTitle}>
              {configJSON.courseValidityTitle}
            </Typography>
            <IconButton className={classes.closeButton} onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="subtitle1" className={classes.subTitleCourseValidity}>
              {course_name}: {courseDescription}
            </Typography>
            <div className={classes.buttonGroup}>
              <Button
                variant="outlined"
                color="primary"
                className={`${classes.button} ${selectedValidity === 1 ? 'selected' : ''}`}
                onClick={() => this.handleSelect(1)}
              >
                <span className={classes.buttonLabel}>
                  {selectedValidity === 1 && <CheckIcon className={classes.checkIcon} />}
                  1 Year Validity 
                  <div className={classes.validityPopupPrice}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.first_year_price}</div>
                </span>
              </Button>
              {courseValidityDetails?.attributes?.second_year_price ?
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.button} ${selectedValidity === 2 ? 'selected' : ''}`}
                  onClick={() => this.handleSelect(2)}
                >
                  <span className={classes.buttonLabel}>
                    {selectedValidity === 2 && <CheckIcon className={classes.checkIcon} />}
                    2 Year Validity
                    <div className={classes.validityPopupPrice}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.second_year_price}</div>
                  </span>
                </Button>
                : null}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between',padding: "16px 24px" }}>
            <Button className={classes.cancelButton} onClick={this.onClose}>Cancel</Button>
            <Button data-test-id="applyButton" variant="contained" className={classes.applyButton} onClick={() => this.handleChartStatus(courseValidityDetails?.attributes?.is_purchased || courseValidityDetails?.attributes?.is_cart, courseValidityDetails.id, courseValidityDetails.type, this.state.indexCourse, this.state.selectedValidity)}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>

        <NotificationAlertMessage
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.oncloseAlert}
                    isOpen={this.state.isAlert}
                    data-test-id="notificationsAlertTestId"
                />
            <Grid
                container
                className={classes.courses}
                style={{display: gridView ? "grid" : 'flex', gridTemplateColumns: "1fr 1fr 1fr 1fr", flexDirection: gridView ? "row" : "column" }}
            >
                {courses.filter(course => course.attributes.status).map((course: Course, index: number) => {
                     const value = course.attributes;
                     const isPurchasedOrCart = value.is_purchased;
                     const isInCart = value.is_cart;
                     const courseType = course.type;
                     const list = course.type === "course" ? "newLaunchCourses" : "bundleCourses"
                     const courseImageSrc = courseType === "course" ?
                     value.course_thumbnail :
                     value.bundle_course_thumbnail;
                     const courseCategoryColor = courseType === "course" ?
                     value?.course_child_category?.color :
                        "black";
                        const courseCategoryName = courseType === "course" ?
                        value?.course_child_category?.name :
                        value?.course_category?.name;

                      const courseRating = courseType === "course" ?
                        value?.average_rating?.Average_rating :
                        value.rating;

                      const totalStudents = courseType === "course" ?
                        value.student_count :
                        value.total_student;

                      const courseName = courseType === "course" ?
                        value.course_name :
                        value.bundle_name;
                    if (gridView) {
                        return (
                          <React.Fragment key={course.id}>
                          {course.type === "course" ? getNewLaunchCoursesWeb(
                            ({
                              ...course,
                              isAdd: true
                            } as unknown) as LaunchedCourses,
                            index,
                            CardType.trendingCourses,
                            this.stringToBoolean,
                            this.showCoursePrice,
                            this.handleWishlistStatus,
                            this.handleOpenCourseValidityPopup
                          ) : 
                           getBundleCoursesWebCard(
                            ({
                              ...course,
                              isAdd: true
                            } as unknown) as BundleCourses,
                            index,
                            CardType.bundleCourses,
                            this.stringToBoolean,
                            this.showCoursePrice,
                            this.handleWishlistStatus,
                            this.handleOpenCourseValidityPopup
                           )
                          }
                        </React.Fragment>
                            
                        )
                    } else {
                        return (
                        <Grid
                            item
                            xs={12}
                            key={index.toString()}
                            component={Link}
                            to={`/course/${course.id}`}
                            className="cardContainerList"
                            test-id="mouseEnterLeave"
                            onMouseEnter={() => this.handleMouseEnter(index)}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            {this.renderListCard(course)}
                            {this.renderHoveredListCard(course, index)}
                        </Grid>
                        )
                    }
                })}
            </Grid>
        </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(CourseCard);
// Customizable Area End
