import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { ColorResult } from "react-color";
import { makeApiCall } from "./AdminCategoryController.web";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

export interface AdminChildCategoryResponseType {
  error:string;
  errors: { token: string }[];
  data: Array<ChildCategoryDataType>;
  meta: {
    page: string;
    total_course_child_categories: number;
    course_child_categories: string;
  };
}

export interface ChildCategoryDataType {
  id: string;
  type: string;
  attributes: {
    name: string;
    course_category_id: number;
    course_sub_category_id: number;
    color: string;
    status: boolean;
    total_courses: number;
    student_count: number;
    course_category: {
      id: number;
      name: string;
    };
    course_sub_category: {
      id: number;
      name: string;
    };
    mobile_icon: string;
    web_icon: string;
  };
}

export interface InvalidResponseType {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  adminChildCategoryData: AdminChildCategoryResponseType;
  isDrawer: boolean;
  setfullWidth: boolean;
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  loader: boolean;
  categoryName: string;
  openDeleteChildCategoryModal: boolean;
  deleteChildCatId: string | number;
  iconSubCat: any;
  iconWeb: any;
  iconSubCatName: string;
  iconWebName: string;
  iconWebError: string;
  iconSubCatError: string;
  iconWebPreviewThumbnail: string;
  iconSubCatPreviewThumbnail: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  categoryId: string | number;
  courseSubCategoryId: any;
  courseCategoryId: string;
  courseCategoryError: string;
  courseSubCategoryName: string;
  courseSubCategoryError: string;
  courseChildCategoryStatus: any;
  perPage: number;
  searchedChildCatText: string;
  isHeaderChecked: boolean;
  isChecked: boolean[];
  currentSortState: { order: string; field: string };
  childCategoryName: string;
  childCategoryErr: string;
  courseCategoryData: any;
  courseSubCategoryData: any;
  selectedColor: string;
  selectedColorError: string;
  isColorPickerOpen: boolean;
  columnSort: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminChildCategoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminChildCategoryListApiCallId: string = "";
  postAddChildCategoryApiCallId: string = "";
  deleteChildCategoryApiCallId: string = "";
  editChildCategoryApiCallId: string = "";
  showChildCategoryApiCallId: string = "";
  getAdminCourseChildCategoryListApiCallId: string = "";
  getAdminCourseCategoryListApiCallId: string = "";
  getAdminCourseSubCategoryListApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adminChildCategoryData: {
        data: [],
        meta: {
          page: "",
          total_course_child_categories: 0,
          course_child_categories: ""
        },
        errors: [{ token: "" }],
        error: ""
      },
      isColorPickerOpen: false,
      isDrawer: true,
      setfullWidth: true,
      currentPageCount: 1,
      pageCount: 0,
      userToken: "",
      loader: true,
      categoryName: "",
      openDeleteChildCategoryModal: false,
      iconSubCat: "",
      iconWeb: "",
      deleteChildCatId: "",
      iconSubCatName: "",
      iconWebName: "",
      iconWebError: "",
      iconSubCatError: "",
      iconWebPreviewThumbnail: "",
      iconSubCatPreviewThumbnail: "",
      isAlert: false,
      currentSortState: { order: "desc", field: "id_sort" },
      alertMsg: "",
      isChecked: [],
      alertType: "success",
      isUpdate: false,
      categoryId: "",
      courseCategoryId: "",
      courseCategoryError: "",
      courseSubCategoryError: "",
      courseSubCategoryId: "",
      isHeaderChecked: false,
      courseSubCategoryName: "",
      courseChildCategoryStatus: true,
      perPage: elementsPerPage,
      searchedChildCatText: "",
      childCategoryName: "",
      childCategoryErr: "",
      courseCategoryData: [],
      courseSubCategoryData: [],
      selectedColor: "",
      selectedColorError: "",
      columnSort: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const childCatResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isChildCatValidResponse(childCatResponseJson)) {
        if (!childCatResponseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, childCatResponseJson);
        } else {
          this.parseErrors(childCatResponseJson)
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let ChildCatData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (ChildCatData) {
        this.handleShowChildCategory(ChildCatData.ScreenTest);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    await this.handleFilterChildCategory(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.searchedChildCatText,
      this.state.columnSort
    );
  }

  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  
  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getCourseCategoryDataFunction();
  };

  isChildCatValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: AdminChildCategoryResponseType
  ) => {
    if (apiRequestCallId === this.getAdminChildCategoryListApiCallId) {
      this.handleGetAdminChildCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteChildCategoryApiCallId) {
      this.handleDeleteAdminChildCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.getAdminCourseCategoryListApiCallId) {
      this.handleGetAdminCourseCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.getAdminCourseSubCategoryListApiCallId) {
      this.handleGetAdminCourseSubCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.postAddChildCategoryApiCallId) {
      this.handleAddAdminChildCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.showChildCategoryApiCallId) {
      this.handleShowAdminChildCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.editChildCategoryApiCallId) {
      this.handleEditAdminChildCategoryListResponse(responseJson);
    }
    if (apiRequestCallId === this.toggleStatusApiCallId) {
      this.handleToggleStatusResponse(responseJson);
    }
  };

  handleToggleStatusResponse = (responseJson: any) => {
    if (
      responseJson?.meta?.message ==
      "Course Child Category status changed successfully"
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Course Child Category status changed successfully",
        alertType: "success"
      });
      this.handleFilterChildCategory(
        this.state.currentPageCount,
        this.state.perPage,
        this.state.searchedChildCatText,
        this.state.columnSort
      );
    }
  };

  handleShowAdminChildCategoryListResponse = (response: any) => {
    const { attributes } = response.data;
    this.setState({
      iconSubCatName: attributes.mobile_icon,
      iconWebName: attributes.web_icon,
      childCategoryName: attributes.name,
      isUpdate: true,
      courseSubCategoryId: attributes.course_sub_category_id,
      courseCategoryId: attributes.course_category_id,
      selectedColor: attributes.color,
      courseChildCategoryStatus: attributes.status,
      categoryId: response.data.id,
      iconWebPreviewThumbnail: attributes.web_icon,
      iconSubCatPreviewThumbnail: attributes.mobile_icon
    });
    this.handleShowSubCategoryList(attributes.course_category_id);
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    if (
      !this.state.adminChildCategoryData.data ||
      !this.state.adminChildCategoryData.data.length
    ) {
      return;
    }
    let clone = [...this.state.adminChildCategoryData.data];
    clone[index].attributes.status = !status;

    this.setState((prevState) => ({
      adminChildCategoryData: {
        ...prevState.adminChildCategoryData,
        data: clone
      }
    }));

    this.toggleStatusApiCallId = makeApiCall({
      method: configJSON.httpPutType,
      endPoint: `${configJSON.childCategoryStatusUpdateApiEndpoint}/${id}`,
      body: { change_status: !status },
      contentType: configJSON.categoryApiContentType,
      token: this.state.userToken
    });
  }

  handleGetAdminCourseCategoryListResponse = (
    response: AdminChildCategoryResponseType
  ) => {
    this.setState({
      courseCategoryData: response.data,
      loader: false
    });
  };

  handleGetAdminCourseSubCategoryListResponse = (
    response: AdminChildCategoryResponseType
  ) => {
    this.setState({
      courseSubCategoryData: response.data,
      loader: false
    });
  };

  toggleColorPicker(isColorPickerOpen: boolean) {
    this.setState({ isColorPickerOpen });
  }

  handleGetAdminChildCategoryListResponse = (
    response: AdminChildCategoryResponseType
  ) => {
    const { meta } = response;
    if (meta?.course_child_categories === configJSON.catRecordNotFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: configJSON.catRecordNotFoundMessage,
        alertType: "info",
        loader: false,
        pageCount: 0,
        adminChildCategoryData: {
          data: [],
          meta: {
            page: "",
            total_course_child_categories: 0,
            course_child_categories: ""
          },
          errors: [{ token: "" }],
          error: ""
        }
      });
    } else if (response && response?.errors) {
      if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
        this.navigationLoginScreen();
      }
    } else {
      const pageCount = Math.ceil(
        meta.total_course_child_categories / this.state.perPage
      );
      this.setState({
        adminChildCategoryData: response,
        loader: false,
        pageCount: pageCount
      });
    }
  };

  handleDeleteAdminChildCategoryListResponse = (
    response: AdminChildCategoryResponseType
  ) => {
    if(response.error){
      this.setState({
        deleteChildCatId: "",
        openDeleteChildCategoryModal: false,
        isAlert: true,
        loader: false,
        alertType: "error",
        alertMsg: response.error,
      })
    }else{
    this.setState(
      {
        openDeleteChildCategoryModal: false,
        deleteChildCatId: "",
        loader: false,
        isAlert: true,
        alertMsg: "Child Category Deleted Successfully",
        alertType: "success"
      },
      () =>
        this.handleFilterChildCategory(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.searchedChildCatText,
          this.state.columnSort
        )
    );
  };
}

  handleAddAdminChildCategoryListResponse = (response: any) => {
    const { data } = response;
    let alertMsg, alertType: any;
    if (data.mobile_icon || data.web_icon || data.course_sub_category) {
      alertMsg = data.mobile_icon
        ? data.mobile_icon[0]
        : data.web_icon
        ? data.web_icon[0]
        : data.course_sub_category[0];
      alertType = "error";
    } else {
      alertMsg = "Child Category Created Successfully";
      alertType = "success";
      this.handleFeildReset();
      this.handleChildCategoryNavigation();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleFeildReset = () => {
    this.setState({
      iconSubCat: "",
      iconWeb: "",
      iconSubCatName: "",
      iconWebName: "",
      childCategoryName: "",
      courseCategoryId: "",
      courseSubCategoryId: "",
      selectedColor: ""
    });
  };

  handleShowSubCategoryList = async (id: string | number) => {
    this.getAdminCourseSubCategoryListApiCallId =
      makeApiCall({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpGetType,
        endPoint: `bx_block_course/course_sub_category/get_sub_category_based_category?course_category_id=${id}`,
      token: this.state.userToken
      });
  };

  handleShowChildCategory = async (id: string | number) => {
    this.showChildCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.childCategoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  handleDeleteChildCategory = async (id: string | number) => {
    this.setState({ loader: true });
    this.deleteChildCategoryApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpDeleteType,
      endPoint: `${configJSON.childCategoryAPIEndPoint}/${id}`,
      token: this.state.userToken
    });
  };

  handleFilterChildCategory = async (
    pageNo: number,
    per_page: number,
    searchedChildCatString: string,
    sort: string
  ) => {
    this.setState({ loader: true });
    this.getAdminChildCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${
        configJSON.childCategoryAPIEndPoint
      }/search?page=${pageNo}&per_page=${per_page}&child_category_search_params=${searchedChildCatString}&${
        sort || ""
      }`,
      token: this.state.userToken
    });
  };

  handleChildCategorySearch = (e: { target: { value: string } }) => {
    const searchedChildCatVal = e.target.value;
    this.setState(
      { searchedChildCatText: searchedChildCatVal, currentPageCount: 1 },
      () => {
        this.handleFilterChildCategory(
          this.state.currentPageCount,
          this.state.perPage,
          searchedChildCatVal,
          this.state.columnSort
        );
      }
    );
  };

  getCourseCategoryDataFunction = async () => {
    this.setState({ loader: true });
    this.getAdminCourseCategoryListApiCallId = makeApiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.courseCategoryAPIListEndPoint,
      token: this.state.userToken
    });
  };

  checkChildCategoryFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "childCategoryName",
        errorKey: "childCategoryErr",
        errorMessage: "Child Category Name is Required",
        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/
      },
      {
        field: "iconWebName",
        errorKey: "iconWebError",
        errorMessage: "Web Icon is Required"
      },
      {
        field: "iconSubCatName",
        errorKey: "iconSubCatError",
        errorMessage: "App Icon is Required"
      },
      {
        field: "selectedColor",
        errorKey: "selectedColorError",
        errorMessage: "Color is Required"
      },
      {
        field: "courseCategoryId",
        errorKey: "courseCategoryError",
        errorMessage: "Main Category Name is Required"
      },
      {
        field: "courseSubCategoryId",
        errorKey: "courseSubCategoryError",
        errorMessage: "Sub Category Name is Required"
      }
    ];

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
      if (
        !updatedValues[field] ||
        updatedValues[field]?.length === 0 ||
        (regex && !regex.test(updatedValues[field].trim()))
      ) {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: errorMessage
        }));
        hasError = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  handlePostChildCategory = async () => {
    this.setState({ loader: true });
    const {
      courseCategoryId,
      courseSubCategoryId,
      childCategoryName,
      courseChildCategoryStatus,
      selectedColor,
      iconSubCat,
      iconSubCatName,
      iconWeb,
      iconWebName
    } = this.state;
    const stateValues = {
      courseCategoryId,
      courseSubCategoryId,
      childCategoryName,
      selectedColor,
      iconSubCatName,
      iconWebName
    };

    if (!this.checkChildCategoryFormValidations(stateValues)) {
      const formDataValues = {
        courseCategoryId,
        courseSubCategoryId,
        childCategoryName,
        courseChildCategoryStatus,
        selectedColor,
        iconSubCat,
        iconSubCatName,
        iconWeb,
        iconWebName
      };
      const formdata = this.appendFormData(formDataValues, false);
      this.postAddChildCategoryApiCallId = makeApiCall({
        method: configJSON.httpPostType,
        endPoint: configJSON.childCategoryAPIEndPoint,
        body: formdata,
        type: "formdata",
      token: this.state.userToken
      });
    } else {
      this.setState({ loader: false });
    }
  };

  handleEditChildCategory = async () => {
    this.setState({ loader: true });
    const {
      categoryId,
      courseCategoryId,
      courseSubCategoryId,
      childCategoryName,
      courseChildCategoryStatus,
      selectedColor,
      iconSubCat,
      iconSubCatName,
      iconWeb,
      iconWebName
    } = this.state;
    const stateValues = {
      courseCategoryId,
      courseSubCategoryId,
      childCategoryName,
      selectedColor,
      iconSubCatName,
      iconWebName
    };

    if (!this.checkChildCategoryFormValidations(stateValues)) {
      const formDataValues = {
        courseCategoryId,
        courseSubCategoryId,
        childCategoryName,
        courseChildCategoryStatus,
        selectedColor,
        iconSubCat,
        iconSubCatName,
        iconWeb,
        iconWebName
      };
      const formdata = this.appendFormData(formDataValues, true);
      this.editChildCategoryApiCallId = makeApiCall({
        method: configJSON.httpPutType,
        endPoint: `${configJSON.childCategoryAPIEndPoint}/${categoryId}`,
        body: formdata,
        type: "formdata",
      token: this.state.userToken
      });
    } else {
      this.setState({ loader: false });
    }
  };

  appendFormData(
    {
      courseCategoryId,
      courseSubCategoryId,
      childCategoryName,
      courseChildCategoryStatus,
      selectedColor,
      iconSubCat,
      iconSubCatName,
      iconWeb,
      iconWebName
    }: any,
    isEdit: boolean
  ) {
    const formdata = new FormData();
    formdata.append(
      "course_child_category[course_category_id]",
      courseCategoryId
    );
    formdata.append(
      "course_child_category[course_sub_category_id]",
      courseSubCategoryId
    );
    formdata.append("course_child_category[name]", childCategoryName);
    formdata.append("course_child_category[status]", courseChildCategoryStatus);
    formdata.append("course_child_category[color]", selectedColor);
    if (isEdit) {
      if (iconSubCat)
        formdata.append(
          "course_child_category[mobile_icon]",
          iconSubCat,
          iconSubCatName
        );
      if (iconWeb)
        formdata.append(
          "course_child_category[web_icon]",
          iconWeb,
          iconWebName
        );
    } else {
      formdata.append(
        "course_child_category[mobile_icon]",
        iconSubCat,
        iconSubCatName
      );
      formdata.append("course_child_category[web_icon]", iconWeb, iconWebName);
    }
    return formdata;
  }

  handleEditAdminChildCategoryListResponse = (responseJson: any) => {
    const { data } = responseJson;
    let alertMsg, alertType: any;
    if (data.mobile_icon || data.web_icon) {
      alertMsg = data.mobile_icon ? data.mobile_icon[0] : data.web_icon[0];
      alertType = "error";
    } else {
      alertMsg = "Child Category Edited Successfully";
      alertType = "success";
      this.handleFeildReset();
      this.handleChildCategoryNavigation();
    }
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
  };

  handleChildCatSorting(childCatField: string) {
    const sortParams: Record<string, string> = {
      "S.No": "id_sort",
      "Child Category": "child_category_name_sort",
      Status: "status_sort"
    };
    let columnSort = "";
    const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";
    if (sortParams.hasOwnProperty(childCatField)) {
      columnSort = `${sortParams[childCatField]}=${sortOrder}`;
    }
    this.setState( ({
      currentSortState:  { order: sortOrder, field: childCatField },
      columnSort
    }));
    this.handleFilterChildCategory(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.searchedChildCatText,
      columnSort
    );
  }

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  onPageChangeChildCategory = (event: ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        currentPageCount: value
      },
      () => {
        this.handleFilterChildCategory(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.searchedChildCatText,
          this.state.columnSort
        );
      }
    );
  };

  handleClickCheckBox = (event: { target: { checked: boolean } }) => {
    this.setState({
      courseChildCategoryStatus: event.target.checked
    });
  };

  handleCloseDeleteChildCategoryModal = () => {
    this.setState({
      openDeleteChildCategoryModal: false,
      deleteChildCatId: ""
    });
  };

  handleOpenDeleteChildCategoryModal = (id: string) => {
    this.setState({
      openDeleteChildCategoryModal: true,
      deleteChildCatId: id
    });
  };

  handleChildCatHeaderCheckboxChange = () => {
    this.setState((previousState) => {
      const isHeaderChecked = !previousState.isHeaderChecked;
      const isChecked = Array(
        this.state.adminChildCategoryData.data.length
      ).fill(isHeaderChecked);
      return {
        isHeaderChecked,
        isChecked
      };
    });
  };

  handleChildCatRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      const isHeaderChecked = isChecked.every((value) => value);
      return {
        isChecked,
        isHeaderChecked
      };
    });
  };

  handleSubCatSelectChange = (id: string) => {
    this.setState({
      courseSubCategoryId: id
    });
  };

  handleCategorySelectChange = (id: string) => {
    this.setState(
      {
        courseCategoryId: id
      },
      () => {
        this.handleShowSubCategoryList(this.state.courseCategoryId);
      }
    );
  };

  handleChildCategoryName = (event: { target: { value: string } }) => {
    const value = event.target.value;

    this.setState({
      childCategoryName: event.target.value,
      childCategoryErr: value ? "" : "Child category name is required"
    });
  };

  handleColorChange = (color: ColorResult) => {
    this.setState({ selectedColor: color.hex });
  };

  handleAddChildCategoryNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminChildCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleEditPropsChildCategory = (id: string | number) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminChildCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };

  handleChildCategoryNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAdminChildCategoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}
