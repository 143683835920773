import React from "react";

// Customizable Area Start
import { Box, Button, FormControl, Typography ,styled} from "@material-ui/core";
import moment from "moment";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./EducationalUserProfile.css";
import Pagination from "@material-ui/lab/Pagination";
import MainLayout from "../../../components/src/MainLayout.web";
import AddIcon from "@material-ui/icons/Add";
import { webStyles } from "../../couponcodegenerator/src/AddCouponcodegenerator.web";
import {
  DateInput,
  InputBox,
  OneColBox,
  ProfilePicInput,
  StyledSelect,
  TwoColBox,
} from "../../../components/src/GenericInputs";

const StyleGenderInput = styled(FormControl)(() => ({
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    fontFamily: "'Inter'",
    fontSize: '0.9rem',
    color: "rgb(25, 34, 82)"
  },
}))

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      "& .Mui-selected": {
        backgroundColor: "#176876",
        color: "white",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      display: "none",
    },
  })
)(Pagination);

const webStyle = {
  modalStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperStyle: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    width: "400px",
    borderRadius: "20px",
  },
  deleteCloseMain: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  cancelBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    color: "black",
    fontSize: "0.875rem",
    fontWeight: 600,
    background: "white",
    border: "1px solid grey",
    marginTop: "20px",
  },
  headerText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
  },
  updateResetBtnMain: {
    display: "flex",
    marginBottom: "30px",
    justifyContent: "center",
    columnGap: "10px",
  },
  deleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    border: " none",
    color: " white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: " #b91111",
    marginTop: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem 1rem",
    rowGap: "1rem",
  },
  formField: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "15px",
    paddingBottom: "20px",
  },
  sections: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "15px",
  },
  labelText: {
    color: " #192252",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  mainLayout: {
    width: "97%",
    margin: "0 20px",
  },
  errorText: {
    color: "red",
    fontSize: "0.625rem",
  },
  detailsText: {
    display: "-webkit-box",
    webkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    overflow: "hidden",
  },
  paginationViewText: { color: "#176876" },
};
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";
import {
  CheckBtn,
  IdFullnamePhoneEmailCols,
  TableBody,
  TableStyle,
  userProfileWebStyles,
} from "./EducationalUserProfile.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class InstructorUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleInstructorSearchText(e)}
        backData={() => {
          this.handleInstructorDataReset();
          this.setState({ isAddInstructor: false, isEditInstructor: false });
        }}
        isLoading={this.state.isLoading}
        title={this.determineInstructorTitle()}
        isAdd={this.state.isAddInstructor}
        isEdit={this.state.isEditInstructor}
        actionButtons={[
          {
            icon: <AddIcon />,
            iconType: "jsxElement",
            onClick: () => this.setState({ isAddInstructor: true }),
            text: "Add Instructor",
            testId: "addAdminTestId",
          },
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {!this.state.isAddInstructor && !this.state.isEditInstructor && (
          <main className="table_data">{this.instructorList()}</main>
        )}
        {this.state.isAddInstructor && (
          <main className="table_content">{this.addInstructor()}</main>
        )}
        {this.state.isEditInstructor && (
          <main className="table_content">{this.addInstructor()}</main>
        )}
      </MainLayout>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  addInstructor() {
    const { instructorFormData, errors, isEditInstructor } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Add Image*</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                {this.state.previewThumbnail ? (
                  <ProfilePicInput
                    onChange={(e) => this.handleFileChange(e)}
                    onRemove={() => this.handleFileRemove()}
                    data-test-id="fileChange"
                    name="instructorImgName"
                    previewThumbnail={this.state.previewThumbnail}
                  />
                ) : (
                  <label
                    style={{
                      padding: "10px",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "2px dashed #ccc",
                      cursor: "pointer",
                      width: "98%",
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "center",
                      color: "#192252",
                      background: "white",
                    }}
                  >
                    {this.state.selectedFile || this.state.selectedFileName ? (
                      <Typography>{this.state.selectedFileName} </Typography>
                    ) : (
                      <Typography>+ UPLOAD PROFILE PICTURE</Typography>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      data-test-id="fileChange"
                      name="instructorImgName"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleFileChange(e)}
                    />
                  </label>
                )}
                <span style={webStyle.errorText}>{this.state.fileErr}</span>
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Typography className="box__label">Full Name*</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="instructorfullNameChanges"
                id="outlined-basic"
                variant="outlined"
                name="instructorName"
                fullWidth
                value={instructorFormData.instructorName}
                onChange={(e) => {
                  this.handleInstructorChange(e);
                }}
              />
              <span style={webStyle.errorText} data-test-id="instructorNameErr">{errors.instructorName}</span>
            </FormControl>
          </OneColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Gender*</Typography>
              <StyleGenderInput variant="outlined" className="box__input" fullWidth>
                <StyledSelect
                  fullWidth={true}
                  onChange={(e) => {
                    this.handleInstructorChange(e);
                  }}
                  disableUnderline
                  name="instructorGender"
                  value={instructorFormData.instructorGender}
                  data-test-id="selectGender"
                  IconComponent={IoIosArrowDown}
                >
                  <option value="" disabled>{"Select Gender"}</option>
                  <option value="Male">{"Male"}</option>
                  <option value="Female">{"Female"}</option>
                  <option value="Other">{"Other"}</option>
                </StyledSelect>
                <span style={webStyle.errorText}>
                  {errors.instructorGender}
                </span>
              </StyleGenderInput>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">Date of Birth*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <DateInput
                  onChange={(e) => this.handleInstructorChange(e)}
                  data-test-id="dateChange"
                  inputProps={{
                    inputProps: {
                      max: moment().subtract(18, "years").format("YYYY-MM-DD")
                    },
                  }}
                  style={{
                    color: instructorFormData.instructorDOB
                      ? ""
                      : "transparent",
                    fontFamily: 'Inter'
                  }}
                  value={instructorFormData.instructorDOB}
                  name="instructorDOB"
                />

                <span style={webStyle.errorText}>{errors.instructorDOB}</span>
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Typography className="box__label">Qualifications*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="qualification"
                id="outlined-basic"
                variant="outlined"
                name="instructorQualifification"
                fullWidth
                value={instructorFormData.instructorQualifification}
                onChange={(e) => {
                  this.handleInstructorChange(e);
                }}
              />
              <span style={webStyle.errorText} data-test-id="qualificationErr">
                {errors.instructorQualifification}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography className="box__label">Teaching*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="lecturesTest"
                id="outlined-basic"
                variant="outlined"
                name="instructorLectures"
                fullWidth
                value={instructorFormData.instructorLectures}
                onChange={(e) => {
                  this.handleInstructorChange(e);
                }}
              />
              <span style={webStyle.errorText}>
                {errors.instructorLectures}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography className="box__label">Details/Quote*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="detailsTest"
                id="outlined-basic"
                variant="outlined"
                name="instructorDetails"
                value={instructorFormData.instructorDetails}
                onChange={(e) => {
                  this.handleInstructorChange(e);
                }}
              />
              <span style={webStyle.errorText}>{errors.instructorDetails}</span>
            </FormControl>
          </OneColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Email*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  type="text"
                  data-test-id="emailTest"
                  id="outlined-basic"
                  variant="outlined"
                  name="instructorEmail"
                  fullWidth
                  value={instructorFormData.instructorEmail}
                  onChange={(e) => {
                    this.handleInstructorChange(e);
                  }}
                />
                <span style={webStyle.errorText}>{errors.instructorEmail}</span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">Mobile*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  fullWidth
                  id="instructorMobile"
                  name="instructorMobile"
                  value={instructorFormData.instructorMobile}
                  onChange={(e) => {
                    this.handleInstructorChange(e);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  data-test-id="dateChange"
                />
                <span style={webStyle.errorText}>
                  {errors.instructorMobile}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <Box style={userProfileWebStyles.addResetBtnMain}>
            <Button
              data-test-id="resetDataInstructor"
              style={webStyles.resetBtn}
              variant="outlined"
              onClick={() => this.handleInstructorDataReset()}
            >
              Reset
            </Button>
            <Button
              style={webStyles.createBtn}
              data-test-id="addDataInstructor"
              onClick={() => this.handleAddInstructor()}
            >
              {isEditInstructor ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </div>
    );
  }

  deleteModal(id: any) {
    return (
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "100%",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          minWidth: "120px",
          padding: "20px",
        }}
      >
        <h3>Are your sure to delete this instructor?</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "10px",
          }}
        >
          <button
            data-test-id="deleteInstructorModal"
            style={webStyle.deleteBtn}
            onClick={() => this.deleteInstructorData(id)}
          >
            Delete
          </button>
          <button
            data-test-id="cancelDeleteModal"
            style={webStyle.cancelBtn}
            onClick={() => this.deleteInstructorClose()}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  tableBody(row: any, index: number) {
    return (
      <>
        <tr key={row.id}>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                data-test-id="instructorCheckboxChange"
                type="checkbox"
                style={userProfileWebStyles.input}
                checked={this.state.isChecked[index]}
                onChange={() => this.handleRowCheckboxChange(index)}
              />
            </Box>
          </td>
          <IdFullnamePhoneEmailCols row={row} breakWord />
          <td style={{ textAlign: "left" }}>
            {row.attributes.leacture_in && row.attributes.leacture_in.length > 20
              ? row.attributes.leacture_in.slice(0, 40) + "..."
              : row.attributes.leacture_in}
          </td>
          <td>
            <CheckBtn className="switchs">
              <input
                type="checkbox"
                checked={row.attributes?.activated}
                data-test-id="statusTestId"
                onClick={(e: any) =>
                  this.handleToggleStatus(
                    row.id,
                    !row.attributes?.activated,
                    index,
                    "instructorData"
                  )
                }
              />
              <span className="slider round"></span>
            </CheckBtn>
          </td>
          <td style={{ borderRadius: "00px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id="editInstructor"
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={(e) => {
                  this.handleEditInstructorData(row);
                }}
              />
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <FaRegTrashAlt
                  data-test-id="deleteDataInstructor"
                  className="desc_edit_icon"
                  color={"black"}
                  size={17}
                  onClick={(e) => {
                    this.deleteInstructor(row.id);
                  }}
                />
                {this.state.isInstructorDeleteModalOpen &&
                  this.state.instructorId == row.id &&
                  this.deleteModal(this.state.instructorId)}
              </div>
            </Box>
          </td>
        </tr>
        <Box
          className="table_divider"
          style={userProfileWebStyles.tableDivider}
        ></Box>
      </>
    );
  }

  isCurrentSort(name: string) {
    return (
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc"
    );
  }

  tableHeader() {
    return (
      <thead>
        <tr>
          <th>
            <Box
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <input
                onChange={this.handleHeaderCheckboxChange}
                style={userProfileWebStyles.input}
                data-test-id="headerCheckboxChange"
                type="checkbox"
                checked={this.state.isHeaderChecked}
              />
            </Box>
          </th>
          {this.state.instructorColHeader.length > 0 &&
            this.state.instructorColHeader.map((item, index) => (
              <th
                key={index}
                style={
                  index === 1 || index === 4
                    ? { ...(index === 1 ? { width: 200 } : { width: 300 }) }
                    : {}
                }
              >
                <Box className="th_box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        index >= 0 && index < 5 ? "start" : "center",
                      alignItems: "center",
                      columnGap: "5px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      className="title_th"
                      style={{ maxWidth: "150px" }}
                    >
                      {item}
                    </Typography>
                    {["S.No", "Instructor Name", "Status"].includes(item) && (
                      <IoIosArrowDown
                        className={`filter_icon ${this.isCurrentSort(item) ? "active" : ""}`}
                        data-test-id="filterData"
                        onClick={() => this.filterDataOnCol(item, "instructor")}
                        style={{
                          transform: this.isCurrentSort(item)
                            ? "none"
                            : "rotate(180deg)",
                            width: this.isCurrentSort(item)  ? '20px' : "15px",
                            height: this.isCurrentSort(item)  ? '20px' : "15px"
                        }}
                      />
                    )}
                  </div>
                </Box>
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  instructorList() {
    return (
      <Box>
        <TableStyle>
          {this.tableHeader()}
          <span className="dividerLine"></span>
          <TableBody>
            {this.state.instructorData?.length > 0 &&
              this.state.instructorData?.map((row: any, index: number) =>
                this.tableBody(row, index)
              )}
          </TableBody>
        </TableStyle>
        <Box style={userProfileWebStyles.paginationViewAll}>
          <StyledPagination
            count={this.state.instructorPageCount}
            page={this.state.instructorPageNo}
            onChange={this.handleInstructorPageChange}
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
            classes={{ ul: "custom-pagination" }}
          />
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
