// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Grid,
  Chip,
  Divider,
  withStyles,
  createStyles,
  styled,
  Theme,
  Button,
  AccordionDetails
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LiaRupeeSignSolid } from "react-icons/lia";
import Skeleton from "@material-ui/lab/Skeleton";
import MyCoursesDetailController, {
  Props
} from "./MyCourseDetailController.web";
import LinearProgress from "@material-ui/core/LinearProgress";
import { PiVideoLight } from "react-icons/pi";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { FaCheckCircle, FaStar } from "react-icons/fa";
import { GoShareAndroid } from "react-icons/go";
import { IoIosHeartEmpty } from "react-icons/io";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { webStylesLocal } from "./MyCourse.web";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import {
  CourseDetailsAttributes,
  SpecfifcContentDuration
} from "./CourseDetailController.web";
import { setStorageData } from "framework/src/Utilities";
import { Accordion, AccordionSummary } from "./CourseDetail.web";

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class MyCourseDetail extends MyCoursesDetailController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  toggleDrawer() {
    const value = !this.state.isDrawerOpen;
    this.setState(
      { isDrawerOpen: value },
      async () => await setStorageData("isCourseDrawerOpen", `${value}`)
    );
  }

  accordionHeader(isExpanded: boolean, v: SpecfifcContentDuration) {
    return (
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <RemoveIcon htmlColor="white" />
          ) : (
            <AddIcon htmlColor="white" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        key={v.id}
      >
        <Typography
          component="h6"
          style={{
            fontWeight: "bold"
          }}
        >
          {v.name}
        </Typography>
        <Typography
          component="span"
          style={{ marginLeft: "auto", marginRight: 30 }}
        >
          {v.class_count} Classes
        </Typography>
        <Typography component="span">
          {this.convertMinutesToTimeFormat(`${v.duration_count}`)} Mins
        </Typography>
      </AccordionSummary>
    );
  }

  renderAccordion(content: SpecfifcContentDuration[]) {
    return (
      <>
        {content.map(v => {
          const isExpanded = !!this.state.openedAccordions.find(
            x => x === v.id
          );
          return (
            <Accordion
              expanded={isExpanded}
              data-test-id="accordionTestId"
              onChange={() => this.handleAccordionChange(v.id)}
            >
              {this.accordionHeader(isExpanded, v)}
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column", gap: 5 }}
              >
                {v.classes_data.map((val, i) => (
                  <Link
                    to={`/MyCoursesDetail/${this.state.courseId}/${
                      this.state.currentCourse
                    }/${v.id}/${val.id}`}
                    className="content-list-item"
                    style={{
                      borderBottom:
                        v.classes_data.length === i ? "" : "1px solid black"
                    }}
                  >
                    <Typography
                      component="h6"
                      variant="h6"
                      className="accordion-text"
                    >
                      <MdOutlinePlayCircleFilled
                        fontSize={"inherit"}
                        color="inherit"
                      />
                      {val.title}
                    </Typography>
                    <Typography component="h6" variant="h6">
                      {this.convertMinutesToTimeFormat(val.duration)} MINS
                    </Typography>
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  }

  renderOverview(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography
          variant="h4"
          component="h5"
          style={{ margin: "30px 0px 0px 0px" }}
        >
          {value.course_name}
        </Typography>
        <Typography component="span">{value.short_description}</Typography>
        <Typography
          variant="h5"
          component="h6"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Chip
            style={{ color: "white", background: "#FB7303" }}
            label={"Bestseller"}
            data-test-id="itemsCounter"
          />
          <Typography
            variant="h4"
            component="h6"
            style={{
              marginRight: "auto",
              display: "inline-flex",
              alignItems: "center",
              gap: 3,
              marginLeft: 15
            }}
          >
            <Typography
              variant="h5"
              component="strong"
              style={{
                color: "#2E5E69",
                display: "inline-flex",
                alignItems: "center",
                gap: 5
              }}
            >
              <FaStar color="ffc107" /> {value.average_rating.Average_rating}
            </Typography>
            <Typography
              variant="h5"
              component="strong"
              style={{ color: "#979797" }}
            >
              | {value.student_count}
            </Typography>
          </Typography>
          <div>
            <GoShareAndroid />
            <IoIosHeartEmpty />
          </div>
        </Typography>
        <div>
          <Typography
            variant="h6"
            component={"h4"}
            style={{ marginBottom: "0.5rem" }}
          >
            WHAT YOU WILL LEARN
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              marginBottom: 20
            }}
          >
            {value.what_learn.map(item => (
              <Typography
                component="span"
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#676767"
                }}
              >
                <FaCheckCircle
                  fontSize="inherit"
                  style={{ background: "white", borderRadius: "100px" }}
                  color="#32ba7c"
                />
                {item}
              </Typography>
            ))}
          </div>
        </div>
        <Divider light />
        <div
          style={{
            display: "inline-flex",
            gap: 15,
            marginTop: 20,
            alignItems: "center"
          }}
        >
          <div style={webStylesLocal.cashContainer}>
            <div
              style={{ ...webStylesLocal.cashDisplay, padding: "0.5rem 1rem" }}
            >
              <div>
                <LiaRupeeSignSolid style={{ color: "black" }} />
              </div>
              <Typography variant="h4" component="h6">
                {value.first_year_price.toFixed(2)}
              </Typography>
            </div>
          </div>
          {value.second_year_price && (
            <div className="middle-line">
              <div>
                <LiaRupeeSignSolid style={{ color: "#D4D3D3" }} />
              </div>
              <Typography variant="h5" component="h6">
                {value.second_year_price.toFixed(2)}
              </Typography>
            </div>
          )}{" "}
          {value.discounted_price ? (
            <Typography
              variant="h5"
              component="h6"
              style={{ color: "#FF5F5F" }}
            >
              {value.discounted_price}% OFF
            </Typography>
          ) : null}
        </div>
      </div>
    );
  }

  renderCourse(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "12px 3px"
          }}
        >
          <Typography component="span">
            {value.total_content} Chapters <BsDot /> {value.course_class_count}{" "}
            Lectures
            <BsDot />{" "}
            {this.convertToHoursAndMinutes(
              value.all_course_class_duration,
              true
            )}{" "}
            Total Long
          </Typography>
          <Button
            endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
            variant="outlined"
            data-test-id="expandAllAccordions"
            style={styles.courseViewAll}
            onClick={() =>
              this.handleExpandAllAccordions(
                value.specfifc_content_duration.map(e => e.id)
              )
            }
          >
            <Typography variant="h6" style={styles.viewAllText}>
              EXPAND ALL
            </Typography>
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {this.renderAccordion(value.specfifc_content_duration)}
        </div>
      </div>
    );
  }

  renderTab(value: CourseDetailsAttributes) {
    switch (this.state.currentTab) {
      case 0: // Overview
        return this.renderOverview(value);
      case 1: // COURSE CONTENT
        return this.renderCourse(value);
      case 2: // ASSIGNMENT
        break;
      case 3: // RATINGS
        break;
    }
  }

  renderLeftColumn() {
    return (
      <Grid
        item
        data-test-id="drawer"
        xs={this.state.isDrawerOpen ? 3 : 1}
        style={{
          position: "relative",
          padding: 0,
          ...(this.state.isDrawerOpen ? {} : { maxWidth: 15 })
        }}
      >
        <div
          style={{
            ...styles.drawerContainer,
            display: this.state.isDrawerOpen ? "flex" : "none"
          }}
        >
          <div style={{ padding: "6px 8px" }}>
            <Typography
              variant="h5"
              component="h2"
              style={{ margin: "0.5rem 0" }}
            >
              My Courses
            </Typography>
          </div>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "6px 8px",
              boxSizing: "border-box"
            }}
          >
            <div>
              <Typography component="span" style={{ color: "#37F8C4" }}>
                {this.state.coursesList.reduce(
                  (acc, value) => acc + value.length,
                  0
                )}{" "}
                COURSES
              </Typography>
            </div>
          </div>
          <List style={{ maxHeight: 500, overflow: "auto" }}>
            {this.state.coursesList ? (
              this.state.coursesList.map((course, index) => {
                    const isCurrent =
                      this.state.currentCourse === `${course.course_id}`;
                    return (
                      <React.Fragment key={course.id}>
                      <Link
                        to={`/MyCoursesDetail/${course.order_id}/${
                          course.course_id
                        }`}
                        aria-current={isCurrent}
                        aria-disabled={isCurrent}
                        key={course.id}
                        style={{ fontWeight: isCurrent ? "bold" : "normal" }}
                        className="courses-list-detail"
                      >
                        <ListItem button className="list-item">
                          <img
                            src={
                              course.course_data.data.attributes
                                .course_thumbnail
                            }
                            width={80}
                            height={"auto"}
                            style={styles.courseThumbnail}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              width: "calc(100% - 80px)"
                            }}
                          >
                            <Typography
                              variant={isCurrent ? "h6" : undefined}
                              component={"span"}
                            >
                              {course.course_data.data.attributes.course_name}
                            </Typography>
                            <CustomProgress
                              variant="determinate"
                              value={(course.total_watched_chapter / course.total_course_chapter) * 100}
                            />
                            <Typography
                              variant={isCurrent ? "subtitle2" : undefined}
                              component="span"
                            >
                              {course.course_data.data.attributes.total_content}{" "}
                              CHAPTERS •{" "}
                              {
                                course.course_data.data.attributes
                                  .course_class_count
                              }{" "}
                              LESSONS •{" "}
                              {
                                course.course_data.data.attributes
                                  .all_course_class_duration
                              }{" "}
                              HOURS
                            </Typography>
                          </div>
                        </ListItem>
                      </Link>
                      </React.Fragment>
                    );
              })
            ) : (
              <CircularProgress />
            )}
          </List>
        </div>
        <IconButton
          style={{ ...styles.closeDrawerButton }}
          data-test-id="toggleDrawer"
          onClick={() => this.toggleDrawer()}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    );
  }

  renderView() {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      chapterDetail = this.state.chapterDetails as SpecfifcContentDuration;
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={webStylesLocal.adminLayout}>
          <Grid container spacing={2}>
            {this.renderLeftColumn()}
            <Grid
              item
              xs={this.state.isDrawerOpen ? 9 : 11}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 20,
                margin: "auto"
              }}
            >
              <div>
                <Typography
                  variant="h4"
                  noWrap
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  {chapterDetail ? (
                    chapterDetail.name
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <div className="videoplayer">
                    {!this.state.classDetails ? (
                      <img
                        src="https://via.placeholder.com/1920x1080/eee?text=16:9"
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: 12,
                          background: "black"
                        }}
                      />
                    ) : (
                      <iframe
                        width="560"
                        height="315"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "black",
                          borderRadius: 12,
                          display: "initial"
                        }}
                        className="imageContainer"
                        src={this.state.classDetails.video_upload}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      />
                    )}
                  </div>
                  <div style={styles.courseTitle}>
                    <Typography variant="h4" component="h1">
                      {this.state.classDetails ? (
                        this.state.classDetails.title
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </Typography>
                    <Chip
                      style={{ color: "white", background: "#FB7303" }}
                      label={"Refer a Friend"}
                      data-test-id="itemsCounter"
                    />
                  </div>
                  <div>
                    <StyledTabs
                      value={this.state.currentTab}
                      onChange={(e, value) =>
                        this.setState({
                          currentTab: value
                        })
                      }
                      data-test-id="tabsTestId"
                      aria-label="styled tabs example"
                    >
                      <StyledTab label="OVERVIEW" />
                      <StyledTab label="COURSE CONTENT" />
                      <StyledTab label="ASSIGNMENT" />
                      <StyledTab label="RATINGS" />
                    </StyledTabs>
                    {value ? this.renderTab(value) : <CircularProgress />}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={styles.playlistColumn}>
                    <Typography
                      variant="h6"
                      component="span"
                      style={{ marginLeft: 30 }}
                    >
                      CHAPTERS
                    </Typography>
                    <Divider
                      style={{ margin: "0px 30px", background: "#6DF4BE" }}
                    />
                    <List style={{ padding: 8 }}>
                      {this.state.playlist ? (
                        this.state.playlist.map((item, index) => {
                          const isCurrent = this.state.classId === `${item.id}`;
                          return (
                            <Link
                              to={`/MyCoursesDetail/${this.state.courseId}/${
                                this.state.currentCourse
                              }/${this.state.chapterId}/${item.id}`}
                              aria-current={isCurrent}
                              aria-disabled={isCurrent}
                              key={item.id}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                fontWeight: isCurrent ? "bold" : "normal"
                              }}
                            >
                              <ListItem
                                button
                                key={item.id}
                                style={{ alignItems: "start", gap: 5 }}
                              >
                                <FaCheckCircle
                                  style={{
                                    background: "white",
                                    borderRadius: "100px",
                                    width: 24,
                                    height: 24
                                  }}
                                  color="#236AD7"
                                />
                                <PiVideoLight
                                  style={{ width: 24, height: 24 }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "hidden",
                                    minWidth: 76
                                  }}
                                >
                                  <Typography
                                    variant={isCurrent ? "subtitle1" : "body1"}
                                    component="span"
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      fontWeight: isCurrent ? "bold" : "normal"
                                    }}
                                  >
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    variant={isCurrent ? "subtitle2" : "body2"}
                                    component="span"
                                  >
                                    (
                                    {this.convertMinutesToTimeFormat(
                                      item.duration
                                    )}
                                    )
                                  </Typography>
                                </div>
                              </ListItem>
                            </Link>
                          );
                        })
                      ) : (
                        <CircularProgress />
                      )}
                    </List>
                  </div>
                  {this.state.relatedCourses &&
                  this.state.relatedCourses.length ? (
                    <div style={styles.rightColumnCard}>
                      <Typography
                        variant="h5"
                        component="h3"
                        style={{ margin: 0 }}
                      >
                        Related Courses
                      </Typography>
                      {this.state.relatedCourses.map(item => (
                        <Link
                          to={`/course/${item.id}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            boxShadow: "0px 2px 6px 0px #00000046",
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            borderRadius: 10.61,
                            boxSizing: "border-box",
                            padding: 8
                          }}
                        >
                          <img
                            src={item.attributes.course_thumbnail}
                            style={{
                              borderRadius: "10.61px",
                              height: "142px",
                              width: "100%"
                            }}
                            alt="course thumbnail"
                            height={"142px"}
                            width={"auto"}
                          />
                          <Typography
                            variant="h5"
                            component="h5"
                            style={{
                              margin: 0,
                              fontSize: 20,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {item.attributes.course_name}
                          </Typography>
                          <Typography component="span">
                            {item.attributes.course_class_count} chapters{" "}
                            <BsDot /> {item.attributes.total_content} lectures
                          </Typography>
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }

  returnMainView() {
    return (
      <main
        style={{
          overflowX: "scroll"
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
            padding: "25px 0"
          }}
        >
          {this.renderView()}
          <FooterWeb id="footer" navigation={this.props.navigation} />
        </div>
      </main>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        {this.returnMainView()}
      </ThemeProvider>
    );
  }
}

const styles = {
  titleText: {
    color: "#858585",
    fontWeight: 700,
    fontSize: 24
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: -4,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    background: "#111612",
    color: "white",
    paddingBottom: 10,
    width: "calc(100% - 16px)"
  } as CSSProperties,
  closeDrawerButton: {
    background: "white",
    position: "absolute",
    height: 55,
    width: 15,
    top: 10,
    right: 0,
    fontSize: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    padding: 10,
    color: "black"
  } as CSSProperties,
  courseThumbnail: {
    borderRadius: 12,
    background: "white",
    minWidth: 80,
    maxWidth: 80,
    maxHeight: 51.6,
    minHeight: 51.6,
    height: "auto",
    objectFit: "contain"
  } as CSSProperties,
  courseTitle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    width: "100%",
    margin: "1rem 0"
  } as CSSProperties,
  viewAllText: {
    color: "#1220C1",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px"
  },
  courseViewAll: {
    display: "flex",
    columnGap: "10px",
    borderRadius: "31.826px",
    border: "1.8px solid #1721CD",
    background: "#FFF"
  },
  rightColumnCard: {
    marginTop: 20,
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 0px #00000025",
    width: "100%",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  } as CSSProperties,
  playlistColumn: {
    padding: "8px 0",
    background: "#ECFFFD",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: 12,
    border: "1.8px solid #6DF4BE",
    boxSizing: "border-box"
  } as CSSProperties,
  input: {
    flex: 1,
    marginRight: 4,
    marginLeft: 16,
    color: "black",
    width: "90%"
  },
  iconButton: {
    padding: 10,
    background: "linear-gradient(180deg,#fff54b,#f4c952)",
    fontSize: "0.55rem",
    margin: 3
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "30px",
    margin: "auto",
    border: "1px solid #FFA235",
    background: "white",
    boxSizing: "border-box",
    boxShadow: "unset",
    height: 44,
    flexDirection: "row-reverse"
  } as CSSProperties
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #C2C2C2"
  },
  fixed: {
    height: "48px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#FD7101"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      background: "transparent",
      color: "#9F9F9F",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: 115,
      padding: 0,
      "&.Mui-selected": {
        color: "#FD7101"
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CustomProgress = styled(LinearProgress)(() => ({
  borderRadius: 20,
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "white"
  },
  "& > div": {
    backgroundColor: "#3784F8"
  },
  "&.MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#3784F8"
  }
}));

// Customizable Area End
