import React from "react";

// Customizable Area Start
import { Box, FormControl, Typography, Button ,styled } from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Pagination from "@material-ui/lab/Pagination";
import "./EducationalUserProfile.css";
import moment from "moment";
import MainLayout from "../../../components/src/MainLayout.web";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import {
  DateInput,
  InputBox,
  OneColBox,
  ProfilePicInput,
  StyledSelect,
  TwoColBox,
} from "../../../components/src/GenericInputs";
const exportIcon = require("../../../components/src/exportIcon.png");

const StyleGenderInput = styled(FormControl)(() => ({
  '& .MuiInputBase-input': {
    fontFamily: "'Inter'",
    fontSize: '0.9rem',
    color: "rgb(25, 34, 82)",
    padding: '10px 14px'
  },
}))

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      "& .Mui-selected": {
        backgroundColor: "#176876",
        color: "white",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      display: "none",
    },
  })
)(Pagination);
const webStyle = {
  fullNameText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgNameMain: { display: "flex", marginLeft: "15px", columnGap: "10px" },
  editDeleteIcon: { background: "none", border: "none", cursor: "pointer" },
  tableActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  adminMainLayout: { width: "97%", margin: "0 20px" },
  adminLayout: { display: "flex", flexDirection: "column" },
  headerText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
  },
  adminLabelText: {
    color: "#192252",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  formErrorText: { fontSize: "12px", color: "red" },
  inputField: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "15px",
    paddingBottom: "20px",
  },
  fileLabel: {
    padding: "10px",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    width: "98%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    color: "#192252",
    background: "white",
  },
  subFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: " 4rem",
    borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
    marginBottom: "15px",
  },
  updateResetBtnMain: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "30px",
    justifyContent: "center",
    columnGap: "10px",
  },
  viewText: { color: "#176876" },
  deleteBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    border: " none",
    color: " white",
    fontSize: " 1rem",
    fontWeight: 600,
    backgroundColor: " #b91111",
    marginTop: "20px",
  },
  cancelBtn: {
    width: "150px",
    borderRadius: "6px",
    padding: "15px 0",
    color: "black",
    fontSize: " 1rem",
    fontWeight: 600,
    background: "white",
    border: "1px solid grey",
    marginTop: "20px",
  },
  previewText: {
    fontSize: "0.875rem",
  },
};
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";
import {
  IdFullnamePhoneEmailCols,
  TableBody,
  TableStyle,
  userProfileWebStyles,
  CheckBtnCol,
} from "./EducationalUserProfile.web";
import { webStyles } from "../../couponcodegenerator/src/AddCouponcodegenerator.web";

export default class AdminProfile extends EducationalUserProfileController {
  // Customizable Area Start
  toggleStatus(row: any, index: any) {
    this.handleToggleStatus(
      row.id,
      !row.attributes?.activated,
      index,
      "adminData"
    );
  }
  closemodal() {
    this.oncloseAlert();
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleAdminSearchText(e)}
        backData={() => {
          this.handleAdminDataReset();
          this.setState({ isAdminAdd: false, isAdminEdit: false });
        }}
        isLoading={this.state.isLoading}
        title={this.determineAdminTitle()}
        isAdd={this.state.isAdminAdd}
        isEdit={this.state.isAdminEdit}
        actionButtons={[
          {
            icon: <AddIcon />,
            iconType: "jsxElement",
            onClick: () => this.setState({ isAdminAdd: true }),
            text: "Add Admin",
            testId: "addAdminTestId",
          },
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {!this.state.isAdminAdd && !this.state.isAdminEdit && (
          <main className="table_data">{this.adminList()}</main>
        )}
        {(this.state.isAdminAdd || this.state.isAdminEdit) && (
          <main className="table_content">{this.addAdmin()}</main>
        )}
      </MainLayout>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  deleteModal(id: any) {
    return (
      <div
        style={{
          position: "absolute",
          right: "0",
          top: "100%",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          minWidth: "120px",
          padding: "20px",
        }}
      >
        <h3>Are your sure to delete this Admin?</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "10px",
          }}
        >
          <button
            data-test-id="deleteAdminModal"
            style={webStyle.deleteBtn}
            onClick={() => this.deleteAdminApi(id)}
          >
            Delete
          </button>
          <button
            data-test-id="cancelDeleteModal"
            style={webStyle.cancelBtn}
            onClick={() => this.setState({ isAdminDeleteModal: false })}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  addAdmin() {
    const { isAdminEdit, adminFormData, adminFormErrors } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Add Image*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                {this.state.previewThumbnail ? (
                  <ProfilePicInput
                    onChange={(e) => this.handleFileChange(e)}
                    onRemove={() => this.handleFileRemove()}
                    data-test-id="fileChange"
                    name="instructorImgName"
                    previewThumbnail={this.state.previewThumbnail}
                  />
                ) : (
                  <label
                    style={{ ...webStyle.fileLabel, flexDirection: "column" }}
                  >
                    {this.state.selectedFile || this.state.selectedFileName ? (
                      <Typography style={webStyle.previewText}>
                        {this.state.selectedFileName}
                      </Typography>
                    ) : (
                      <Typography style={webStyle.previewText}>
                        + UPLOAD PROFILE PICTURE
                      </Typography>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      data-test-id="fileChange"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleFileChange(e)}
                    />
                  </label>
                )}
                <span style={webStyle.formErrorText}>{this.state.fileErr}</span>
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Typography className="box__label">Full Name*</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                data-test-id="adminName"
                id="outlined-basic"
                variant="outlined"
                name="adminName"
                fullWidth
                value={adminFormData.adminName}
                onChange={(e) => {
                  this.handleAdminInputChange(e);
                }}
              />
              <span data-test-id="adminNameErr" style={webStyle.formErrorText}>
                {adminFormErrors.adminName}
              </span>
            </FormControl>
          </OneColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Gender*</Typography>
              <StyleGenderInput variant="outlined" fullWidth>
                <StyledSelect
                  fullWidth={true}
                  onChange={(e) => {
                    this.handleAdminInputChange(e);
                  }}
                  disableUnderline
                  name="adminGender"
                  value={adminFormData.adminGender}
                  data-test-id="adminGender"
                  IconComponent={IoIosArrowDown}
                >
                  <option value="" disabled>{"Select Gender"}</option>
                  <option value="Male">{"Male"}</option>
                  <option value="Female">{"Female"}</option>
                  <option value="Other">{"Other"}</option>
                </StyledSelect>
                <span style={webStyle.formErrorText}>
                  {adminFormErrors.adminGender}
                </span>
              </StyleGenderInput>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">Date of Birth*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <DateInput
                  onChange={(e) => this.handleAdminInputChange(e)}
                  data-test-id="dateChange"
                  inputProps={{
                    inputProps: {
                      max: moment().subtract(18, "years").format("YYYY-MM-DD")
                    },
                  }}
                  style={{
                    color: adminFormData.adminDOB ? "" : "transparent",
                  }}
                  value={adminFormData.adminDOB}
                  name="adminDOB"
                />
                <span style={webStyle.formErrorText}>
                  {adminFormErrors.adminDOB}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Email*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  type="text"
                  data-test-id="emailTest"
                  id="outlined-basic"
                  variant="outlined"
                  name="adminEmail"
                  fullWidth
                  value={adminFormData.adminEmail}
                  onChange={(e) => {
                    this.handleAdminInputChange(e);
                  }}
                />
                <span style={webStyle.formErrorText}>
                  {adminFormErrors.adminEmail}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">Mobile*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  fullWidth
                  id="adminMobile"
                  name="adminMobile"
                  value={adminFormData.adminMobile}
                  onChange={(e) => {
                    this.handleAdminInputChange(e);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  data-test-id="mobileTest"
                />
                <span style={webStyle.formErrorText}>
                  {adminFormErrors.adminMobile}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
        </Box>
        <Box style={webStyle.updateResetBtnMain}>
          <Button
            data-test-id="resetData"
            variant="outlined"
            style={webStyles.resetBtn}
            onClick={() => this.handleAdminDataReset()}
          >
            Reset
          </Button>
          <Button
            data-test-id="addUpdateAdmin"
            style={webStyles.createBtn}
            onClick={() => this.handleAddAdmin()}
          >
            {isAdminEdit ? "Update" : "Create"}
          </Button>
        </Box>
      </div>
    );
  }

  tableBody(row: any, index: any) {
    return (
      <>
        <tr key={row.id}>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                checked={this.state.isChecked[index]}
                style={userProfileWebStyles.input}
                onChange={() => this.handleRowCheckboxChange(index)}
                data-test-id="rowCheckboxChange"
                type="checkbox"
              />
            </Box>
          </td>
          <IdFullnamePhoneEmailCols row={row} />
          <td>
            <div>
              {moment(new Date(row.attributes?.created_at)).format(
                "DD MMM YYYY"
              )}
            </div>
            <div>
              {moment(new Date(row.attributes?.created_at)).format("HH:mm:ss")}
            </div>
          </td>
          <td>
            <div>
              {row.attributes?.last_login_at
                ? moment(new Date(row.attributes.last_login_at)).format(
                    "DD MMM YYYY"
                  )
                : ""}
            </div>
            <div>
              {row.attributes?.last_login_at
                ? moment(new Date(row.attributes.last_login_at)).format(
                    "HH:mm:ss"
                  )
                : ""}
            </div>
          </td>
          <td>
            <div>
              {row.attributes?.logout_at
                ? moment(new Date(row.attributes.logout_at)).format(
                    "DD MMM YYYY"
                  )
                : ""}
            </div>
            <div>
              {row.attributes?.logout_at
                ? moment(new Date(row.attributes.logout_at)).format("HH:mm:ss")
                : ""}
            </div>
          </td>
          <CheckBtnCol
            row={row}
            handleToggleStatus={(e: any) => this.toggleStatus(row, index)}
          />
          <td style={{ borderRadius: "0px 10px 10px 0px" }}>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id="editAdmin"
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleAdminEdit(row)}
              />
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <FaRegTrashAlt
                  data-test-id="deleteAdmin"
                  className="desc_edit_icon"
                  color={"black"}
                  size={17}
                  onClick={() => this.deleteAdmin(row.id)}
                />
                {this.state.isAdminDeleteModal &&
                  this.state.adminId == row.id &&
                  this.deleteModal(row.id)}
              </div>
            </Box>
          </td>
        </tr>
        {this.renderTableDivider()}
      </>
    );
  }

  renderTableDivider() {
    return (
      <Box
        className="table_divider"
        style={userProfileWebStyles.tableDivider}
      ></Box>
    );
  }

  tableHeader() {
    const { field, order } = this.state.currentSortState;
    const isCurrentSort = (name: string) => field === name && order === "asc";
    return (
      <thead>
        <tr>
          <th>
            <Box
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <input
                onChange={this.handleHeaderCheckboxChange}
                data-test-id="headerCheckboxChange"
                type="checkbox"
                style={userProfileWebStyles.input}
                checked={this.state.isHeaderChecked}
              />
            </Box>
          </th>
          {this.state.adminColHeader.length > 0 &&
            this.state.adminColHeader.map((item, index) => (
              <th key={index}>
                <Box className="th_box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent:
                        index === 0 || index === 2 || index === 3
                          ? "start"
                          : "center",
                      alignItems: "center",
                      columnGap: "5px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      className="title_th"
                      style={{ maxWidth: "150px" }}
                    >
                      {item}
                    </Typography>
                    {["S.No", "Admin Name", "Status"].includes(item) && (
                      <IoIosArrowDown
                        className={`filter_icon ${isCurrentSort(item) ? "active" : ""}`}
                        data-test-id="filterData"
                        onClick={() => this.filterDataOnCol(item, "admin")}
                        style={{
                          transform: isCurrentSort(item)
                            ? "none"
                            : "rotate(180deg)",
                          width: isCurrentSort(item)  ? '20px' : "15px",
                          height: isCurrentSort(item)  ? '20px' : "15px"
                        }}
                      />
                    )}
                  </div>
                </Box>
              </th>
            ))}
        </tr>
      </thead>
    );
  }

  adminList() {
    return (
      <Box>
        <TableStyle>
          {this.tableHeader()}
          <span className="dividerLine"></span>
          <TableBody>
            {this.state.adminData?.length > 0 &&
              this.state.adminData?.map((row: any, index: number) =>
                this.tableBody(row, index)
              )}
          </TableBody>
        </TableStyle>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
          <StyledPagination
            data-test-id="paginationTest"
            count={this.state.adminTotalPage}
            page={this.state.adminPageNo}
            onChange={this.handleAdminPageChange}
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
            classes={{ ul: "custom-pagination" }}
          />
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
