import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
import { validationsLoop } from "../../user-profile-basic/src/FooterController.web";
import { getStorageData, setStorageData } from "framework/src/Utilities";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface NotificationsListResponse extends ErrorResponse {
  data: NotificationsData[];
  meta: Meta;
}

interface ShowNotificationResponse extends ErrorResponse {
  data: NotificationsData;
  meta: Meta;
}

export interface Meta {
  page: string;
  message: string;
  total_email_notification_template: number;
}

export interface NotificationsData {
  id: string;
  type: string;
  attributes: Attributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}
export interface DataError {
  headers: string[];
}
interface ResponseSubmit extends ErrorResponse {
  data: NotificationsData | DataError;
  meta: {
    message: string;
  };
}
export interface Attributes {
  id: number;
  created_at: Date;
  updated_at: Date;
  email_notification_template_type: string;
  headers: string;
  functions: string;
  contents: string;
  status: boolean;
  notificationId: string;
  promo_code:string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  notificationsList: NotificationsData[];
  isLoading: boolean;
  notificationId: string;
  token: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  headers: string;
  headersError: string;
  functions: string;
  functionsError: string;
  contents: string;
  contentsError: string;
  email_notification_template_type: string;
  promo_code:string;
  promo_codeError:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddEmailNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateNotificationsApiCallId: string = "";
  showNotificationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      notificationsList: [],
      isLoading: true,
      token: "",
      headers: "",
      headersError: "",
      contents: "",
      contentsError: "",
      email_notification_template_type: "",
      functions: "",
      functionsError: "",
      notificationId: "",
      promo_code:'',
      promo_codeError:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else {
        this.parseErrors(responseJson);
        this.setState({ isLoading: false });
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        await setStorageData("emailnotificationId",propsData.notificationId)
        this.handleShowNotification(propsData.notificationId);
      }
    }
    // Customizable Area End
  }

// Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("loginToken");
    const notificationId = await getStorageData("emailnotificationId")
    this.setState({ token: token });
    if (notificationId) {
      this.handleShowNotification(notificationId)
    }
  }
  parseErrors = async (responseJson: ErrorResponse) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  validateInput = (input: string, name:string): string | null => {
    const nameFields = name === "headers" ? "Headers" : "Contents"
    if (input.length > 100) {
      return `${nameFields} is too long (maximum is 100 characters)`;
    }
  
    if (!input.trim()) {
      return `${nameFields} cannot be only blank spaces.`;
    }
  
    if (/^\d+$/.test(input)) {
      return `${nameFields} cannot be only numbers.`;
    }
  
    if (/^[!@#$%^&*(),.?":{}|<>]+$/.test(input)) {
      return `${nameFields} cannot be only special characters.`;
    }
  
    return ""; // No error
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson:
      | ShowNotificationResponse
      | NotificationsListResponse
      | ErrorResponse
      | ResponseSubmit
  ) => {
    switch (apiRequestCallId) {
      case this.showNotificationApiCallId:
        this.handleShowNotificationResponse(
          responseJson as ShowNotificationResponse
        );
        break;
      case this.updateNotificationsApiCallId:
        this.handleSubmitEmailNotificationResponse(
          responseJson as ResponseSubmit
        );
        break;
    }
  };

  handleApiRequest(apiCallData: APIPayloadType) {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  handleInputChange({ target }: { target: { value: string; name: string } }) {
    let { name, value } = target,
      error = "";
    const stateValues: unknown = { [name]: value, [`${name}Error`]: this.validateInput(value, name) };
    this.setState(stateValues as Pick<S, keyof S>);
  }
  handleCourseInputChange(e: any) {
    let { name, value } = e.target;
    if (value.startsWith(' ')) {
      this.setState({ promo_codeError: 'Promo code cannot start with a blank space' });
      return;
    }
    if(value.length>50){
      this.setState({promo_codeError:'Promo code cannot be more than 50 characters'})
    }
    else{
      this.setState({promo_codeError:'',promo_code:value})
    }
  }
  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "headers",
        errorKey: "headersError",
        errorMessage: "Headings is Required"
      }
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }

  handleAddReset = () => {
    this.setState({
      contents: "",
      contentsError: "",
      functions: "",
      functionsError: "",
      headers: "",
      headersError: "",
      promo_code:'',
      promo_codeError:''
    });
  };

  handleSubmitEmailNotification() {
    this.setState({ isLoading: true });
    const {  headers, notificationId , headersError,promo_code,promo_codeError} = this.state;
    if(headers==""){
      this.setState({headersError:"Heading is Required",isLoading: false})
    }
    if(promo_code==""){
      this.setState({promo_codeError:"Promo code is Required",isLoading: false})
      return
    }
    if( headersError ||promo_codeError) {
      this.setState({ isLoading: false });
      return false
    }
    const formValues = {
      headers,
      promo_code
    };
    
    if (!this.checkFormValidations(formValues)) {
      const data = {
        data: {
          headers,
          promo_code
        }
      };

      this.updateNotificationsApiCallId = this.handleApiRequest({
        method: configJSON.putApiMethodType,
        endPoint: configJSON.emailNotificationsEndpoint + `/${notificationId}`,
        token: this.state.token,
        contentType: configJSON.exampleApiContentType,
        body: data
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  isDataError(data: unknown): data is DataError {
    return (data as DataError).headers !== undefined;
  }
  handleSubmitEmailNotificationResponse(response: ResponseSubmit) {
    if (response.meta) {
      this.navigationEmailNotifications();
    } else if (this.isDataError(response.data)) {
      const data = response.data;
      this.setState({
        isAlert: true,
        alertMsg: "Header name " + data.headers[0],
        headersError: "Header name " + data.headers[0],
        alertType: "error",
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationEmailNotifications = () => {
    const emailNotificationsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationEmailNotificationsMessage)
    );
    emailNotificationsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(emailNotificationsScreenMsg);
  };

  handleShowNotification = (notificationId: string) => {
    this.setState({ isLoading: true });
    this.showNotificationApiCallId = this.handleApiRequest({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getEmailNotificationsAPIMethod,
      endPoint: configJSON.emailNotificationsEndpoint + `/${notificationId}`
    });
  };

  handleShowNotificationResponse = (response: ShowNotificationResponse) => {
    if (response && response.data) {
      const { data } = response,
        { attributes, id } = data,
        {
          headers,
          email_notification_template_type,
          promo_code
        } = attributes;
      this.setState({
        headers,
        email_notification_template_type,
        notificationId: id,
        isLoading: false,
        promo_code
      });
    } else {
      this.navigationEmailNotifications();
    }
  };
  // Customizable Area End
}
