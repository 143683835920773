import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react';
import moment from "moment";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "framework/src/Utilities";
interface ProfileResponse {
  data: {
    attributes: {
      photo: string,
      full_name: string,
      full_phone_number: string;
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  activeStep: number;
  showDOB: boolean;
  fullNameErr: string;
  stateErr: string;
  districtErr: string;
  genderErr: string;
  dobError: string;
  fullName: string;
  state: any;
  listState: any[];
  listDistrict: any[];
  gender: string;
  date: string;
  District: any;
  getOtp: boolean;
  mobileTermsChecked: boolean;
  otp: string[];
  signupWithMobileToken: any;
  seconds: number;
  otpEmail: string[];
  registrationFormData: any;
  errDate: string;
  phoneErr: string;
  numberIsAlready: string;
  otpError: string;
  signupResendToken: string;
  signupResendTokenWithEmail: string;
  Email: string;
  emailErr: string;
  emailTermsChecked: boolean;
  signUpToken: string;
  loginWithMobileVerifyToken: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  apiStateCallId: any;
  apiCityCallId: any;
  createSignupWithMobileCallId: any;
  createSignupWithMobileVerify: any;
  finalRegister: any;
  EmailSignupSendOTPID: any;
  createSignupWithEmailVerify: any;
  SignupWithMobileResendApiID: any;
  SignupWithMobileResendVirifyApiID: any;
  SignupWithEmailResendApiID: any;
  SignupWithEmailResendVirifyApiID: any;
  profileIconApiCallId: string = "";
  dateInputRef: React.RefObject<any>;
  timer: ReturnType<typeof setInterval> = setInterval(() => '', 1000);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      activeStep: 0,
      showDOB: true,
      fullNameErr: "",
      stateErr: "",
      districtErr: "",
      genderErr: "",
      dobError: "",
      fullName: "",
      state: "",
      listState: [],
      date: "",
      listDistrict: [],
      gender: "",
      District: "",
      getOtp: false,
      mobileTermsChecked: false,
      otp: ["", "", "", ""],
      signupWithMobileToken: "",
      seconds: 0,
      otpEmail: ["", "", "", ""],
      registrationFormData: {},
      errDate: "",
      phoneErr: "",
      numberIsAlready: "",
      otpError: "",
      signupResendToken: '',
      signupResendTokenWithEmail: "",
      Email: "",
      emailErr: "",
      emailTermsChecked: false,
      signUpToken: "",
      loginWithMobileVerifyToken: ""
      // Customizable Area End
    };

    // Customizable Area Start
  this.dateInputRef = React.createRef<HTMLInputElement>();
    this.handleBackNavigation = this.handleBackNavigation.bind(this);
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.apiStateCallId:
          this.setState({ listState: responseJson });
          break;
        case this.apiCityCallId:
          this.setState({ listDistrict: (responseJson && responseJson.data) || [] })
          break;
        case this.createSignupWithMobileCallId:
          this.createSignupWithMobileresponse(responseJson)
          break;
        case this.EmailSignupSendOTPID:
          this.EmailSignupSendOTPIDresponse(responseJson)
          break;
        case this.createSignupWithMobileVerify:
          this.createSignupWithMobileVerifyresponse(responseJson)
          break;
        case this.finalRegister:
          this.finalRegisterresponse(responseJson);
          break;
        case this.SignupWithMobileResendApiID:
          this.SignupWithMobileResendApiIDResponse(responseJson);
          break;
        case this.SignupWithMobileResendVirifyApiID:
          this.SignupResendOTPVerifyResponse(responseJson);
          break;

        case this.SignupWithEmailResendApiID:
          this.SignupWithEmailResendApiIDResponse(responseJson)
          break;

        case this.SignupWithEmailResendVirifyApiID:
          this.SignupResendOTPVerifyResponseEmail(responseJson);
          break;

        case this.createSignupWithEmailVerify:
          this.createSignupWithEmailVerifyresponse(responseJson)
          break;

        case this.profileIconApiCallId:
          this.getUserProfileResponse(responseJson);
          break;

        case this.validationApiCallId:
          this.arrayholder = responseJson.data;
          const regexData = this.arrayholder && this.arrayholder[0];

          if (regexData) {
            this.passwordReg = regexData.password_validation_regexp
              ? new RegExp(regexData.password_validation_regexp)
              : this.passwordReg;
            this.setState({
              passwordHelperText: regexData.password_validation_rules || this.state.passwordHelperText,
            });
            this.emailReg = regexData.email_validation_regexp
              ? new RegExp(regexData.email_validation_regexp)
              : this.emailReg;
          }
          break;

        case this.createAccountApiCallId:
          if (!responseJson.errors) {
            const msg = new Message(getName(MessageEnum.AccoutResgistrationSuccess));
            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(msg);
          } else {
            // Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
          break;
      }

      if (!apiRequestCallId || !responseJson) {
        return;
      }

    }
    this.handleReceive(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleReceive(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleInputChange = (e: any) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 10) {
      this.setState({ phone: value, phoneErr: "", numberIsAlready: "" }, () => {
        if(this.state.phone === "") {
          this.setState({ phoneErr: "Please Enter a valid number", numberIsAlready: "" })
        }
      });
    }

  };

  handleStateChange(e: any) {
    const value = e.target.value;
    if (value == 0) {
      this.setState({ stateErr: "State is required" })
    } else {
      this.setState({ state: value, stateErr: "" }, () => { this.getAllCity() })
    }
  }

  finalRegisterresponse(responseJson: any) {
    if (responseJson.data) {      
      this.setState({ activeStep: 3, getOtp: false });
      setStorageData("loginToken", responseJson.meta?.token);
      setStorageData("userRole", responseJson.data.attributes.role_name);
      window.alert('You have successfully registered and Verified')
      this.getUserProfile(responseJson.meta?.token);
    }
  }

  getAllStates() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiStateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stateApiEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getAllCity() {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiCityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CityAPiEndPoint + `${this.state.state}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));


    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  EmailSignupSendOTPIDresponse(responseJson: any) {
    if (responseJson.meta) {
      window.localStorage.setItem('emailOtpToken', responseJson.meta.token)
      this.setState({ getOtp: true })
      window.alert('OTP has been sent')
      this.otpTimer()

    } else {
      this.setState({emailErr:'This email is already registered please try to login'})
    }
  }


  handleSignUpWithMobileAPI() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const data = {
      attributes: {
        full_phone_number: '91' + this.state.phone
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSignupWithMobileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithMobileEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFullNameChange(e: any) {
    let a = e.target.value;

    const namePattern = /^[a-zA-Z\s]*$/;

    if (a.trim() === "") {
        this.setState({ fullName: a, fullNameErr: "Full Name is required" });
    } else if (!namePattern.test(a)) {
        this.setState({ fullName: a, fullNameErr: "Only letters and spaces are allowed" });
    } else if (a.trim().length < 3) {
        this.setState({ fullName: a, fullNameErr: "Full Name must be at least 3 characters" });
    } else {
        this.setState({ fullName: a, fullNameErr: "" });
    }
}

  handleKeydown = (e: any, ind: any) => {
    const inputs = document.querySelectorAll('input[type="number"]');
    if (e.target.value.length === 0 && e.key === "Backspace") {
      const nextInput = inputs[ind - 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };
  handleOTPChange = (e: any, index: number) => {
    this.setState({ otpError: "" });
    const otp = [...this.state.otp];
    otp[index] = e.target.value;
    this.setState({ otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  handleKeydownEmail = (e: any, ind: any) => {
    const inputs = document.querySelectorAll('input[type="number"]');
    if (e.target.value.length === 0 && e.key === "Backspace") {
      const nextInput = inputs[ind - 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };
  handleOTPChangeEmail = (e: any, index: number) => {
    this.setState({ otpError: "" });
    const otp = [...this.state.otpEmail];
    otp[index] = e.target.value;
    this.setState({ otpEmail: otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };


  createSignupWithMobileresponse(responseJson: any) {
    if (responseJson?.meta?.token) {
      this.setState({ signupWithMobileToken: responseJson.meta.token });
      this.setState({ getOtp: true })

      this.otpTimer()

    }
    if (responseJson.errors) {
      if (responseJson.errors[0].full_phone_number === 'This number already registered, try another number') {
        this.setState({phoneErr:'This number is already registered please try to login'})

      }
    }
  }

  handleLoginNavigate() {
    this.props.navigation.navigate("Login")
  }

  handleDistrictChange(e: any) {
    const value = e.target.value;
    if (value == 0) {
      this.setState({ districtErr: "District is required" })
    } else {
      this.setState({ District: value, districtErr: "" })
    }
  }

  handleGenderChange(e: any) {
    const value = e.target.value;
    if (value.length == 0) {
      this.setState({ genderErr: "Gender is required" })
    } else {
      this.setState({ gender: value, genderErr: "" })
    }
  }

  handleDateChange(e: any) {
    this.setState({ date: e.target.value });
    let selectedDate = new Date(e.target.value);
    let today = new Date();
    let limitDate = moment().subtract(100, 'year').calendar();
    let limit = new Date(limitDate);
    today.setHours(0, 0, 0, 0);

    let minAgeDate = moment().subtract(8, 'years').toDate();

    if (!e.target.value) {
      this.setState({ dobError: "DOB is required" });
    } else if (selectedDate > today || selectedDate < limit) {
      this.setState({ dobError: "Please Select a valid date" });
    } else if (selectedDate > minAgeDate) {
      this.setState({ dobError: "Age must be at least 8 years" });
    } else {
      this.setState({ dobError: "" });
    }
}

  handleRegister = () => {
    const updatedValues = {
      full_name: this.state.fullName,
      state_id: this.state.state,
      district_id: this.state.District,
      gender: this.state.gender,
      date_of_birth: this.state.date
    };

    let hasError = false;

    if (updatedValues.full_name.length === 0) {
      this.setState({ fullNameErr: "Full Name is Required" });
      hasError = true;
    } else {
      this.setState({ fullNameErr: "" });
    }

    if (!updatedValues.state_id) {
      this.setState({ stateErr: "State is Required" });
      hasError = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!updatedValues.gender) {
      this.setState({ genderErr: "Gender is Required" });
      hasError = true;
    } else {
      this.setState({ genderErr: "" });
    }

    if (!updatedValues.district_id) {
      this.setState({ districtErr: "City is Required" });
      hasError = true;
    } else {
      this.setState({ districtErr: "" });
    }

    if (!updatedValues.date_of_birth) {
      this.setState({ dobError: "Date of birth is Required" });
      hasError = true;
    } else {
      this.setState({ dobError: "" });
    }

    if (!hasError) {
      this.setState({ registrationFormData: updatedValues, activeStep: 1 });

    }
  }

  handleEmailchange(e: any) {
    const inputEmail = e.target.value.trim();


    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailRegex.test(inputEmail)) {

      this.setState({ Email: inputEmail, emailErr: "" });
    } else {

      this.setState({ Email: inputEmail, emailErr: "Please Enter a valid email" })
    }
  }


  handleMobileSignupclick(ph: any) {

    if (this.state.mobileTermsChecked && ph) {
      this.handleSignUpWithMobileAPI()
      this.setState({ otp: ['', '', '', ''], })
    } else if (!this.state.mobileTermsChecked) {
      window.alert('Please accept terms and conditions & privacy policy')
    } else {
      window.alert("Enter phone number")
    }

  }

  handleMobileSignupOTPVerifyclick(token: any) {
    let otpValue = this.state.otp && this.state.otp.join('')

    if (otpValue.length === 4) {
      if (this.state.signupResendToken) {
        this.handleSignUpWithMobileResendVerifyAPI()
      } else {
        this.handleSignUpWithMobileverifyAPI(token)
      }
    }
  }

  handleEmailSignupOTPVerifyclick() {
    if (this.state.signupResendTokenWithEmail) {
      this.handleSignUpWithEmailResendVerifyAPI()
    } else {
      this.handleSignUpWithEmailOTPverifyAPI()
    }
  }

  handleSignUpRegister(userData: any, verifytoken: any) {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": verifytoken
    };

    const data = {
      attributes: {...userData, device_id: window.navigator.userAgent}
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.finalRegister = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RegisterWithMobileVerifyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  createSignupWithMobileVerifyresponse(responseJson: any) {
    if (responseJson.data) {
      let b = responseJson.data[0].token;
      window.localStorage.setItem('verifyToken', b);
      this.setState({ loginWithMobileVerifyToken: responseJson.data[0].token, activeStep: 2, getOtp: false });
    } else {
      window.alert('Invalid OTP');
    }
  }

  SignupResendOTPVerifyResponse(responseJson: any) {
    if (responseJson.meta === 'Otp verified') {
      this.setState({ activeStep: 2, getOtp: false });
    } else {
      window.alert('Enter correct OTP')
    }
  }

  handleEmailSign(){
    this.setState({emailErr:"email is required"})
  }
  handleMobileSign(){
    this.setState({emailErr:"mobile is required"})
  }

  handleEmailSignupWeb() {

    if (this.state.emailTermsChecked) {

      if (this.state.emailErr.length > 0) {
        window.alert("Enter a valid email Id");
      } else {

        let a: any = window.localStorage.getItem('verifyToken');
        this.emailSignupSendOTP(a)
        this.setState({ otpEmail: ["", "", "", ""]})
      }
    } else {
      window.alert('Please Accept terms and conditions & privacy policy')
    }
  }

  SignupWithEmailResendApiIDResponse(responseJson: any) {
    if (responseJson.meta) {
      window.alert('New OTP Has been Sent');
      this.otpTimer()

      this.setState({ signupResendTokenWithEmail: responseJson.meta.token })
    } else {
      window.alert('Something wend wrong')
    }
  }

  createSignupWithEmailVerifyresponse(responseJson: any) {
    if (responseJson.meta === 'Otp verified') {
      setStorageData("loginToken", responseJson.data[0]?.token);
      const registrationData = {
        ...this.state.registrationFormData,
      };
      this.handleSignUpRegister(registrationData, responseJson.data[0].token);
    } else {
      window.alert('Enter Correct Otp')
    }
  }

  SignupResendOTPVerifyResponseEmail(responseJson: any) {
    if (responseJson.meta === 'Otp verified') {
      window.alert('Email Verified')
      setStorageData("loginToken", responseJson.data[0]?.token);
      const registrationData = {
        ...this.state.registrationFormData,
      };
      this.handleSignUpRegister(registrationData, responseJson.data[0].token);
    } else {
      window.alert('Enter correct OTP')
    }
  }

  getUserProfile = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileIconApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileInfoApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfileResponse = (response: ProfileResponse) => {
    if (response && response.data && response.data.attributes) {
      setTimeout(() => {
        this.goToHome()
      }, 1000)
      setStorageData("profilePic", response.data.attributes.photo);
      setStorageData("profileUsername", response.data.attributes.full_name);
      setStorageData("profilePhoneNumber", response.data.attributes.full_phone_number);
    }
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  SignupWithMobileResendApiIDResponse(responseJson: any) {
    if (responseJson.meta) {
      window.alert('New OTP Has been Sent');
      this.otpTimer()

      window.localStorage.setItem('signupResendToken', responseJson.meta.token);
      this.setState({ signupResendToken: responseJson.meta.token })
    } else {
      window.alert('Something went wrong')
    }
  }
  emailSignupSendOTP(token: any) {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": token
    };

    const data = {
      attributes: {
        email: this.state.Email
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EmailSignupSendOTPID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EmailSignupSendOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSignUpWithEmailOTPverifyAPI() {

    let tkn = window.localStorage.getItem('emailOtpToken');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tkn
    };

    let otpValue = this.state.otpEmail && this.state.otpEmail.join('')

    const data = {
      attributes: {
        otp: otpValue
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSignupWithEmailVerify = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithEmailVerifyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleSignUpWithMobileResendAPI() {



    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };


    const data = {
      attributes: {
        full_phone_number: '91' + this.state.phone
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignupWithMobileResendApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithMobileResendApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({otp:['','','','']})
  }

  handleSignUpWithMobileResendVerifyAPI() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": this.state.signupResendToken
    };

    let otpValue = this.state.otp && this.state.otp.join('')

    const data = {
      attributes: {
        otp: otpValue
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignupWithMobileResendVirifyApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithMobileResendVerifyApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleEmailOTPResend() {


    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };



    const data = {
      attributes: {
        email: this.state.Email
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignupWithEmailResendApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithEmailResendApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ otpEmail: ["", "", "", ""]})
  }



  handleSignUpWithEmailResendVerifyAPI() {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": this.state.signupResendTokenWithEmail
    };

    let otpValue = this.state.otpEmail && this.state.otpEmail.join('')

    const data = {
      attributes: {
        otp: otpValue
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignupWithEmailResendVirifyApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithEmailResendVerifyApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleSignUpWithMobileverifyAPI(tkn: any) {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tkn
    };

    let otpValue = this.state.otp && this.state.otp.join('')

    const data = {
      attributes: {
        otp: otpValue
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSignupWithMobileVerify = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SignupWithMobileVerifyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  otpTimer() {
    clearInterval(this.timer);
    this.setState({ seconds: 300 }, () => {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
        if (this.state.seconds == 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    });
  }

  timeFormat(seconds: number): string {
    const remainingSeconds = seconds % 60;
    const minutes = Math.floor(seconds / 60);
    return `0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
  
  async componentDidMount() {
    this.getAllStates();
     /* istanbul ignore next */
     window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
     window.addEventListener('popstate', this.handleBackNavigation);
  }

  /* istanbul ignore next */
  async componentWillUnmount(){
    window.removeEventListener('popstate', this.props.navigation.navigate('LandingPage'));
  }
  
   handleBackNavigation() {
     this.props.navigation.navigate('LandingPage');
  }

  gobackTopreviousStep(step:number){
    this.setState({ activeStep: step })
    if (this.state.activeStep === 2 && this.state.getOtp === false) {
      this.setState({ getOtp: true, activeStep: 1 })
    } else if (this.state.activeStep === 1 && this.state.getOtp === true) {
      this.setState({ activeStep: 1, getOtp: false })
    }
    else if (this.state.activeStep === 1 && this.state.getOtp === false) {
      this.setState({ activeStep: 0, getOtp: false })
    }
    else if (this.state.activeStep === 2 && this.state.getOtp === true) {
      this.setState({ activeStep: 2, getOtp: false })
    }
  }
  handleDOBClick = () => {
    if (this.dateInputRef.current) {
      this.dateInputRef.current.showPicker();
    }
  };
  goTermsAndCondition() {
    window.open("/HelpCenter/termsandcondition", "_blank")
  }
  navigateToPravicyPolicy() {
    window.open("/HelpCenter/privacypolicy", "_blank")
  }
  // Customizable Area End
}
