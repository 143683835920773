import React from 'react';
import Loader from './Loader.web';
import {
  styled,
  createTheme,
  ThemeProvider,
  AppBar,
  Toolbar,
  Box,
  Typography,
  OutlinedInput,
  responsiveFontSizes,
} from '@material-ui/core';
import { removeStorageData } from 'framework/src/Utilities';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { searchIcone } from '../../blocks/categoriessubcategories/src/assets';
import MenuIcon from '@material-ui/icons/Menu';
import { MdChevronLeft } from 'react-icons/md';
import InputAdornment from '@material-ui/core/InputAdornment';
const drawerWidth = 250;
export const elementsPerPage = 20;

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace",
    },
    body1: {
      fontFamily: "'Inter', monospace",
    },
  },
});
theme = responsiveFontSizes(theme);

import CustomDrawer from './SideBar.web';
import { DateInput } from './GenericInputs';
import { getStorageData } from 'framework/src/Utilities';

const defaultProfilePic =
  'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg';

type ActionButton =
  | {
      icon: string;
      iconType: 'imgUrl';
      text: string;
      onClick: (e: any) => void;
      testId: string;
    }
  | {
      icon: JSX.Element;
      iconType: 'jsxElement';
      text: string;
      onClick: (e: any) => void;
      testId: string;
    };

type MainLayoutProps = {
  actionButtons?: ActionButton[];
  backData?: () => void;
  handleSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableSearch?: boolean;
  title?: string;
  isAdd?: boolean;
  handleFromDate?: any;
  handleToDate?: any;
  formDate?: any;
  toDate?: any;
  isEdit?: boolean;
  isStudent?: boolean;
  isLoading?: boolean;
  newUI?: boolean;
};

type S = {
  text: string;
  profilePic: string;
  anchorEl: null | HTMLElement;
};

const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let timer: undefined | ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timer as ReturnType<typeof setTimeout>);
    timer = setTimeout(() => callback(...args), delay);
  };
};

class MainLayout extends React.Component<MainLayoutProps, S> {
  constructor(props: MainLayoutProps) {
    super(props);
    this.state = {
      text: '',
      profilePic: defaultProfilePic,
      anchorEl: null,
    };
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  debounceValue = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => this.props.handleSearch!(e),
    750
  );

  handleSearchChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    e.persist();
    this.setState({ text: e.target.value });
    this.debounceValue(e);
  }

  componentDidUpdate() {
    this.fetchProfilePic();
  }

  async changeRedirection(url: string, removeStorage : boolean) {
    removeStorage && await this.removeUserDataFromStorage();
    window.location.href = url;
  }

  fetchProfilePic = async () => {
    try {
      const value = await getStorageData('profilePic');
      if (value !== this.state.profilePic) {
        this.setState({ profilePic: value });
      }
    } catch (error) {
      if (this.state.profilePic !== defaultProfilePic) {
        this.setState({ profilePic: defaultProfilePic });
      }
    }
  };

  async logoutUser() {
    const userResponse = confirm('Do you want to logout?');
    if (userResponse) {
      await this.changeRedirection('/?logout_user=true', true);
    }
  }

  async removeUserDataFromStorage() {
    await removeStorageData('userRole');
    await removeStorageData('profilePic');
    await removeStorageData('profileUsername');
    await removeStorageData('cartCount');
    await removeStorageData('wishlistCount');
    await removeStorageData('cartCount');
    await removeStorageData('profilePhoneNumber');
  }

  render() {
    const {
      isEdit,
      handleFromDate,
      handleToDate,
      formDate,
      isStudent,
      toDate,
      actionButtons,
      backData,
      title,
      isAdd,
      isLoading,
      newUI,
      disableSearch,
    } = this.props;

    const isProfileDisabled = window.location.pathname === '/AdminUserProfile';

    return (
      <ThemeProvider theme={theme}>
        <CustomDrawer isVisible={true} />
        <Loader loading={isLoading || false} />
        <NavBar
          position="fixed"
          style={{
            ...webStyle.showDrawer,
            background: newUI
              ? '#F1F5F9'
              : isAdd || isEdit
              ? 'rgb(252, 252, 252)'
              : 'white'
          }}
          className={newUI ? 'new-ui' : ''}
        >
          <Toolbar
            style={
              newUI
                ? { background: 'white', borderBottom: '1px #E2E8F0 solid' }
                : isAdd || isEdit
                ? { borderBottom: '1px solid black', marginBottom: '20px' }
                : { marginBottom: '20px' }
            }
          >
            <Box className={newUI ? 'nav_contain new-ui' : 'nav_contain'}>
              <Box
                className="nav_text_boxes"
                style={!(isAdd || isEdit) ? { marginBottom: 20 } : {}}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <MenuIcon
                    data-test-id="toggleButton"
                    className="drower_menu"
                  />
                  {(isAdd || isEdit) && backData && (
                    <MdChevronLeft
                      onClick={() => backData()}
                      style={{ fontSize: '28px', cursor: 'pointer' }}
                    />
                  )}
                  {title && (
                    <Typography
                      className="main_headings"
                      data-test-id="seriolNoTestId"
                      variant="h6"
                    >
                      {title}
                    </Typography>
                  )}
                </Box>
                <Box className="avtar_boxes">
                  <IconButonStyled
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={e => this.handleClick(e)}
                  >
                    <img
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 30,
                        border: '2px #F0E5FF solid'
                      }}
                      src={this.state.profilePic}
                      alt="avatar"
                    />
                  </IconButonStyled>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.handleClose()}
                  >
                    <MenuItem
                      data-test-id = "changeRedirection"
                      disabled={isProfileDisabled}
                      onClick={async () =>
                        await this.changeRedirection('/AdminUserProfile',false)
                      }
                    >
                      <ListItemIcon style={{ minWidth: 30, fontSize: '1rem' }}>
                        <AccountCircleIcon fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </MenuItem>
                    <MenuItem onClick={async () => await this.logoutUser()}>
                      <ListItemIcon style={{ minWidth: 30, fontSize: '1rem' }}>
                        <ExitToAppIcon fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              {!(isAdd || isEdit) && (
                <div style={{ display: 'flex' }}>
                  <Box className="search_boxes">
                    {!disableSearch && (
                      <OutlinedInput
                        className="search_input"
                        style={isStudent ? {} : { width: '328px' }}
                        placeholder="Search"
                        type="text"
                        data-test-id="searchBoxTestId"
                        onChange={e => this.handleSearchChange(e)}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: 0 }}
                          >
                            <img src={searchIcone} alt="" />
                          </InputAdornment>
                        }
                      />
                    )}
                    {isStudent && (
                      <Box
                        style={{
                          display: 'inline-flex',
                          flex: 2,
                          gap: '1rem',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        className="date_fields"
                      >
                        <Box
                          className="date_boxs"
                          style={{ position: 'relative' }}
                        >
                          <DateInput
                            id="datetime-local"
                            data-test-id="isSeletedDate1ID"
                            value={formDate}
                            inputProps={{
                              style: {
                                borderBottom: 'none',
                                height: 48,
                              },
                              inputProps: {
                                max: new Date().toJSON().slice(0, 10),
                              },
                            }}
                            onChange={handleFromDate}
                          />
                          <Typography
                            style={{
                              color: 'black',
                              position: 'absolute',
                              left: 0,
                              top: '-60%',
                              fontWeight: 'bold',
                              fontSize: '0.75rem'
                            }}
                          >
                            Search with Date
                          </Typography>
                        </Box>
                        <Typography style={{ color: 'black' }}>to</Typography>
                        <DateInput
                          dataTestId="isSelectedDate2ID"
                          id="datetime-local"
                          value={toDate}
                          onChange={handleToDate}
                          inputProps={{
                            style: {
                              borderBottom: 'none',
                              height: 48,
                            },
                            inputProps: {
                              min: formDate
                                ? new Date(formDate).toJSON().slice(0, 10)
                                : new Date().toJSON().slice(0, 10),
                            },
                          }}
                        />
                      </Box>
                    )}
                    <Box className="btn_box">
                      {actionButtons &&
                        actionButtons.map(res => (
                          <Button
                            data-test-id={res.testId}
                            onClick={res.onClick}
                            variant="outlined"
                            className="btns"
                            {...(res.iconType === 'jsxElement'
                              ? { startIcon: res.icon }
                              : {})}
                          >
                            {res.iconType === 'imgUrl' && (
                              <img
                                src={res.icon}
                                className="icon_color"
                                alt="plus"
                                style={{ marginRight: '9px' }}
                              />
                            )}
                            {res.text}
                          </Button>
                        ))}
                    </Box>
                  </Box>
                </div>
              )}
            </Box>
          </Toolbar>
          {this.props.children}
        </NavBar>
      </ThemeProvider>
    );
  }
}

export default MainLayout;

const NavBar = styled(AppBar)({
  marginLeft: '250px',
  backgroundColor: '#fff',
  fontFamily: 'Inter !important',

  '&.new-ui': {
    '& .table_content': {
      height: '89vh',
      overflowX: 'scroll',
      paddingTop: 20,
      paddingBottom: 20,
    },
    '& .table_data': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      overflow: 'scroll',
      '@media (max-width:950px)': {
        padding: '10px',
        overflowX: 'scroll'
      },
    },
  },
  '& .table_data': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflow: 'scroll',
    height: '100%',
    '@media (max-width:950px)': {
      padding: '10px',
      overflowX: 'scroll'
    },
  },
  '& .table_content': {
    height: '80vh',
    overflowX: 'scroll'
  },

  '& .nav_text_boxes': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000',
    alignItems: 'center',
    '& .drower_menu': {
      display: 'none',
      height: '35px',
      width: '35px',
      color: 'black',
      '@media (max-width:970px)': {
        display: 'block'
      },
    },
    '& .main_headings': {
      color: '#000',
      fontSize: '1.375rem',
      fontWeight: 700,
      letterSpacing: '-0.12px',
      fontStyle: 'normal',
      lineHeight: '32px'
    },
    '& .avtar_boxes': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
  },
  '& .nav_contain': {
    padding: '35px 0 20px 0',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  '& .nav_contain.new-ui': {
    padding: '20px 0 20px 0',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  '& .search_boxes': {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    flex: 1,
    gap: '1rem',

    '& .search_input': {
      borderRadius: '2px',
      height: '48px',
      width: '328px',

      '& .icon_color': { color: '#979797' },
      '@media (max-width:1437px)': {
        width: '100%'
      },
      '& input::placeholder': {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '24px',
        color: '#979797 !important'
      },
    },

    '@media (max-width:1437px)': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    '& .btn_box': {
      display: 'flex',
      flex: 2,
      gap: '1rem',
      justifyContent: 'flex-end',
      '& .btns': {
        backgroundColor: '#1F6575',
        color: '#fff',
        textTransform: 'none',
        height: '47px',
        fontSize: '0.75rem',
        width: '180px',
        boxShadow: 'unset',
        borderRadius: 0,
        '& .MuiButton-iconSizeMedium > :first-child': {
          fontSize: '0.75rem'
        },
        '@media (max-width:1437px)': {
          flex: '0 0 auto',
          display: 'inline-flex'
        },
      },
    },
  },
  '& .date_boxs': {
    display: 'flex',
    justifyContent: 'flex-start',

    '& .MuiSvgIcon-root': {
      height: '1.1em !important',
      width: '1.1em !important'
    },
    '& .date_input': {
      '& .MuiInputBase-root': {
        fontSize: '0.75rem'
      },
      '& input': {
        textTransform: 'uppercase',
        minWidth: '144.53px'
      },
      '& input::-moz-calendar-picker-indicator': {
        display: 'none',
        WebkitAppearance: 'none'
      },
    },
  },
});

const IconButonStyled = styled(IconButton)({
  '&.MuiIconButton-root': {
    padding: 0,
  },
});

const webStyle = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: '100svh'
  },
  bundleCourseText: {
    display: 'flex',
    paddingLeft: '28px',
    fontSize: '1.375rem',
    color: 'black',
    paddingTop: 6,
    borderTop: '1px solid grey'
  },
  adminSearch: {
    width: ' 100%',
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid var(--gray)',
    padding: '14px 15px',
    borderRadius: '3px'
  },
  dateInput: {
    border: '1px solid black',
    borderRadius: '5px'
  },
  adminInput: {
    outline: 'none',
    background: 'none',
    border: 'none',
    color: 'black',
    fontSize: '1.1rem',
    marginLeft: '20px'
  },
  profileInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90px',
    marginRight: '20px'
  },
  userTitle: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.12px'
  },
  addExportText: {
    color: ' var(--Basic-White, #FFF)',
    fontFamily: 'Inter',
    fontSize: '0.688rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px'
  },
  addExportBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '10px',
    backgroundColor: '#1F6575',
    width: '150px',
    height: '47px',
    cursor: 'default',
    border: 'none',
    fontSize: '0.875rem'
  },
  adminHeader: {
    width: '100%',
    display: 'flex',
    marginBottom: '28px'
  },
  commonAdminHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bellCircle: {
    top: 0,
    right: '3px'
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--imgSize)',
    height: 'var(--imgSize)',
    borderRadius: '50%'
  },
  searchInput: {
    width: '350px',
    height: '45px',
    borderRadius: '2px'
  },
  iconColor: {
    color: '#b2b2b2'
  },
  customDatePicker: {
    display: 'inline-block'
  },
  dateInput2: {
    width: '190px',
    height: '55px'
  },
  calendarIcon: {
    top: '50%',
    right: '10px',
    transform: ' translateY(-50%)',
    cursor: 'pointer'
  },
  dateLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '250px',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 700,
  },
  toText: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px'
  },
};
