// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Chip,
  createTheme,
  ThemeProvider,
  responsiveFontSizes
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyCourseController, { Props } from "./MyCourseController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { study, myCourseBg } from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import MyCourseCardWeb from "../../../components/src/MyCourseCard.web";

export const webStylesLocal: Record<string, CSSProperties> = {
  leftRightLable: {
    marginBottom: "10px"
  },
  instructorLang: {
    color: "#FFF",
    fontSize: "9px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.011px"
  },
  instructorLangName: {
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.017px"
  },
  chapterLectureTimeHead: {
    display: "flex",
    columnGap: "7.5px"
  },
  chapterLectureTimeText: {
    color: "#FFF",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: 3
  },
  aboutCourseRight: {
    rowGap: "10px"
  },
  aboutCourseLeftRight: {
    display: "flex",
    gap: "10px",
    color: "white",
    padding: "0 10px 0 10px",
    justifyContent: "space-between"
  },
  fullDesc: {
    margin: "12px 0",
    padding: 0,
    color: " #FFF",
    fontSize: "13px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.151px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden"
  },
  courseShortDescHover: {
    color: "#FFF",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    fontWeight: 600,
    marginTop: "9px",
    marginBottom: "9px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    overflow: "hidden"
  },
  aboutCourse2: {
    marginTop: "10px"
  },
  courseName: {
    border: "1px solid white",
    background: "white",
    color: "#1220C1",
    width: "max-content",
    borderRadius: "26.85px",
    padding: "5px 7px",
    height: "min-content",
    fontSize: "12px"
  },
  courseNameTop: {
    display: "flex",
    justifyContent: "space-between"
  },
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(23%, 1fr))",
    gap: "clamp(7rem, 2vw, 8rem)",
    width: "100%",
    background: "white",
    padding: "20px 14px",
    boxShadow: "0px 1px 8px 0px #00000025",
    boxSizing: "border-box"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "10px 12px",
    boxShadow: "0px 1px 8px 0px #00000025",
    boxSizing: "border-box"
  },
  bundleCourseMainLayout: {
    width: "100%",
    margin: "0 auto",
    marginBottom: 30
  },
  addToCart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    boxSizing: "border-box",
    margin: "auto"
  },
  addToCartList: {
    display: "flex",
    width: "20%",
    padding: "16px",
    boxSizing: "border-box",
    margin: "auto",
    alignItems: "end",
    justifyContent: "end"
  },
  instructorNameMain: {
    width: "100%",
    padding: 7,
    background: "#0E599D",
    marginBottom: "6px",
    display: "flex",
    justifyContent: "flex-start"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    marginTop: "14.3px",
    justifyContent: "space-between",
    width: "100%"
  },
  aboutCourseList: {
    display: "inline-flex",
    gap: "25px",
    width: "100%",
    alignItems: "center"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
    padding: "0 12px",
    boxSizing: "border-box",
    maxWidth: 1440
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  headerContainer: {
    display: "flex",
    color: "white",
    position: "relative",
    width: "100%"
  },
  headerImage: {
    maxWidth: 300,
    position: "absolute",
    top: "-15px",
    right: 0
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#2a9e6d",
    fontSize: "24px",
    gap: "0.2rem",
    borderRadius: "10px",
    padding: "1rem 1.5rem",
    background: "#f6f6f6",
    alignItems: "center"
  }
};

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class MyCourse extends MyCourseController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  renderList() {
    const countCourses = () => {
      return this.state.coursesList.length
    };
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={webStylesLocal.adminLayout} className="container-auto-margin">
          <div style={webStylesLocal.headerContainer}>
            <div style={{ maxWidth: "800px", marginRight: "auto" }}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20
                }}
              >
                <h1>My Courses</h1>
                <Chip
                  style={{ color: "white", background: "#FCB910" }}
                  label={`${countCourses()} ITEMS`}
                  data-test-id="itemsCounter"
                />
              </div>
              <p>
                Save and curate your desired courses, and easily plan your
                learning journey and access to your favorite educational
                resources with just a click.
              </p>
            </div>
            <div style={webStylesLocal.headerImage}>
              <img
                src={study}
                alt=""
                width={300}
                height={80}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              width: "100%"
            }}
          >
            <div data-test-id="courseCardGridTestId" style={webStylesLocal.card}>
              {this.state.coursesList.map((item, index) => (
                <React.Fragment key={item.id}>
                    <MyCourseCardWeb index={index} item={item} data-test-id="courseCardTestID" />
                </React.Fragment>
              ))}
              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                  margin: "auto",
                  width: "100%",
                  height: "35vh"
                }}
              >
                {this.state.isLoading && (
                  <CircularProgress
                    style={{ margin: "auto", color: "#2a9e6d" }}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                `linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px), url(${myCourseBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "darken",
              padding: "25px 0"
            }}
          >
            {this.renderList()}
            <FooterWeb id="footer" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
