import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface FaqAttributes {
  topic_name: string;
  topic_description: string;
}

export interface Faq {
  data: FaqData;
}

export interface FaqData {
  id: string;
  type: string;
  attributes: FaqAttributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface FaqListResponse {
  data: FaqData[];
  meta: {
    page: string;
    total_faqs: number;
  };
}
interface FaqAttributes {
  topic_name: string;
  topic_description: string;
  created_on: string;
  updated_on: string;
}

interface FaqSw {
  id: string;
  type: string;
  attributes: FaqAttributes;
}

interface FaqApi {
  data: FaqSw[],
  meta: {
    page: string,
    total_faqs: number
}
}

const descriptionNameRegex = /^(?!^[ \t]*$)(?!^[^a-zA-Z0-9]*$)(?!^\d*$).*$/;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  navigation: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  pageNo: number;
  perPage: number;
  totalPages: number;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  faqList: FaqData[];
  openDeleteModal: boolean;
  deleteId: number | string;
  isUpdate: boolean;
  faqId: string | number;
  description: string;
  descriptionError: string;
  name: string;
  nameError: string;
  expanded: string | false;
  hasMore: boolean;
  faqs: FaqSw[];
  page: number;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class FaqController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFaqListApiCallId: string = "";
  showFaqApiCallId: string = "";
  submitFaqApiCallId: string = "";
  deleteFaqApiCallId: string = "";
  getSWFaqsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      pageNo: 1,
      perPage: elementsPerPage,
      totalPages: 1,
      loader: false,
      isAlert: false,
      alertMsg: "",
      alertType: configJSON.successMsg,
      isUpdate: false,
      faqList: [],
      faqId: "",
      description: "",
      descriptionError: "",
      name: "",
      nameError: "",
      openDeleteModal: false,
      deleteId: "",
      expanded: false,
      hasMore: true,
      faqs: [],
      page: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.errors) {
        if (this.isValidResponse(responseJson)) {
          this.apiSuccessCallBacks(apiRequestCallId, responseJson);
        }
      } else {
        this.parseErrors(responseJson, apiRequestCallId)
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowFaq(propsData.faqId);
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.SessionResponseData));
      if (data) {
          this.getStudentFaqsData();
          window.scrollTo(0, 0);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    this.getStudentFaqsData()
  }
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }, apiCallId: string) => {
     if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    }else if(apiCallId === this.submitFaqApiCallId) {
      this.handleUpdateFaqResponse(responseJson);
      this.navigationLoginScreen();
    }else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    await this.getFaqList();
  };

  deleteFaqApiResponse() {
    this.setState({ openDeleteModal: false }, () => this.getFaqList());
  }

  faqApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: Faq | FaqListResponse | ErrorResponse | FaqApi
  ) => {
    switch (apiRequestCallId) {
      case this.getFaqListApiCallId:
        this.handleGetFaqResponse(responseJson as FaqListResponse);
        break;
      case this.showFaqApiCallId:
        this.handleShowFaqResponse(responseJson as Faq);
        break;
      case this.submitFaqApiCallId:
        this.handleUpdateFaqResponse(responseJson as ErrorResponse);
        break;
      case this.deleteFaqApiCallId:
        this.deleteFaqApiResponse();
        break;
      case this.getSWFaqsApiCallId: 
       this.handleStudentFaqsApiResponse(responseJson as FaqApi)
       break;
    }
  };

  handleStudentFaqsApiResponse = (response: FaqApi) => {
    if (response.data) {
      this.setState({ 
        faqs : [...this.state.faqs, ...response.data], 
        hasMore: (response.meta.total_faqs/5) > this.state.page})
      }
  }
  handleUpdateFaqResponse = async (response: ErrorResponse) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token === configJSON.invalidTokenMessage
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Invalid token",
        alertType: configJSON.alertTypeError,
        loader: false,
      });
    } else {
      const alertMsg = configJSON.aboutUsUpdateSuccessMsg;
      const alertType: Color = configJSON.alertTypeSuccess;

      this.handleFieldReset();
      this.setState(
        {
          isAlert: true,
          alertMsg,
          alertType,
          loader: false,
        },
        () => {
          this.navigationFaq();
        }
      );
    }
  };

  handleShowFaqResponse = (response: Faq) => {
    const {
      data: {
        id,
        attributes: { topic_description, topic_name },
      },
    } = response;

    this.setState({
      loader: false,
      description: topic_description,
      name: topic_name,
      isUpdate: true,
      faqId: id,
    });
  };

  handleGetFaqResponse = (response: FaqListResponse) => {
    if (response && response.data) {
      const pageCount = Math.ceil(
        response.meta.total_faqs / this.state.perPage
      );
      this.setState({
        faqList: response.data,
        loader: false,
        totalPages: pageCount,
      });
    }
  };

  handlePageChange = (event: unknown, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getFaqList();
    });
  };

  handleInputChange = ({
    target,
  }: {
    target: { value: string; name: string };
  }) => {
    const { name, value } = target;
    let errorMessage = "";
    if (!descriptionNameRegex.test(value)) {
      errorMessage = `Please enter a valid ${
        name === "name" ? configJSON.nameText : configJSON.topicDescriptionText
      }`;
    }
    const values: unknown = {
      [name]: value,
      [`${name}Error`]: errorMessage,
    };
    this.setState(values as Pick<S, keyof S>);
  };

  handleFieldReset = () => {
    this.setState({ description: "", name: "" });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getBlankField = (description: string, name: string): unknown => {
    if (!descriptionNameRegex.test(description)) {
      return {
        descriptionError: `Please enter a valid ${configJSON.topicDescriptionText}`,
      };
    }
    if (!descriptionNameRegex.test(name)) {
      return {
        nameError: `Please enter a valid ${configJSON.nameText}`,
      };
    }
    return null;
  };

  handleOpenModal = (id: string) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id,
    });
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };

  handleDeleteFaq(id: string | number) {
    this.setState({ loader: true });
    this.deleteFaqApiCallId = this.faqApiCall({
      method: configJSON.deleteApiMethod,
      endPoint: configJSON.getFaqApiEndpoint + `/${id}`,
      token: this.state.userToken,
    });
  }

  handleUpdateFaq = async () => {
    this.setState({ loader: true });
    const { description, name } = this.state;
    const blankField = this.getBlankField(description, name);

    if (blankField) {
      this.setState({
        ...(blankField as object),
        loader: false,
      });
    } else {
      const body = {
        faq: {
          topic_description: description,
          topic_name: name,
        },
      };
      this.submitFaqApiCallId = this.faqApiCall({
        contentType: configJSON.validationApiContentType,
        method: this.state.isUpdate
          ? configJSON.examplePutAPiMethod
          : configJSON.exampleAPiMethod,
        endPoint: `${configJSON.getFaqApiEndpoint}/${this.state.faqId}`,
        body: body,
      });
    }
  };

  handleShowFaq = async (faqId: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.getFaqApiEndpoint}/${faqId}`;
    this.showFaqApiCallId = this.faqApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint,
    });
  };

  getFaqList = async () => {
    this.setState({ loader: true });
    const endPoint =
      configJSON.getFaqApiEndpoint +
      `/list_faqs?page=${this.state.pageNo}&per_page=${this.state.perPage}`;
    this.getFaqListApiCallId = this.faqApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint,
    });
  };

  handleAddFaqNavigation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddFaqMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigationEditFaq = (faqId: string | number) => {
    const editFaqScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddFaqMessage)
    );
    editFaqScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editFaqScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), {
      faqId: faqId,
    });
    this.send(editFaqScreenMsg);
  };

  navigationFaq = () => {
    const faqScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationFaqMessage)
    );
    faqScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(faqScreenMsg);
  };
  getStudentFaqsData = (page: number = 1) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSWFaqsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.swFaqsApiEndPoint + `?page=${page}&per_page=5`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeAccordion = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleSeeMore = () => {
    this.setState({ page : this.state.page + 1}, () => {
      this.getStudentFaqsData(this.state.page);
    })
  };

  // Customizable Area End
}
