// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../couponcodegenerator/src/VouchercodegeneratorController";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import {
  ClassesDatum,
  CourseDetailData,
  CourseDetailResponse,
  CourseDetailsAttributes,
  RelatedCoursesResponse,
  SpecfifcContentDuration,
} from "./CourseDetailController.web";
import { CoursesResponse, Datum } from "./MyCourseController.web";
import { ResponseErrors } from "../../user-profile-basic/src/AdminStateListController.web";

export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
    navigate: (value: string) => void;
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isLoading: boolean;
  searchText: string;
  coursesList: any[];
  courseId: string;
  currentCourse: string;
  page: number;
  perPage: number;
  loadMoreDisabled: boolean;
  courseDetail: CourseDetailsAttributes | undefined;
  currentTab: number;
  tabs: unknown[];
  relatedCourses: CourseDetailData[];
  isDrawerOpen: boolean;
  playlist: ClassesDatum[];
  chapterId: string;
  classId: string;
  chapterDetails: SpecfifcContentDuration | undefined;
  classDetails: ClassesDatum | undefined;
  openedAccordions: number[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class MyCourseController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = "";
  getRelatedCoursesApiCallId: string = "";
  getCourseDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.state = {
      page: 1,
      perPage: elementsPerPage,
      alertMsg: "",
      searchText: "",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      userToken: "",
      coursesList: [],
      loadMoreDisabled: false,
      courseId: "",
      currentCourse: "",
      relatedCourses: [],
      currentTab: 0,
      tabs: [],
      courseDetail: undefined,
      isDrawerOpen: true,
      playlist: [],
      chapterId: "",
      classId: "",
      chapterDetails: undefined,
      classDetails: undefined,
      openedAccordions: []
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCoursesApiCallId:
            this.handleGetCoursesResponse(responseJson);
            break;
          case this.getCourseDetailApiCallId:
            this.handleGetDetailsResponse(responseJson);
            break;
          case this.getRelatedCoursesApiCallId:
            this.handleGetRelatedCoursesResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const isDrawerOpen = await getStorageData("isCourseDrawerOpen");
    const token = await getStorageData("loginToken");
    const id = this.props.navigation.getParam("id");
    const currentCourse = this.props.navigation.getParam("courseId");
    const currentChapter = this.props.navigation.getParam("chapterId");
    const currentClass = this.props.navigation.getParam("classId");
    if (token && id) {
      this.setState({
        userToken: token,
        courseId: id,
        currentCourse: currentCourse,
        chapterId: currentChapter,
        classId: currentClass,
        isDrawerOpen: isDrawerOpen === "true",
      });
      this.getCourseDetail(currentCourse);
      this.getCourses();
    } else {
      this.navigationLoginScreen();
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  convertToHoursAndMinutes(decimalHours: number, onlyHours = false) {
    const totalMinutes = Math.floor(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return onlyHours ? hours : `${hours} Hours, ${minutes} Minutes`;
  }

  convertMinutesToTimeFormat(minutes: string) {
    const hours = Math.floor(parseFloat(minutes) / 60);
    const mins = Math.round(parseFloat(minutes) % 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(mins).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  handleExpandAllAccordions(indexArray: number[]) {
    this.setState({
      openedAccordions:
        this.state.openedAccordions.length === indexArray.length
          ? []
          : indexArray
    });
  }

 handleAccordionChange(index: number) {
    const array = [...this.state.openedAccordions],
      findAccordion = array.findIndex(v => index === v);
    if (findAccordion > -1) {
      this.setState({
        openedAccordions: [
          ...this.state.openedAccordions.filter(v => v !== index)
        ]
      });
    } else {
      this.setState({
        openedAccordions: [...this.state.openedAccordions, index]
      });
    }
  }

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  getCourses() {
    this.setState({ isLoading: true });
    this.getCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getAllCoursesApiEndpoint +
        `?search=${this.state.searchText}`,
      method: configJSON.getMethod,
      token: this.state.userToken,
      contentType: configJSON.apiContentType,
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false,
    });
  }

  navigationHomeScreen = () => {
    const messageHome: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    messageHome.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageHome);
  };

  handleGetCoursesResponse(responseJson: Record<string, any>) {
    if (responseJson && responseJson.data) {
      this.setState({
        coursesList: [...this.state.coursesList, ...responseJson.data],
        loadMoreDisabled: responseJson.data.length < elementsPerPage,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false, loadMoreDisabled: true });
    }
  }

  handleGetDetailsResponse(responseJson: CourseDetailResponse) {
    if (responseJson && responseJson.data) {
      let classId = this.state.classId,
        chapterId = this.state.chapterId;
      if (!classId) {
        this.setState({
          classId: `${responseJson.data.attributes.specfifc_content_duration[0]?.classes_data[0].id}`,
        });
        classId = `${responseJson.data.attributes.specfifc_content_duration[0]?.classes_data[0].id}`;
      }
      if (!chapterId) {
        this.setState({
          chapterId: `${responseJson.data.attributes.specfifc_content_duration[0]?.id}`,
        });
        chapterId = `${responseJson.data.attributes.specfifc_content_duration[0]?.id}`;
      }
      const playlist = responseJson.data.attributes.specfifc_content_duration.find(
          (v) => v.id === parseInt(chapterId)
        ),
        classDetails = playlist?.classes_data.find(
          (v) => v.id === parseInt(classId)
        );
      this.setState(
        {
          courseDetail: responseJson.data.attributes,
          isLoading: false,
          playlist: playlist?.classes_data || [],
          chapterDetails: playlist,
          classDetails: classDetails,
        },
        () => this.getRelatedCourse()
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  getCourseDetail(courseId: string) {
    this.setState({ isLoading: true });
    this.getCourseDetailApiCallId = apiCall({
      endPoint: configJSON.getCourseDetailApiEndpoint + courseId,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  getRelatedCourse() {
    this.setState({ isLoading: true });
    this.getRelatedCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getRelatedCoursesApiEndpoint +
        `?course_id=${this.state.currentCourse}&course_child_category_id=${
          this.state.courseDetail!.course_child_category.id
        }`,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  handleGetRelatedCoursesResponse(responseJson: RelatedCoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        relatedCourses: responseJson.data,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  // Customizable Area End
}
// Customizable Area End
