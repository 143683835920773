import React from 'react';
import { Color } from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const NotificationAlertMessage = (props: {
  isOpen: boolean;
  onClose: any;
  alertType: Color;
  msg: string;
  dataTestId?: string;
  invisible?: boolean;
}) => {
  const properties: any = {
    autoHideDuration: 3000,
    style: props.invisible ? { display: 'none' } : {top: "14%"},
    open: props.isOpen,
    onClose: props.onClose,
    anchorOrigin: { horizontal: 'center', vertical: 'top' },
    ...(props.dataTestId ? { 'data-test-id': props.dataTestId } : {}),
  };
  const alertProperties = {
    severity: props.alertType,
    msg: props.msg,
  };
  return (
    <Snackbar {...properties}>
      <Alert severity={alertProperties.severity}>{alertProperties.msg}</Alert>
    </Snackbar>
  );
};
export default NotificationAlertMessage;
