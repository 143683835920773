import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  InputAdornment,
  styled,
  Badge,
  Avatar,
  Chip,
  IconButton,
  createTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import UserSidebarMenu from "../../../components/src/UserSideBarMenu";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import CreateIcon from "@material-ui/icons/Create";
import {
  TwoColBox,
  InputBox,
  OneColBox,
  DateInput,
  StyledSelect
} from "../../../components/src/GenericInputs";
import MainLayout from "../../../components/src/MainLayout.web";
import { FaChevronDown } from "react-icons/fa";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import UserProfileController, { Props } from "./UserProfileController.web";
import { CreateButton, ButtonContainer, ResetButton } from "./Achievements.web";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import moment from "moment";
let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

// Customizable Area End

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderHeader = (isAdmin: boolean) => {
    return (
      <div style={userProfileStyles.headerContainer}>
        <div>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={userProfileStyles.avatar}
            badgeContent={
              this.state.isEditMode &&
              !this.state.isLoading && (
                <ProfilePicInput
                  handleUserPhoto={e => this.handleUserPhoto(e)}
                />
              )
            }
          >
            <Avatar
              alt="user"
              src={this.state.photoPreview}
              style={{ width: 100, height: 100 }}
            />
          </Badge>
        </div>
        <div style={userProfileStyles.titleSection}>
          <div style={userProfileStyles.titleContainer}>
            {this.state.isLoading ? (
              <Skeleton variant="text" style={{ width: 200 }} />
            ) : (
              <Typography
                className="fullNameHeader"
                style={{
                  color: isAdmin ? "black" : "white",
                  fontWeight: "bold",
                  fontSize: "1.7rem"
                }}
              >
                {this.state.fullName}
              </Typography>
            )}
            {!this.state.isEditMode && !this.state.isLoading && (
              <IconButton
                color="inherit"
                aria-label="upload picture"
                component="span"
                data-test-id="editModeTestId"
                size="small"
                onClick={() => this.toggleEditMode()}
                style={{
                  fontSize: "0.8rem",
                  color: isAdmin ? "black" : "white"
                }}
              >
                <CreateIcon fontSize="inherit" />
              </IconButton>
            )}
          </div>
          {isAdmin && (
            <div>
              <Chip
                label="Admin"
                style={userProfileStyles.roleChip}
                avatar={
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Role
                  </span>
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  renderInputView = (isAdmin: boolean) => {
    return (
      <StyledBox className={isAdmin ? "admin_view" : ""}style={userProfileStyles.mainLayout}>
        {!isAdmin && (
          <SideBox>
            <UserSidebarMenu
              userPic={this.state.photoPreview}
              fullName={this.state.fullName}
              phoneNumber={"+91 " + this.state.mobile}
              pathname={window.location.pathname}
            />
          </SideBox>
        )}
        <Box
          sx={{
            ...userProfileStyles.columnLayout,
            ...(!isAdmin ? { width: "85%" } : {width: "90%"})
          }}
        >
          {this.renderHeader(isAdmin)}
          <OneColBox className="align-column">
            <Box className="child__box align-column">
              <Typography className="box__label">Full Name</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="fullNameTestId"
                  variant="outlined"
                  fullWidth
                  name="fullName"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.fullName}
                  disabled={!this.state.isEditMode}
                />
                <span style={webStyles.errorText} data-test-id="fullNameError">
                  {this.state.fullNameError}
                </span>
              </FormControl>
            </Box>
          </OneColBox>
          <TwoColBox>
            <Box className="child__box align-column">
              <Typography className="box__label">Email Address</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="emailTestId"
                  variant="outlined"
                  fullWidth
                  name="email"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.email}
                  disabled={!this.state.isEditMode || !isAdmin}
                />
                <span style={webStyles.errorText}>{this.state.emailError}</span>
              </FormControl>
            </Box>
            <Box className="child__box align-column">
              <Typography className="box__label">Mobile</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputAdornmentField
                  data-test-id="mobileId"
                  variant="outlined"
                  fullWidth
                  name="mobile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    )
                  }}
                  disabled={this.handleNumberInputDisable(isAdmin)}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.mobile}
                />
                <span
                  style={webStyles.errorText}
                  data-test-id="phone_number_err"
                >
                  {this.state.mobileError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box align-column">
              <Typography className="box__label">Date of Birth</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="dateOfBirth"
                  value={this.state.dateOfBirth}
                  onChange={e => this.handleInputChange(e)}
                  data-test-id="dateOfBirthTestId"
                  inputProps={{
                    inputProps: {
                      max: moment()
                        .subtract(isAdmin ? 18 : 8, "years")
                        .format("YYYY-MM-DD")
                    }
                  }}
                  disabled={!this.state.isEditMode}
                />
                <span style={webStyles.errorText}>
                  {this.state.dateOfBirthError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box align-column">
              <Typography className="box__label">Gender</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  data-test-id="genderTestId"
                  disableUnderline
                  variant="outlined"
                  IconComponent={FaChevronDown}
                  fullWidth
                  name="gender"
                  disabled={!this.state.isEditMode}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.gender}
                >
                  <option aria-label="None" value="" disabled>
                    {"Select Gender"}
                  </option>
                  {this.state.gendersList &&
                    this.state.gendersList.map(gender => (
                      <option key={gender.label} value={gender.value}>
                        {gender.label}
                      </option>
                    ))}
                </StyledSelect>
                <span style={webStyles.errorText}>
                  {this.state.genderError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          {!isAdmin && (
            <OneColBox style={{ display: "flex", flexDirection: "column" }}>
              <Typography className="box__label">School / College</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="schoolTestId"
                  variant="outlined"
                  fullWidth
                  name="school"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.school}
                  disabled={!this.state.isEditMode}
                />
              </FormControl>
              <span style={webStyles.errorText}>
                  {this.state.schoolError}
                </span>
            </OneColBox>
          )}
          <TwoColBox>
            <Box className="child__box align-column">
              <Typography className="box__label">State</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  data-test-id="stateTestId"
                  disableUnderline
                  variant="outlined"
                  IconComponent={FaChevronDown}
                  fullWidth
                  name="stateId"
                  disabled={!this.state.isEditMode}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.stateId}
                >
                  <option aria-label="None" value="" disabled />
                  {this.state.stateList &&
                    this.state.stateList.map(state => {
                      return (
                        <option key={state.lable} value={state.value}>
                          {state.lable}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span style={webStyles.errorText}>
                  {this.state.stateIdError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box align-column">
              <Typography className="box__label">District</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  data-test-id="districtTestId"
                  disableUnderline
                  variant="outlined"
                  IconComponent={FaChevronDown}
                  fullWidth
                  name="districtId"
                  disabled={!this.state.isEditMode}
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.districtId}
                >
                  <option aria-label="None" value="" disabled />
                  {this.state.districtList &&
                    this.state.districtList.map(({ attributes }) => {
                      return (
                        <option key={attributes.name} value={attributes.id}>
                          {attributes.name}
                        </option>
                      );
                    })}
                </StyledSelect>
                <span style={webStyles.errorText}>
                  {this.state.districtIdError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          {this.state.isEditMode && (
            <ButtonContainer style={{ margin: "34px 0" }}>
              <ResetButton
                data-test-id="cancelButtonId"
                onClick={() => this.cancelEditMode()}
              >
                Cancel
              </ResetButton>
              <CreateButton
                data-test-id="submitButtonId"
                disabled={this.state.isLoading}
                onClick={() => this.handleSubmitProfile()}
              >
                Update
              </CreateButton>
            </ButtonContainer>
          )}
        </Box>
      </StyledBox>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {window.location.pathname === "/AdminUserProfile" ? (
          <MainLayout
            newUI
            disableSearch
            isLoading={this.state.isLoading}
            data-test-id="mainLayoutEvent"
            title={"Profile"}
            isEdit
            isAdd
          >
            <NotificationAlertMessage
              dataTestId="alertTestId"
              alertType={this.state.alertType}
              msg={this.state.alertMsg}
              onClose={this.closemodal}
              isOpen={this.state.isAlert}
            />
            <main
              className="table_content"
              style={{ background: "rgb(252, 252, 252)" }}
            >
              {this.renderInputView(true)}
            </main>
          </MainLayout>
        ) : (
          <ThemeProvider theme={theme}>
            <NotificationAlertMessage
              dataTestId="alertTestId"
              alertType={this.state.alertType}
              msg={this.state.alertMsg}
              onClose={this.closemodal}
              isOpen={this.state.isAlert}
            />
            <HeaderWeb navigation={this.props.navigation} id={""} />
            <main
              style={{
                overflowX: "scroll",
                maxWidth: "1920px"
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(60,116,129,1) 100px, rgba(255,255,255,1) 100px)",
                  padding: "25px 10px"
                }}
              >
                {this.renderInputView(false)}
                <>
                  <FooterWeb navigation={this.props.navigation} id={""} />
                </>
              </div>
            </main>
          </ThemeProvider>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SideBox = styled(Box)({
  width: "15%",
  "@media only screen and (max-width: 1598px)": {
    width: "18%"
  },
  "@media only screen and (max-width: 1525px)": {
    width: "20%"
  },
  "@media only screen and (max-width: 1366px)": {
    width: "22%"
  },
  "@media only screen and (max-width: 1175px)": {
    width: "25%"
  },
  "@media only screen and (max-width: 1070px)": {
    width: "27%"
  },
  "@media only screen and (max-width: 1000px)": {
    width: "30%"
  },
  "@media only screen and (max-width: 760px)": {
    width: "35%"
  },
  "@media only screen and (max-width: 677px)": {
    width: "37%"
  },
  "@media only screen and (max-width: 652px)": {
    width: "38%"
  }
});
const StyledBox = styled(Box)({
  width: "calc(97% - 55px)",
  margin: "0 auto",
  display: "flex",
  gap: 20,
  maxWidth: 1440,
  '@media (min-width: 900px) and (max-width: 1280px)' :{
    margin: "0px 32px",
  },
  '@media (min-width: 1281px) and (max-width: 1440px)' :{
    margin: "0px 55px",
  },
  '.admin_view' :{
    '@media (min-width: 1441px) and (max-width: 7100px)' :{
      margin: "0px auto auto 20px",
    }
  },
  '& .fullNameHeader': {
    textOverflow: 'ellipsis',
    overflow: "hidden",
    maxWidth: '80%',
    textWrap: 'nowrap'
  }
});

const InputAdornmentField = styled(InputBox)({
  "& .MuiOutlinedInput-input": {
    paddingLeft: 0
  }
});
export const ProfilePicInput = (props: {
  handleUserPhoto: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <>
      <input
        data-test-id="photoTestId"
        accept="image/png, image/jpeg, image/jpg"
        name="photo"
        id="photo"
        onChange={props.handleUserPhoto}
        type="file"
        style={{ display: "none" }}
      />
      <label htmlFor="photo">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          style={userProfileStyles.avatarChangePicButton}
        >
          <PhotoCameraOutlinedIcon fontSize="inherit" color="inherit" />
        </IconButton>
      </label>
    </>
  );
};
enum ProfileStylesProperties {
  headerContainer = "headerContainer",
  avatar = "avatar",
  avatarChangePicButton = "avatarChangePicButton",
  titleSection = "titleSection",
  titleContainer = "titleContainer",
  roleChip = "roleChip",
  columnLayout = "columnLayout",
  mainLayout = "mainLayout"
}
export const userProfileStyles: Record<
  ProfileStylesProperties,
  React.CSSProperties
> = {
  columnLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem"
  },
  mainLayout: {
    maxWidth: 1440
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "2rem"
  },
  avatar: {
    border: "2px solid rgb(240, 229, 255)",
    borderRadius: "100%"
  },
  avatarChangePicButton: {
    color: "black",
    fontSize: "0.8rem",
    background: "yellow"
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    color: "black",
    width: "100%",
    justifyContent: "center",
    gap: "0.5rem",
    height: 104
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.3rem"
  },
  roleChip: {
    border: "2px solid rgb(240, 229, 255)",
    padding: "0.8rem",
    alignItems: "center"
  }
};
// Customizable Area End
