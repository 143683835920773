import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall, validationsLoop } from "./FooterController.web";
import {
  navigationLoginScreen,
  parseErrors
} from "../../couponcodegenerator/src/FlatDiscountController.web";
export const configJSON = require("./config");

const phoneRegex = /^(\d\s?){10}$/,
  numericRegex = /^\d+(\.\d+)?$/,
  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  fullNameRegex = /^[a-zA-Z\s]{3,}$/;

export const defaultValues: Pick<
  S,
  "isLoading" | "userToken" | "isAlert" | "alertMsg" | "alertType"
> = {
  isLoading: false,
  userToken: "",
  isAlert: false,
  alertMsg: "",
  alertType: "success"
};

type FormValues = Omit<
  S,
  | "isLoading"
  | "userToken"
  | "isAlert"
  | "alertMsg"
  | "alertType"
  | "isEditMode"
  | "gendersList"
  | "defaultValuesForm"
  | "districtList"
  | "stateList"
  | "isDrawerOpen"
>;

export interface IState {
  lable: string;
  value: number;
}

export interface DistrictResponse {
  data: DatumDistrict[];
}

export interface DatumDistrict {
  id: string;
  type: string;
  attributes: IDistrict;
}

export interface IDistrict {
  id: number;
  name: string;
  created_on: string;
  updated_on: string;
}

export interface ValidationFieldsType {
  field: string;
  errorKey: string;
  errorMessage: string;
  regex?: RegExp;
}

export interface Props {
  navigation: unknown;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface UserProfileResponse {
  data: Data;
  errors: Record<string, string>[];
  meta: Meta;
}

export interface UserProfileUpdateResponse {
  data: Data[];
  errors: Record<string, string>[];
  meta: Meta;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  address: null;
  profile_country_id: null;
  state_id: null;
  city_id: null;
  studying_in_id: null;
  account_id: number;
  photo: string;
  full_name: string;
  school_name: string;
  state: null;
  city: null;
  state_details: Details;
  district_details: Details;
  country: null;
  studying_in: null;
  email: string;
  dob: string;
  gender: string;
  full_phone_number: string;
}

export interface Details {
  name: string;
  id: string;
}

export interface Meta {
  profile: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  mobile: string;
  mobileError: string;
  email: string;
  emailError: string;
  fullName: string;
  fullNameError: string;
  districtId: string;
  districtList: DatumDistrict[];
  districtIdError: string;
  stateId: string;
  stateList: IState[];
  stateIdError: string;
  schoolError: string;
  gender: string;
  genderError: string;
  school: string;
  gendersList: { label: string; value: string }[];
  dateOfBirth: string;
  dateOfBirthError: string;
  photo: unknown;
  photoName: string;
  photoPreview: string;
  isEditMode: boolean;
  defaultValuesForm: FormValues;
  isEmptyMobileNumber: boolean;
  isFormChanged: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileApiCallId: string = "";
  updateProfileApiCallId: string = "";
  getStateListApiCallId: string = "";
  getDistrictListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      ...defaultValues,
      defaultValuesForm: {
        mobile: "",
        mobileError: "",
        email: "",
        emailError: "",
        districtId: "",
        districtIdError: "",
        fullName: "",
        fullNameError: "",
        stateId: "",
        stateIdError: "",
        schoolError: "",
        gender: "0",
        genderError: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        photo: "",
        photoName: "",
        photoPreview: "",
        school: "",
        isEmptyMobileNumber: false,
        isFormChanged: false
      },
      stateList: [],
      districtList: [],
      mobile: "",
      mobileError: "",
      email: "",
      emailError: "",
      districtId: "",
      districtIdError: "",
      fullName: "",
      fullNameError: "",
      stateId: "",
      stateIdError: "",
      schoolError: "",
      gender: "0",
      genderError: "",
      school: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      gendersList: [
        { label: "Male", value: "0" },
        { label: "Female", value: "1" },
        { label: "Other", value: "2" }
      ],
      photo: "",
      photoName: "",
      photoPreview: "",
      isEditMode: false,
      isEmptyMobileNumber: false,
      isFormChanged: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.handleGetStates();
    this.getProfileData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getProfileApiCallId:
            this.handleGetProfileResponse(responseJson);
            break;
          case this.updateProfileApiCallId:
            this.handleSubmitProfileResponse(responseJson);
            break;
          case this.getDistrictListApiCallId:
            this.handleGetDistrictResponse(responseJson);
            break;
          case this.getStateListApiCallId:
            this.handleGetStatesResponse(responseJson);
            break;
        }
      } else if (responseJson.errors && responseJson.errors.phone_number) {
        this.setState({
          mobileError: responseJson.errors.phone_number,
          isLoading: false
        });
      } else {
        parseErrors(
          responseJson,
          this.parseApiErrorResponse,
          this.send,
          this.props
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  toggleEditMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  cancelEditMode = () => {
    const { defaultValuesForm } = this.state,
      {
        dateOfBirth,
        districtId,
        email,
        fullName,
        gender,
        mobile,
        photo,
        stateId
      } = defaultValuesForm,
      valuesToSet = {
        dateOfBirth,
        dateOfBirthError: "",
        districtId,
        districtIdError: "",
        email,
        emailError: "",
        fullName,
        fullNameError: "",
        gender,
        genderError: "",
        mobile,
        mobileError: "",
        photo,
        stateId,
        stateIdError: "",
        schoolError: ""
      };
    this.setState(valuesToSet);
    this.setState({ isLoading: false })
    this.toggleEditMode();
  };

  getGender(gender: string | null) {
    const genders = ["Male", "Female", "Other"];
    if (!gender) {
      return 0;
    }
    const index = genders.findIndex(v => v === gender);
    return index !== -1 ? index : 0;
  }

  handleGetProfileResponse(resJson: UserProfileResponse) {
    if (
      resJson.errors &&
      resJson.errors.length > 0 &&
      resJson.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      navigationLoginScreen(this.props, this.send);
    } else {
      const { attributes } = resJson.data,
        {
          email,
          district_details,
          full_phone_number,
          full_name,
          photo,
          gender,
          dob,
          state_details,
          school_name
        } = attributes,
        valuesToSet: unknown = {
          email: email,
          fullName: full_name,
          photoName: photo,
          photoPreview: photo,
          dateOfBirth: dob,
          gender: `${this.getGender(gender)}`,
          districtId: district_details.id,
          stateId: state_details.id,
          mobile: full_phone_number.slice(2),
          lastName: "",
          school: school_name
        };
      this.setState(
        {
          ...(valuesToSet as FormValues),
          defaultValuesForm: valuesToSet as FormValues,
          isLoading: false,
          isEmptyMobileNumber: full_phone_number === ""
        },
        () => this.handleGetDistricts(state_details.id)
      );
    }
  }

  handleGetStatesResponse = (response: IState[]) => {
    if (response) {
      this.setState({ isLoading: false, stateList: response });
    } else {
      this.setState({
        isLoading: false,
        stateList: [],
        districtList: [],
        stateId: "",
        districtId: ""
      });
    }
  };

  handleGetDistrictResponse = (response: DistrictResponse) => {
    if (response && response.data) {
      this.setState({
        isLoading: false,
        districtList: response.data
      });
    } else {
      this.setState({ isLoading: false, districtId: "", districtList: [] });
    }
  };

  handleGetStates = () => {
    this.setState({ isLoading: true });
    this.getStateListApiCallId = apiCall({
      method: configJSON.httpGetType,
      endPoint: configJSON.stateApiEndPoint,
      token: this.state.userToken
    });
  };

  handleGetDistricts = (stateId: string) => {
    this.setState({ isLoading: true });
    this.getDistrictListApiCallId = apiCall({
      method: configJSON.httpGetType,
      endPoint: configJSON.districtAPiEndPoint + stateId,
      token: this.state.userToken
    });
  };

  getProfileData() {
    this.setState({ isLoading: true });
    this.getProfileApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getUserProfileApiEndpoint,
      token: this.state.userToken
    });
  }

  handleUserPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target && event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          alertType: "error",
          isAlert: true,
          alertMsg:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
          photoName: ""
        });
      } else if (fileSize > 2 * 1024 * 1024) {
        this.setState({
          alertType: "error",
          isAlert: true,
          alertMsg: "File size exceeds 2MB. Please choose a smaller file.",
          photoName: ""
        });
      } else {
        const imageUrl = global.URL.createObjectURL(file);
        this.setState({
          photo: file,
          photoName: fileName,
          photoPreview: imageUrl
        });
      }
    } else {
      this.setState({
        photo: "",
        photoName: "",
        photoPreview: "",
        alertType: "error",
        isAlert: true,
        alertMsg: "No file is selected"
      });
    }
  };

  checkFormValidations(updatedValues: Record<string, unknown>): boolean {
    const validationFields = [
      {
        field: "fullName",
        errorKey: "fullNameError",
        errorMessage: "Full Name is Required",
        regex: fullNameRegex
      },
      {
        field: "email",
        errorKey: "emailError",
        errorMessage: "Email is Required",
        regex: emailRegex
      },
      {
        field: "mobile",
        errorKey: "mobileError",
        errorMessage: "Phone number is Required",
        regex: phoneRegex
      },
      {
        field: "dateOfBirth",
        errorKey: "dateOfBirthError",
        errorMessage: "Date Of Birth is Required"
      },
      {
        field: "gender",
        errorKey: "genderError",
        errorMessage: "Gender is Required"
      },
      {
        field: "districtId",
        errorKey: "districtIdError",
        errorMessage: "District is Required"
      },
      {
        field: "stateId",
        errorKey: "stateIdError",
        errorMessage: "State is Required"
      },
      {
        field: "school",
        errorKey: "schoolError",
        errorMessage: "School is Required"
      }
    ];

    return validationsLoop(validationFields, updatedValues, value =>
      this.setState(value)
    );
  }
  handleSubmitProfile() {
    if (this.state.isAlert) {
      this.setState({
        alertType: "error",
        isAlert: true,
        alertMsg: this.state.isAlert
          ? "Please address the alert before submitting."
          : "No changes to submit."
      });
      return;
    }
    this.setState({ isLoading: true });
    const {
      stateId,
      districtId,
      fullName,
      email,
      mobile,
      dateOfBirth,
      gender,
      photo,
      photoName,
      school
    } = this.state;
    const formValues = {
      email,
      mobile,
      stateId,
      districtId,
      fullName,
      dateOfBirth,
      gender,
      photoName,
      school
    };
    if (!this.checkFormValidations(formValues)) {
      const formdata = new FormData();
      if (photo) {
        formdata.append("profile[photo]", photo as Blob, photoName);
      }
      formdata.append("profile[date_of_birth]", dateOfBirth);
      formdata.append("profile[state_id]", stateId);
      formdata.append("profile[district_id]", districtId);
      formdata.append("profile[new_name]", fullName);
      formdata.append("profile[new_email]", email);
      formdata.append("profile[new_phone_number]", "91" + mobile);
      formdata.append("profile[gender]", gender);
      formdata.append("profile[profile_country_id]", "1");
      formdata.append("profile[school_name]", school);

      this.updateProfileApiCallId = apiCall({
        method: configJSON.callTypeApiValidateMobileNo,
        endPoint: configJSON.updateUserProfileApiEndpoint,
        token: this.state.userToken,
        type: "formdata",
        body: formdata
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleSubmitProfileResponse(response: UserProfileUpdateResponse) {
    if (
      response.meta &&
      response.meta.profile === configJSON.profileUpdatedSuccessfullyMessage
    ) {
      const { attributes } = response.data[0],
        {
          email,
          district_details,
          full_phone_number,
          full_name,
          photo,
          gender,
          dob,
          state_details,
          school_name
        } = attributes,
        valuesToSet: unknown = {
          email: email,
          fullName: full_name,
          photoName: photo,
          photoPreview: photo,
          dateOfBirth: dob,
          gender: `${this.getGender(gender)}`,
          districtId: district_details.id,
          stateId: state_details.id,
          mobile: full_phone_number.slice(2),
          lastName: "",
          school: school_name
        };
      this.setState(
        {
          defaultValuesForm: valuesToSet as FormValues,
          alertMsg: response.meta.profile,
          alertType: "success",
          isAlert: true,
          isEmptyMobileNumber: full_phone_number === "",
          isLoading: false
        },
        async () => {
          this.cancelEditMode();
          await setStorageData("profilePic", photo);
          await setStorageData("profileUsername", full_name);
          window.dispatchEvent(new Event("storage"));
        }
      );
    } else if (
      response.errors &&
      response.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      navigationLoginScreen(this.props, this.send);
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleInputChange({ target }: { target: { value: string; name: string } }) {
    let { name, value } = target;
    let values = String(value);
    if (values.startsWith(" ")) {
      return;
    }
    
    this.setState({ isFormChanged: true });
    let error = "";

    switch (name) {
      case "mobile":
        if (/^\d*$/.test(value) && value.length <= 10) {
          const errMsg = value.length === 0 ? configJSON.mobileNumberErrMsg : "";
          const stateValues: unknown = {
            [name]: value,
            [`${name}Error`]: errMsg,
          };
          this.setState(stateValues as Pick<S, keyof S>);
        }
        return;
    
      case "email":
        error = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
    
        case "fullName":
          if (value.length > 60) {
            error = "Fullname cannot be more than 60 characters";
            this.setState({ fullNameError: error });
            return;
          }
        
          if (!fullNameRegex.test(value)) {
            error = configJSON.fullNameErrorMsg;
            this.setState({ [name]: value, fullNameError: error });
            return;
          }
          this.setState({ [name]: value, fullNameError: "" });
          break;
    
      default:
        break;
    }
    const stateValues: unknown = { [name]: value, [`${name}Error`]: error };
    this.setState(stateValues as Pick<S, keyof S>, () => {
      this.handleGetDistrictsData(name, value)
    });
  }
  
  handleGetDistrictsData = (name: string, value: string) => {
    if (name === "stateId") {
      this.handleGetDistricts(value);
    }
  }
  handleNumberInputDisable = (isAdmin: boolean) => {
    if (this.state.isEditMode) {
      if (isAdmin) {
        return false;
      } else if (this.state.isEmptyMobileNumber) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
