import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Button,
  Modal,
  FormControl,
  TextField,
  Typography
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import ClearIcon from "@material-ui/icons/Clear";
import { addMore, deleteIcon, deleteIcone, editIcone } from "./assets";
import {
  TwoColBox,
  InputBox,
  OneColBox,
  StyledSelect,
  ProfilePicInput
} from "../../../components/src/GenericInputs";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
const drawerWidth = 250;

// Customizable Area End

import BundleCourseController, {
  configJSON,
  Props
} from "./BundleCourseController";
import MainLayout from "../../../components/src/MainLayout.web";

export default class AddBundleCourse extends BundleCourseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderAddBundleCourseForm() {
    const { bundleCourseFormData } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <OneColBox>
            <Typography className="box__label">
              Select Main Category*
            </Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <StyledSelect
                fullWidth={true}
                onChange={e => {
                  this.handleCourseInputChange({
                    ...e,
                    errorField: "categoryError"
                  });
                }}
                onClick={() => this.handleSelectClick()}
                disableUnderline
                name="category"
                value={bundleCourseFormData.category}
                data-test-id="category"
                IconComponent={IoIosArrowDown}
              >
                <option aria-label="None" value="" disabled/>
                {this.state.categoryList &&
                  this.state.categoryList.length > 0 &&
                  this.state.categoryList.map((item: any) => (
                    <option value={item.id}>{item.attributes.name}</option>
                  ))}
              </StyledSelect>
              <span style={webStyles.formErrorText}>
                {this.state.bundleCourseFormData.categoryError}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography className="box__label">Enter Bundle Name*</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="bundleName"
                id="outlined-basic"
                variant="outlined"
                name="bundleName"
                fullWidth
                value={bundleCourseFormData.bundleName}
                onChange={e => {
                  this.handleCourseInputChange({
                    ...e,
                    errorField: "bundleNameError"
                  });
                }}
              />
              <span style={webStyles.formErrorText} data-test-id="bundleNameError">
                {this.state.bundleCourseFormData.bundleNameError}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography
              className="box__label"
              style={{ alignSelf: "flex-start" }}
            >
              Description*
            </Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <MultiLineTextField
                data-test-id="description"
                id="outlined-basic"
                variant="outlined"
                name="description"
                fullWidth
                multiline
                maxRows={3}
                minRows={3}
                value={bundleCourseFormData.description}
                onChange={e => {
                  this.handleCourseInputChange({
                    ...e,
                    errorField: "descriptionError"
                  });
                }}
              />
              <span style={webStyles.formErrorText}>
                {this.state.bundleCourseFormData.descriptionError}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography
              className="box__label"
              style={{ alignSelf: "flex-start" }}
            >
              Overview*
            </Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <MultiLineTextField
                data-test-id="overview"
                id="outlined-basic"
                variant="outlined"
                name="overview"
                multiline
                fullWidth
                maxRows={3}
                minRows={3}
                value={bundleCourseFormData.overview}
                onChange={e => {
                  this.handleCourseInputChange({
                    ...e,
                    errorField: "overviewError"
                  });
                }}
              />
              <span style={webStyles.formErrorText}>
                {this.state.bundleCourseFormData.overviewError}
              </span>
            </FormControl>
          </OneColBox>
          <OneColBox>
            <Typography className="box__label">What You'll Learn</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="whatWillLearn"
                id="outlined-basic"
                variant="outlined"
                name="whatWillLearn"
                fullWidth
                value={bundleCourseFormData.whatWillLearn}
                onChange={e => {
                  this.handleCourseInputChange({
                    ...e,
                    errorField: "whatWillLearnError"
                  });
                }}
              />
              <span style={webStyles.formErrorText}>
                {this.state.bundleCourseFormData.whatWillLearnError}
              </span>
            </FormControl>
            <div style={webStyles.addMoreDeleteIcon}>
              <Button
                data-test-id="addPath"
                variant="outlined"
                style={webStyles.addMoreLearn}
                onClick={this.handleAddWhoYoullLearn}
              >
                <img src={addMore} />
                <p style={webStyles.addMoreText}>Add More</p>
              </Button>
            </div>
          </OneColBox>
          {bundleCourseFormData.additionalFields.map(
            (field: any, index: any) => (
              <OneColBox>
                <Typography className="box__label">
                  What You'll Learn
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="box__input"
                >
                  <InputBox
                    data-test-id={"additionalField"}
                    variant="outlined"
                    fullWidth
                    value={field}
                    onChange={e => this.handleAdditionalFieldChange(e, index)}
                  />
                  <span style={webStyles.formErrorText}>
                    {this.state.bundleCourseFormData.additionalFieldErrors?.[index]}
                  </span>
                </FormControl>
                <div style={webStyles.addMoreDeleteIcon}>
                  <Button
                    data-test-id="addLearnMore"
                    variant="outlined"
                    style={webStyles.addMoreLearn}
                    onClick={this.handleAddWhoYoullLearn}
                  >
                    <img src={addMore} />
                    <p style={webStyles.addMoreText}>Add More</p>
                  </Button>
                  <div
                    data-test-id="deleteLearnMore"
                    style={webStyles.deleteAddLearn}
                    onClick={() => this.handleDeleteAdditionalField(index)}
                  >
                    <img src={deleteIcon} />
                  </div>
                </div>
              </OneColBox>
            )
          )}
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Choose Tag*</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  fullWidth={true}
                  value={bundleCourseFormData.tag}
                  onClick={() => this.getTagList()}
                  onChange={e => {
                    this.handleCourseInputChange({
                      ...e,
                      errorField: "tagError"
                    });
                  }}
                  disableUnderline
                  name="tag"
                  data-test-id="tag"
                  IconComponent={IoIosArrowDown}
                >
                  {this.state.choosTagList &&
                  this.state.choosTagList.length > 0 &&
                  this.state.choosTagList.map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
                </StyledSelect>
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.tagError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">Enter Rating*</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <StyledSelect
                  fullWidth={true}
                  value={bundleCourseFormData.rating}
                  onChange={e => {
                    this.handleCourseInputChange(
                      { ...e, errorField: "ratingError" },
                      true,
                      { max: 5, min: 0 }
                    );
                  }}
                  disableUnderline
                  data-test-id="rating"
                  id="outlined-basic"
                  variant="outlined"
                  name="rating"
                  IconComponent={IoIosArrowDown}
                >
                  <option value="" disabled>{""}</option>
                  {[1,2,3,4,5].map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
                </StyledSelect>
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.ratingError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label" style={{ alignSelf: "start" }}>
              Add Course ({this.state.courseData.length})*
            </Typography>
            <Box
              className="box__input"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%"
              }}
            >
              {this.state.courseData.length > 0 &&
                this.state.courseData.map((courses: any) =>
                  this.generateNestedDivs(courses, courses.id)
                )}
              <FormControl variant="outlined" fullWidth className="box__input">
                <label
                  style={{
                    padding: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "2px dashed #ccc",
                    cursor: "pointer",
                    width: "98%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                    background: "white",
                    color: "#192252"
                  }}
                  data-test-id="addCourseModal"
                  onClick={this.handleAddCoursePopup}
                >
                  <Typography>+ ADD COURSES</Typography>
                </label>
                <span style={webStyles.formErrorText} data-test-id="selectedCourseIdError">
                  {this.state.bundleCourseFormData.selectedCourseIdError}
                </span>
              </FormControl>
            </Box>
          </OneColBox>

          <OneColBox>
            <Typography className="box__label">Upload Thumbnail*</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              {this.state.bundleCourseFormData.previewThumbnail ? (
                <ProfilePicInput
                  onChange={e => this.handleEditFileChange(e)}
                  onRemove={() => this.handleDeleteFile()}
                  data-test-id="fileChange"
                  name="subCatIconImgUpload"
                  previewThumbnail={
                    this.state.bundleCourseFormData.previewThumbnail
                  }
                />
              ) : (
                <label
                  style={{
                    padding: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "2px dashed #ccc",
                    cursor: "pointer",
                    width: "98%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                    background: "white",
                    color: "#192252"
                  }}
                >
                  <Typography>+ UPLOAD THUMBNAIL IMAGE</Typography>
                  <input
                    type="file"
                    id="fileInput"
                    data-test-id="fileChange"
                    name="instructorImgName"
                    style={{ display: "none" }}
                    onChange={e => this.handleEditFileChange(e)}
                  />
                </label>
              )}
              <span style={webStyles.formErrorText}>
                {this.state.bundleCourseFormData.fileErr}
              </span>
            </FormControl>
          </OneColBox>

          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">
                Enter 1st Year Price*
              </Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="firstYrPrize"
                  id="outlined-basic"
                  variant="outlined"
                  name="firstYrPrize"
                  fullWidth
                  value={bundleCourseFormData.firstYrPrize}
                  onChange={e => {
                    this.handleBundleCoursePrice(
                      { ...e, errorField: "firstYrPrizeError" }
                    );
                  }}
                />
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.firstYrPrizeError}
                </span>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label">
                Enter 2nd Year Price
              </Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="secondYrPrize"
                  id="outlined-basic"
                  variant="outlined"
                  name="secondYrPrize"
                  fullWidth
                  value={bundleCourseFormData.secondYrPrize}
                  onChange={e => {
                    this.handleBundleCoursePrice(
                      { ...e, errorField: "secondYrPrizeError" }
                    );
                  }}
                />
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.secondYrPrizeError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Typography className="box__label">Discounted Price*</Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="discountPrize"
                  id="outlined-basic"
                  variant="outlined"
                  name="discountPrize"
                  fullWidth
                  value={bundleCourseFormData.discountPrize}
                  onChange={e => {
                    this.handleBundleCoursePrice(
                      { ...e, errorField: "discountPrizeError" }
                    );
                  }}
                />
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.discountPrizeError}
                </span>
              </FormControl>
            </Box>
          </TwoColBox>
          
          <OneColBox>
            <Typography className="box__label">Preview Video URL*</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                data-test-id="videoPreview"
                id="preview-video-url"
                variant="outlined"
                name="videoPreview"
                fullWidth
                value={this.state.videoPreview}
                onChange={this.handlePreviewChange}
                placeholder="Enter the video URL (e.g., https://example.com/video)"
              />
              <span style={webStyles.formErrorText}>
                {this.state.videoPreviewErr}
              </span>
            </FormControl>
          </OneColBox>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
              marginBottom: "20px"
            }}
          >
            {this.state.isUpdate ? (
              <>
                <Button
                  style={webStyles.resetBtn}
                  data-test-id="cancelButtonTestId"
                  onClick={() => this.handleGoBack(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="updateButtonTestId"
                  style={webStyles.createBtn}
                  disabled={this.state.isLoading}
                  onClick={() => this.handleAddBundleCourse(true)}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={webStyles.resetBtn}
                  data-test-id="resetButtonTestId"
                  onClick={() => this.handleResetData()}
                >
                  Reset
                </Button>
                <Button
                  data-test-id="createButtonTest"
                  style={webStyles.createBtn}
                  disabled={this.state.isLoading}
                  onClick={() => this.handleAddBundleCourse(false)}
                >
                  Create
                </Button>
              </>
            )}
          </div>
        </Box>
      </div>
    );
  }

  renderAddCourseModal = () => {
    const { bundleCourseFormData } = this.state;
    const coursesList =
      this.state.courseList &&
      this.state.courseList.length > 0 ?
      this.state.courseList.map(item => (
        <option
          value={item.id}
          disabled={
            !!this.state.bundleCourseFormData.selectedCourseId.find(
              i => i === parseInt(item.id, 10)
            )
          }
        >
          {item.attributes.course_name}
        </option>
      )) : <option disabled>{configJSON.noCourseAvailable}</option>;
    return (
      <>
        <AddCourseModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalId"
          open={this.state.isAddCourseModalOpen}
          onClose={this.handleCloseDeleteModal}
        >
          <ModalComponent>
            <div style={webStyles.modalTitle}>
              <div style={webStyles.modalTitleHeadText}>Add Courses</div>
              <ClearIcon
                data-test-id="clearIcon"
                onClick={() => {
                  this.setState({ isAddCourseModalOpen: false });
                }}
              />
            </div>
            <Box
              style={{
                ...webStyles.inputField,
                borderBottom: "1px solid rgba(189, 188, 188, 0.314)"
              }}
            >
              <div style={webStyles.adminLabelText}>Select Course</div>
              <FormControl variant="outlined" fullWidth>
                <StyledSelect
                  fullWidth={true}
                  onChange={e => {
                    this.handleCourseInputChange({
                      ...e,
                      errorField: "courseError"
                    });
                  }}
                  disableUnderline
                  name="course"
                  value={bundleCourseFormData.course}
                  data-test-id="course"
                  IconComponent={IoIosArrowDown}
                >
                  <option aria-label="None" value="" disabled/>
                  {coursesList}
                </StyledSelect>
                <span style={webStyles.formErrorText}>
                  {this.state.bundleCourseFormData.courseError}
                </span>
              </FormControl>
            </Box>
            {bundleCourseFormData.additionalCourses.map(
              (course: any, index: number) => (
                <Box
                  key={index}
                  style={{
                    ...webStyles.inputField,
                    borderBottom: "1px solid rgba(189, 188, 188, 0.314)"
                  }}
                >
                  <div style={webStyles.adminLabelText}>
                    Select Additional Course
                  </div>
                  <FormControl variant="outlined" fullWidth>
                    <StyledSelect
                      fullWidth={true}
                      disableUnderline
                      onChange={e => {
                        this.handleAdditionalCourseChange(e, index + 1);
                      }}
                      name={`course-${index + 1}`}
                      value={
                        this.state.bundleCourseFormData.selectedCourseId[
                          index + 1
                        ]
                      }
                      data-test-id={"AdditionalCourses"}
                      IconComponent={IoIosArrowDown}
                    >
                      <option aria-label="None" value="" />
                      {coursesList}
                    </StyledSelect>
                  </FormControl>
                </Box>
              )
            )}
            <div>
              <Button
                data-test-id="addPath"
                style={{ ...webStyles.addMoreLearn, float: "right" }}
                variant="outlined"
                onClick={this.handleAddMoreCourse}
              >
                <img src={addMore} />
                <p style={webStyles.addMoreText}>Add More</p>
              </Button>
            </div>
            <div style={webStyles.resetAddMain}>
              <Button
                data-test-id="addPath"
                variant="outlined"
                style={webStyles.addMoreReset}
                onClick={this.handleResetAdditionalCourses}
              >
                <p style={webStyles.addMoreText}>Reset</p>
              </Button>
              <Button
                data-test-id="addCourse"
                style={webStyles.addCourseTextModalBtn}
                onClick={() => this.handleAddCourse()}
              >
                <p style={webStyles.addCourseTextModal}>Add Course</p>
              </Button>
            </div>
          </ModalComponent>
        </AddCourseModal>
      </>
    );
  };
  generateNestedDivs(course: any, id: any) {
    return (
      <div style={webStyles.main}>
        <div>
          <img
            style={webStyles.left}
            src={course.course_thumbnail}
            width={151}
            height={89}
          />
        </div>
        <div style={webStyles.rightMain}>
          <div style={webStyles.right1}>{course.course_name}</div>
          <div style={webStyles.right2}>{course.short_description}</div>
          <div style={webStyles.right3}>
            <div style={webStyles.right3Left}>₹{course.first_year_price}</div>
            <div style={webStyles.right3Right}>
              <Button
                style={{
                  borderRadius: "3.977px",
                  border: "0.795px solid rgb(31, 101, 117)",
                  background: "#EEEEEE"
                }}
              >
                <img src={editIcone} />
              </Button>
              <Button
                onClick={() => this.deleteAddedCourse(id)}
                style={{
                  borderRadius: "3.977px",
                  border: "0.795px solid rgb(31, 101, 117)",
                  background: "#EEEEEE"
                }}
              >
                <img src={deleteIcone} data-test-id="deleteTheCourse" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        newUI
        isLoading={this.state.isLoading}
        data-test-id="mainLayoutEvent"
        backData={() => this.handleGoBack(false)}
        title={"Bundle Course"}
        isEdit
        isAdd
      >
        <NotificationAlertMessage
          dataTestId="notificationModalTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        {this.renderAddCourseModal()}
        <main className="table_content" style={{ background: "white" }}>
          {this.renderAddBundleCourseForm()}
        </main>
      </MainLayout>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MultiLineTextField = styled(TextField)({
  "& .MuiInputBase-input": { height: "86px" },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": { borderColor: "#3F6474" }
  }
});

const AddCourseModal = styled(Modal)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ModalComponent = styled(Box)({
  width: "651px",
  padding: "20px",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  outline: "none",
  margin: "20px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 0px 3px px transparent",
  maxHeight: "70vh",
  overflowY: "auto"
});

export const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  resetBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: "15px 0",
    fontSize: "0.875rem",
    fontWeight: 600,
    marginTop: "20px",
    backgroundColor: "#fcfcfc",
    border: "1px solid #81999f",
    color: "#81999f",
    display: "flex",
    justifyContent: "center"
  },
  createBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: " 15px 0",
    border: "none",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: " #206575",
    marginTop: " 20px",
    display: "flex",
    justifyContent: "center"
  },
  main: {
    display: "flex",
    columnGap: "15px",
    borderRadius: "5px",
    border: "1px solid #9F9F9F",
    background: "#FFF",
    padding: "10px"
  },
  left: {
    borderRadius: "10px"
  },
  rightMain: {
    width: "100%"
  },
  right1: {
    color: "#000",
    fontSize: "0.938rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: " -0.022px",
    marginBottom: "2px"
  },
  right2: {
    color: "#8B8B8B",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px",
    marginBottom: "21px"
  },
  right3: {
    display: "flex",
    justifyContent: "space-between"
  },
  right3Left: {
    color: "#000",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px"
  },
  right3Right: {
    display: "flex",
    columnGap: "5px"
  },
  deleteAddLearn: {
    width: "44px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid #FF5C00",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  resetAddMain: {
    display: "flex",
    justifyContent: "center",
    columnGap: "15px",
    marginBottom: "20px"
  },
  categoryImgName: {
    display: "flex",
    justifyContent: "center"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  modalTitleHeadText: {
    color: "var(--Basic-Black, #000)",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px"
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  bundleCourseText: {
    display: "flex",
    marginLeft: "40px",
    fontSize: "1.438rem",
    color: "black"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  addMoreText: {
    color: "#1F6575",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17.5px"
  },
  tableDivide: {
    height: "10px"
  },
  addMoreDeleteIcon: { display: "flex", columnGap: "10px" },
  addMoreLearn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "130px",
    cursor: "default",
    fontSize: "0.875rem",
    columnGap: "10px",
    borderRadius: "3.977px",
    borderColor: "#1F6575",
    height: "40px"
  },
  addMoreReset: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "108px",
    borderColor: "#1F6575",
    background: "var(--Basic-White, #FFF)"
  },
  addCourseTextModalBtn: {
    width: "180px",
    background: "#1F6575"
  },
  addCourseTextModal: {
    color: "var(--Basic-White, #FFF)",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fullNameText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  imgNameMain: { display: "flex", marginLeft: "15px", columnGap: "10px" },
  editDeleteIcon: { background: "none", border: "none", cursor: "pointer" },
  tableActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  bundleCourseMainLayout: { width: "97%", margin: "0 auto", maxWidth: "960px" },
  adminLayout: { display: "flex", flexDirection: "column", gap: "2rem" },
  adminLabelText: {
    color: "#192252",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center"
  },
  formErrorText: { fontSize: "12px", color: "red" },
  inputField: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    marginBottom: "15px",
    paddingBottom: "20px"
  },
  fileLabel: {
    padding: "10px",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    width: "98%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    background: "white"
  }
};

// Customizable Area End
