import React from 'react';
import { Grid, withStyles ,Box} from '@material-ui/core';
import { WithStyles, createStyles } from '@material-ui/core/styles';
import { termsandconditionImg } from '../../blocks/TermsAndConditions/src/assets';

const styles = createStyles({
  headerContainerMain: {
    maxWidth: 1440, 
    padding: '55px',
    margin: '0px auto',
    background: "initial", 
    overflow: 'initial',
    height: 234, 
    zIndex: 7,
    '@media (min-width: 1441px) and (max-width: 10080px)': {
      padding: '50px 0px',
    }
  },
  headingTitle: {
    fontFamily: 'Inter',
    fontSize: "43.63px",
    fontWeight: 700,
    color: "#FFFFFF",
    margin: "0px"
},
headerSubTitle: {
    fontFamily: 'Inter',
    color: "#FFFFFF",
    fontSize: '26.26px',
    fontWeight: 400 as const,
    lineHeight: '31.79px',
    letterSpacing: '0.10943496972322464px',
    textAlign: 'left' as const
},
contactUsButtonItem: {
  width: 119,
  height: 37,
  borderRadius: 100,
  background: "#FCB910",
  color: "#fff",
  fontFamily: 'Inter',
  fontSize: 16,
  marginLeft: 16,
  marginBottom: 8
}
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  subTitle: string;
  img?:string;
}

class HelpCenterHeader extends React.Component<Props> {
  render() {
    const { classes,title ,subTitle, img} = this.props;

    return (
      <Box style={{ background: "linear-gradient(to right, #3C7481, #154955)"}}>
      <Grid className={`header ${classes.headerContainerMain}`} container>
        <Grid container item xs={12} md={7} className="headerLeft"
        style={{
          display: "flex",
          flexDirection: "column"
        }}>
          <h1 className={classes.headingTitle}>{title}
          </h1>
          <p className={classes.headerSubTitle}>
            {subTitle}
          </p>
        </Grid>
        <Grid item xs={12} md={5} lg={6} className="image">
          <img
            src={img ? img : termsandconditionImg}
            alt="student-on-a-desk-illustration"
            className="img headerImage"
            style={{
              width: '283.26px',
              height: '234px',
              marginBottom: img ? -10 : -25
            }}
          />
        </Grid>
      </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(HelpCenterHeader);
