import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  CardContent,
  Chip,
  styled,
  Divider,
} from '@material-ui/core';
import { FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CourseOrder } from '../../blocks/catalogue/src/MyCourseController.web';

const styles = {
  root: {
    height: 324,
    boxShadow: 'unset',
    borderRadius: 12,
  },
  media: {
    height: 140,
    borderRadius: 12,
  },
};

interface CourseCardProps extends WithStyles<typeof styles> {
  item: CourseOrder;
  index: number;
}

export class CourseCard extends React.Component<CourseCardProps> {
  getRemainingDays(initialDate: Date, year: number): number {
    const today = new Date();

    const oneYearLater = new Date(initialDate);
    oneYearLater.setFullYear(initialDate.getFullYear() + year);

    const timeDifference = oneYearLater.getTime() - today.getTime();

    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return remainingDays > 0 ? remainingDays : 0;
  }

  render() {
    const { classes, item } = this.props;

    return (
      <Card className={classes.root}>
        <Link
          to={`/MyCoursesDetail/${item.order_id}/${item.course_id}`}
          data-test-id="linkTestId"
          style={{ margin: 0, textDecoration: 'none', color: 'inherit' }}
        >
          <CardActionArea
            style={{
              position: 'relative',
              borderRadius: 12,
            }}
          >
            <CardMedia
              className={classes.media}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '173px',
                minHeight: '173px',
                aspectRatio: '16/9',
                backgroundSize: 'contain',
                backgroundPosition: 'center'
              }}
              image={item.course_data.data.attributes.course_thumbnail}
              title="Thumbnail image"
            />
            <FaPlayCircle
              fontSize="3rem"
              color="white"
              style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                top: '86.5px',
                left: '50%',
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)'
              }}
            />
            <CustomChipImage
              label={`${this.getRemainingDays(
                new Date(item.place_order_date),
                item.no_of_year
              )} Days`}
            />
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                data-test-id="courseNameTestId"
                style={{ margin: 0, height: 64, fontWeight: 'bold' }}
              >
                {item.course_data.data.attributes.course_name}
              </Typography>
              <CustomProgress
                variant="determinate"
                value={(item.total_watched_chapter / item.total_course_chapter) * 100}
              />
              <div
                style={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="body2"
                  component="h5"
                  style={{ color: '#FF5900', fontWeight: 'bold' }}
                >
                  {item.total_watched_chapter} of {item.total_course_chapter}{' '}
                  Chapters
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography
                  variant="body2"
                  style={{ display: 'inline-flex', gap: 3 }}
                >
                  {item.start_button ? (
                    <CustomChip label="Start Course" />
                  ) : (
                    <span style={{ color: '#3B7A89' }}>Write a review</span>
                  )}
                  <CustomChip label="Purchased" />
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    );
  }
}

const CustomProgress = styled(LinearProgress)(() => ({
  borderRadius: 20,
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#EEEEEE'
  },
  '& > div': {
    backgroundColor: '#FE8155'
  },
  '&.MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#FE8155'
  },
}));

const CustomChip = styled(Chip)(() => ({
  fontSize: 10,
  background: '#FF5900',
  color: 'white',
  height: 20,
  fontWeight: 'bold',
  fontFamily: 'Inter',
  borderRadius: 4,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

const CustomChipImage = styled(Chip)(() => ({
  background: '#36AF0C',
  fontWeight: 'bold',
  color: 'white',
  height: 20,
  position: 'absolute',
  fontFamily: 'Inter',
  right: 10,
  top: 140,
  '& .MuiChip-label': {
    paddingLeft: 6,
    paddingRight: 6,
  },
}));

export default withStyles(styles)(CourseCard);
