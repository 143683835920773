import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

// Customizable Area Start
import { parseErrors } from "../../couponcodegenerator/src/FlatDiscountController.web";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}
export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}

export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
        | (object & {
            attributes?: object & {
              name?: string;
            };
          })
        | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

export interface ShoppingCartData {
  id: string;
  attributes: {
    courseable_id: string;
    price: number;
    no_of_year: number;
    courses_shopping_carts: Array<{
      data: {
        attributes: {
          course_thumbnail?: string;
          bundle_course_thumbnail?: string;
          course_name?: string;
          bundle_name?: string;
          course_child_category?: {
            color: string;
            name: string;
          };
          course_category?: {
            name: string;
          };
          average_rating?: {
            Average_rating: number;
            total_review_count: number;
          };
          rating?: number;
          student_count?: number;
          total_student?: number;
        };
        type: string;
      };
    }>;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  order_id: number;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: ShoppingCartOrder[];
  orderItems: ShoppingCartOrderItem[];
  isVisible: boolean;
  isRefreshing: boolean;

  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  isLoading: boolean;
  userToken: string;
  shoppingCartData: ShoppingCartData[];
  shopping_cart_id: string;
  subtotal: number;
  total: number;
  discount: number;
  coupon: string;
  couponError: string;
  appliedCoupon: boolean;
  courseValidityYear: number;
  alertType: Color;
  alertMsg: string;
  isAlert: boolean;
  emptyCartMsg: string;
  anchorEl: boolean;
  selectDiscountType: string;
  discountPlaceHolder: string;
  preDiscountType: string;
  discount_type: string;
  coupon_code_id: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId?: string;
  showOrderApiCallId?: string;
  createOrderItemApiCallId?: string;
  deleteOrderItemApiCallId?: string;
  getShoppingCartItemsApiCallId: string = "";
  applyCouponCodeApiCallId: string = "";
  removeCouponCodeApiCallId: string = "";
  deleteCourseCartItemApiCallId: string = "";
  updatePriceBasedOnYearValidity: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      isLoading: false,
      userToken: "",
      shoppingCartData: [],
      shopping_cart_id: '',
      subtotal: 0,
      total: 0,
      discount: 0,
      coupon: "",
      couponError: "",
      appliedCoupon: false,
      courseValidityYear: 1,
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      emptyCartMsg: '',
      anchorEl: false,
      selectDiscountType: "I’VE A COUPON",
      discountPlaceHolder: "Coupon",
      preDiscountType: "",
      discount_type: "",
      coupon_code_id: 0
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    const coupon = await getStorageData("coupon_code");
    const discount = await getStorageData("discount_type",true);
    if (discount) {
      this.setState({
        discountPlaceHolder: discount?.discountPlaceHolder, 
      selectDiscountType: discount?.selectDiscountType,
      preDiscountType: discount?.discountPlaceHolder
      })
    }
    this.setState({ 
      userToken: token ,
      coupon: coupon || ""
    }, () => {
      this.getShoppingCartData();
    });

    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getOrders(token);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.errors) {
      switch (apiRequestCallId) {
        case this.getShoppingCartItemsApiCallId:
          if(responseJson.message === "Cart is empty") {
            await removeStorageData("coupon_code");
            await removeStorageData("discount_type");
            this.setState({
              appliedCoupon: false,
              coupon: "",
              shoppingCartData: [],
              emptyCartMsg: responseJson.message,
              subtotal: 0,
              total: 0,
              discount: 0.00,
              isLoading: false
            })
          await this.getCartCount(0)
          } else{
            this.setState({
              shoppingCartData: responseJson.shopping_cart_data.data,
              shopping_cart_id: responseJson.shopping_cart_data.data[0].attributes.shopping_cart_id,
              subtotal: responseJson.subtotal,
              total: responseJson.total,
              discount: responseJson.discount,
              isLoading: false,
              appliedCoupon: responseJson.discount !== 0
            });
            if (responseJson.coupon_code !== "null" && responseJson.coupon_code?.coupon_code) {
              this.setState({
              coupon: responseJson.coupon_code?.coupon_code,
              coupon_code_id: responseJson.coupon_code.id
              })
            }
            await this.getCartCount(responseJson.shopping_cart_data.data.length)
          }
          break;
          case this.applyCouponCodeApiCallId:
            
            const successMessages = [
              "coupon code applied successfully",
              "Voucher Code applied successfully",
              "Flat Discount Code applied successfully",
              "Reedem Code applied successfully"
            ];
            
            if (successMessages.includes(responseJson.message)) {
             setStorageData("coupon_code", this.state.coupon);
             setStorageData("discount_type", JSON.stringify({discountPlaceHolder:this.state.discountPlaceHolder,selectDiscountType: this.state.selectDiscountType}));
              this.setState({
                preDiscountType: this.state.discountPlaceHolder,
                appliedCoupon: true,
                discount_type: responseJson.discount_type || responseJson.data?.discount_type,
                isLoading: false
              });
              this.getShoppingCartData(this.state.discountPlaceHolder);
            } else {
              this.setState({
                couponError: responseJson.message || responseJson.error,
                isLoading: false
              });
            }
            break;
        case this.removeCouponCodeApiCallId: 
          this.getShoppingCartData();
        break;
        case this.deleteCourseCartItemApiCallId:
          this.getShoppingCartData()
            this.setState({ 
              alertMsg: "Cart Item Delete Successfully",
              alertType: 'success',
              preDiscountType: "",
              discount_type: "",
              isAlert: true,
            })
          break;
        case this.updatePriceBasedOnYearValidity:
          this.setState({
            preDiscountType: "",
            discount_type: ""
          },() => this.getShoppingCartData());
          break;
      }
    } else {
      parseErrors(
        responseJson,
        this.parseApiErrorResponse,
        this.send,
        this.props
      );
    }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getOrdersApiCallId != null &&
      this.getOrdersApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ isRefreshing: false });

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ orderList: responseJson?.data });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ orderList: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showOrderApiCallId != null &&
      this.showOrderApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ isRefreshing: false });

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          orderItems: responseJson?.data?.attributes?.order_items?.data,
        });
      } else {
        this.setState({ orderItems: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createOrderItemApiCallId != null &&
      this.createOrderItemApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.props.navigation.navigate("ShoppingCartOrders");
      } else if (responseJson.errors) {
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteOrderItemApiCallId != null &&
      this.deleteOrderItemApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.showOrder(this.state.order_id);
        this.getOrders(this.state.token);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  isNumberNull(number_value?: number) {
    return (
      number_value === null ||
      Number.isNaN(number_value) ||
      number_value === undefined
    );
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
  };

  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };

  setModal = (item: {
    post: {
      id: number;
      name: string;
      description: string;
      price: number;
      currency: string;
      category_id: number;
      created_at: string;
      updated_at: string;
      account_id: number;
    };
  }) => {
    this.setState({
      id: item.post?.id,
      name: item.post?.name,
      description: item.post?.description,
      price: item.post?.price,
      currency: item.post?.currency,
      category_id: item.post?.category_id,
      created_at: item.post.created_at,
      updated_at: item.post?.updated_at,
      account_id: item.post?.account_id,
      isVisible: !this.state.isVisible,
    });
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOrder = (order_id: S["order_id"]) => {
    this.setState({ isVisible: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint + "/" + `${order_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = (token: string) => {
    if (
      this.isNumberNull(this.state.catalogue_id) ||
      this.isNumberNull(this.state.quantity) ||
      this.isNumberNull(this.state.taxable_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
      taxable: this.state.taxable,
      taxable_value: this.state.taxable_value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  oncloseAlert =() => {
    this.setState({
      isAlert: false,
    });
  }

  deleteOrderItem = (
    order_id: string | number,
    order_item_id: string | number
  ) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteOrderItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteOrderItemApiEndPoint +
        "/" +
        `${order_id}` +
        "/order_items/" +
        `${order_item_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setCatalogueId = (catalogue_id?: string) => {
    this.setState({ catalogue_id: Number(catalogue_id) || 0 });
  };

  setQuantity = (quantity?: string) => {
    this.setState({ quantity: Number(quantity) || 0 });
  };

  setTaxable = (taxable?: boolean) => {
    this.setState({ taxable: taxable || false });
  };

  setTaxableValue = (taxable_value?: string) => {
    this.setState({ taxable_value: Number(taxable_value) || 0 });
  };

  setVisible = (isVisible: boolean) => {
    this.setState({ isVisible: isVisible });
  };

  addOrderItem = () => {
    this.setState({ isVisible: false });
    this.navigateToAddShoppingCartOrderItem();
  };

  showOrderItems = (order_id: string | number) => {
    const order_id_num = Number(order_id) || 0;
    this.setState({ order_id: order_id_num });
    this.showOrder(order_id_num);
  };

  handleContinueShopping = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  truncateText = (text: string = "", length: number = 12) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  buildQueryParams(httpBody: any): string {
    return Object.keys(httpBody)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(httpBody[key])}`)
      .join('&');
  }
  

  getShoppingCartData(discount_type: string = "") {
    let httpBody: any = {};
    switch (discount_type) {
      case 'Voucher':
        httpBody.voucher_code = this.state.coupon;
        break;
      case 'Points':
        httpBody.redeem_code = this.state.coupon;
        break;
      case 'Flat Discount':
        httpBody.discount_code = this.state.coupon;
        break;
      case 'Coupon':
        httpBody.coupon_code_id = this.state.coupon
      default:
        break;
    }

    const baseUrl = configJSON.getShoppingCartApiEndPoint;
    const queryParams = this.buildQueryParams(httpBody);
    const apiUrl = `${baseUrl}?${queryParams}`;
    this.setState({ isLoading: true });
    this.getShoppingCartItemsApiCallId = this.apiCall({
      method: configJSON.getApiMethod,
      endPoint: apiUrl,
      token: this.state.userToken
    });
  }

  handleCouponValue = (event:  React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ coupon: event.target.value}, () => {
      this.setState({couponError: ""})
    })
  }
  applyCouponCode = () => {
    if(this.state.coupon.trim() === "") {
      this.setState({ couponError: "Please Enter Coupon Code"})
      return
    }
    this.setState({ isLoading: true });
    let httpBody = {};
    if(this.state.discountPlaceHolder === "Points") {
      httpBody = { redeem_code: this.state.coupon }   
    }else{
      httpBody = { code: this.state.coupon }   
    }

     let endPoint = configJSON.applyCouponApiEndPoint;
     switch(this.state.discountPlaceHolder) {
      case "Voucher":
       endPoint = configJSON.applyVoucherCodeApiEndPoint
       break;
      case "Flat Discount":
        endPoint = configJSON.applyFlatDiscountApiEndPoint
      break;
      case "Points":
        endPoint = configJSON.applyRefferalPointApiEndPoint;
        break;
      default:
        endPoint = configJSON.applyCouponApiEndPoint;
     }
    this.applyCouponCodeApiCallId = this.apiCall({
      method: configJSON.postApiMethod,
      endPoint,
      token: this.state.userToken,
      body: httpBody,
      contentType: configJSON.apiContentType
    });
  }

  deleteCourseCartItem = (id: string) => {
    this.setState({ isLoading: true});
    const httpBody = {
      "course_id" : id
    }
    this.deleteCourseCartItemApiCallId = this.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: configJSON.deleteCartItemApiEndPoint,
      token: this.state.userToken,
      body: httpBody,
      contentType: configJSON.apiContentType
    });
  }
  removeCouponCode = () => {
    const { preDiscountType } = this.state;
    let endPoint = configJSON.removeCouponApiEndPoint;
    
     switch(preDiscountType) {
      case "Voucher":
       endPoint = configJSON.removeVoucherCodeApiEndPoint
       break;
      case "Flat Discount":
        endPoint = configJSON.removeFlatDiscountApiEndPoint
      break;
      default:
        endPoint = configJSON.removeCouponApiEndPoint;
     }
    this.setState({isLoading: true});
    
    this.removeCouponCodeApiCallId = this.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint,
      token: this.state.userToken
    })
    this.setState({coupon:""})
  }

  handleChangeYearValidity = (course_id: string,no_of_year: number,index:number ) => {
    this.setState({isLoading: true});
    let clone = [...this.state["shoppingCartData"]];
    clone[index].attributes.no_of_year = no_of_year;

    const stateToSet: unknown = { ["shoppingCartData"]: clone };

    this.setState(stateToSet as Pick<S, keyof S>);
    this.updatePriceBasedOnYearValidity = this.apiCall({
      method: configJSON.putApiMethod,
      endPoint: configJSON.updatePriceBaseOnYear + `?course_id=${course_id}&no_of_year=${no_of_year}`,
      token: this.state.userToken,
    })
  }
  
  getNavigationMessage = (payload: string,courseType:string) => {
    if(courseType=="bundle_course"){
      this.props.navigation.navigate('BundleCourseDetails',{ courseId: payload });
    }
    else
    this.props.navigation.navigate('CourseDetails',{ courseId: payload });
  }
  handleDeleteClick = (event:any) => {
    this.setState({ anchorEl: !this.state.anchorEl });
  };

  handleCancelCoupon = () => {
    this.setState({ coupon: "", couponError: ""});
    removeStorageData("coupon_code")
  }
  handleSelectDiscountType = (value: string, type: string) => {
    this.setState({appliedCoupon: false, coupon: "", selectDiscountType: value ,discountPlaceHolder: type, anchorEl: !this.state.anchorEl, couponError: "" }, () => {
      this.removeCouponCode()
    });
  };
  getCartCount = async (count: number) => {
    try {
      if(count === 0) {
       await removeStorageData("cartCount");
      }else{
        await setStorageData('cartCount', count); 
      }
      const event = new CustomEvent('cartCountChange', { detail: { count: count } });
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error updating cart count:', error);
    }
  };
  // Customizable Area End
}
