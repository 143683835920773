import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import firebase from 'firebase/app';
import 'firebase/auth';
import { removeStorageData, setStorageData } from "framework/src/Utilities";

interface ProfileResponse {
  data: {
    attributes: {
      photo: string,
      full_name: string,
      full_phone_number: string;
    }
  },
  meta: {
    total_cart_items: number;
    message: string;
    wishlists_items: number;
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleNext: any;
  token: any;
  userEmail: string;
  useMobile: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  open: boolean;
  phone: string;
  haveEmailAccout: boolean;
  emailTologIn: string;
  emailValidationError: string;
  showEmailOtpScreen: boolean;
  showLoginSuccess: boolean;
  otp: string[];
  emailOtp: string[];
  otpError: string;
  usingMobileLogin: boolean;
  mobileValidationError: string;
  mobileToLogIn: string;
  showMobileOptScreen: boolean;
  seconds: number;
  loginWithMobileToken: string;
  showLoginWithMobileSuccess: boolean;
  loginWithEmailToken: string;
  loginVerifyedToken: string;
  loginResendToken: string;
  loginResendVerifyedToken: string;
  loginEmailResendVerifyedToken: string;
  resendOtpToken: string;
  role: string;
  test: boolean;
  usingEmailLogin: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  timer: any = null;
  createLoginWithMobile: string = "";
  loginWithMobileOTPVarification: string = "";
  createloginwithEmailId: string = "";
  loginWithEmailOTPVarificationid: string = "";
  loginWithGoodleAccountId: string = "";
  LoginWithMobileResendApiID: string = "";
  LoginWithMobileResendOtpverifyApiID: string = '';
  LoginWithEmailResendApiID: string = "";
  LoginWithEmailResendOtpverifyApiID: string = "";
  profileIconApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.haveAnAccountclick = this.haveAnAccountclick.bind(this);
    this.handleLoginWithEmailId = this.handleLoginWithEmailId.bind(this);
    this.handleEmailOTPForward = this.handleEmailOTPForward.bind(this);
    this.handleMobileOtpForward = this.handleMobileOtpForward.bind(this);
    this.handleLoginSuccessScreen = this.handleLoginSuccessScreen.bind(this);
    this.handleOTPChangeagain = this.handleOTPChangeagain.bind(this);
    this.handleKeydownagain = this.handleKeydownagain.bind(this);
    this.handlePhoneValue = this.handlePhoneValue.bind(this);
    this.handleLoginWithMobileAPI = this.handleLoginWithMobileAPI.bind(this);
    this.handleLoginWithMobileOtpVerifyAPI = this.handleLoginWithMobileOtpVerifyAPI.bind(this);
    this.handleLoginMovileOtpVarification = this.handleLoginMovileOtpVarification.bind(this);
    this.handleLoginWithEmailAPI = this.handleLoginWithEmailAPI.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleLoginWithMobileResponse = this.handleLoginWithMobileResponse.bind(this);
    this.handleMobileOtpVerificationResponse = this.handleMobileOtpVerificationResponse.bind(this);
    this.handleEmailOtpVerificationResponse = this.handleEmailOtpVerificationResponse.bind(this);
    this.handleLoginWithEmailResponse = this.handleLoginWithEmailResponse.bind(this);
    this.handleGoogleAuthLogin = this.handleGoogleAuthLogin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLoginWithMobileResendAPI = this.handleLoginWithMobileResendAPI.bind(this);
    this.LoginWithMobileResendApiIDResponse = this.LoginWithMobileResendApiIDResponse.bind(this);
    this.handleLoginWithMobileResendverifyAPI = this.handleLoginWithMobileResendverifyAPI.bind(this);
    this.handleMobileResendOtpVerificationResponse = this.handleMobileResendOtpVerificationResponse.bind(this);
    this.handleLoginOtpValidate = this.handleLoginOtpValidate.bind(this);
    this.handleLoginWithEmailResendAPI = this.handleLoginWithEmailResendAPI.bind(this);
    this.LoginWithEmailResendApiIDResponse = this.LoginWithEmailResendApiIDResponse.bind(this);
    this.handleLoginWithEmailResendverifyAPI = this.handleLoginWithEmailResendverifyAPI.bind(this);
    this.handleLoginWithEmailOtpValidate = this.handleLoginWithEmailOtpValidate.bind(this);
    this.handleEmailResendOtpVerificationResponse = this.handleEmailResendOtpVerificationResponse.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      open: true,
      phone: '',
      haveEmailAccout: false,
      emailTologIn: '',
      emailValidationError: '',
      showEmailOtpScreen: false,
      showLoginSuccess: false,
      otp: ["", "", "", ""],
      emailOtp: ["", "", "", ""],
      otpError: '',
      usingMobileLogin: false,
      usingEmailLogin: false,
      mobileValidationError: '',
      mobileToLogIn: '',
      seconds: 60,
      showMobileOptScreen: false,
      loginWithMobileToken: '',
      showLoginWithMobileSuccess: false,
      loginWithEmailToken: '',
      loginVerifyedToken: '',
      loginResendToken: '',
      loginResendVerifyedToken: '',
      loginEmailResendVerifyedToken: '',
      resendOtpToken: '',
      role: "",
      test: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    await removeStorageData('cartCount');
    localStorage.clear()
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.state.role?.length > 0) {
      if (this.state.role === "Super Admin") {
        this.props.navigation.navigate("Dashboard")
      }
      else {
        this.props.navigation.navigate("LandingPage")
      }
    } else {
      this.props.navigation.navigate("LandingPage")
    }
  };

  handlePhone = (event: any) => {
    this.setState({
      phone: event,
    });
  };


  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  haveAnAccountclick() {
    this.setState({ haveEmailAccout: true, emailValidationError: "" })
  }

  havePhoneNumberClick() {
    this.setState({ haveEmailAccout: false });
  }

  handleLoginWithEmailId(e: any) {
    const inputEmail = e.target?.value.trim();
    if (!inputEmail) {
      this.setState({ emailTologIn: "", usingEmailLogin: false });
      window.localStorage.removeItem('loginEmail');
    } else {
      this.setState({ emailTologIn: inputEmail, usingEmailLogin: true });
      window.localStorage.setItem('loginEmail', inputEmail)
      if (!inputEmail.match(configJSON.emailcheckRegex)) {
        this.setState({ emailValidationError: 'Invalid email address'});
      } else {
        this.setState({ emailValidationError: '' });
      }
    }
  }

  handleEmailOTPForward() {
    if (!this.state.emailValidationError && this.state.emailTologIn) {
      this.handleLoginWithEmailAPI(this.state.emailTologIn)
      this.otpTimer()
    }
  }
  handleLoginSuccessScreen(a: any) {
    //write otp varification logic here
    let otpValue = this.state.emailOtp && this.state.emailOtp.join('')

    if (otpValue) {
      this.handleLoginWithEmailOtpVerifyAPI(a)
    }

  }

  handleKeydownagain = (e: any, ind: any) => {
    const inputs = document.querySelectorAll('input[type="number"]');
    if (e.target?.value.length === 0 && e.key === "Backspace") {
      const nextInput = inputs[ind - 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };


  handleOTPChangeagain = (e: any, index: number) => {
    this.setState({ otpError: "" });
    const otp = [...this.state.otp];
    otp[index] = e.target?.value;
    this.setState({ otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target?.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  handleEmailOTPChange = (e: any, index: number) => {
    this.setState({ otpError: "" });
    const otp = [...this.state.emailOtp];
    otp[index] = e.target?.value;
    this.setState({ emailOtp: otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target?.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };




  handlePhoneValue(data: any) {
    if (data) {
      this.setState({ usingMobileLogin: true })
      this.setState({ phone: data })
    }
  }

  handleInputChange = (e: any) => {
    const value = e.target?.value;
    if (!value) {
      this.setState({ usingMobileLogin: false, phone: "" ,mobileValidationError: ""});
      window.localStorage.removeItem('mobileToResend');
    } else if (/^\d*$/.test(value) && value.length <= 10) {
      this.setState({ usingMobileLogin: true })
      this.setState({ phone: value , mobileValidationError: "" });
      window.localStorage.setItem('mobileToResend', value)
    }
  };

  handleMobileOtpForward() {
    if (this.state.phone) {
      this.handleLoginWithMobileAPI(this.state.phone)
      this.otpTimer()
    }
  }

  handleGoogleAuthLogin(token: string, email: string ,full_name: string = "" ,full_phone_number: string = "",profile_icon:string = "") {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      attributes: {
        email: email,
        unique_auth_id: token,
        full_name,
        full_phone_number,
        profile_icon
      }
    };
    
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginWithGoodleAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWithGoodleAccountIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithGoodleAccountIdMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleLoginWithMobileAPI(num: any) {

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const data = {
      attributes: {
        full_phone_number: '91' + num
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createLoginWithMobile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWithMobileEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLoginWithMobileOtpVerifyAPI(token2: any) {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": token2
    };

    let otpValue = this.state.otp && this.state.otp.join('')

    const data = {
      attributes: {
        otp: otpValue,
        device_id: window.navigator.userAgent
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginWithMobileOTPVarification = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginOtpVarificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleLoginMovileOtpVarification(a: any) {
    let otpValue = this.state.otp && this.state.otp.join('')

    if (otpValue) {
      this.handleLoginWithMobileOtpVerifyAPI(a)
    }
  }




  handleLoginWithEmailAPI(email: any) {

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const data = {
      type: "email_account",
      attributes: {
        email: email
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createloginwithEmailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWithemailEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  handleLoginWithEmailOtpVerifyAPI(token2: any) {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": token2
    };

    let otpValue = this.state.emailOtp && this.state.emailOtp.join('')

    const data = {
      attributes: {
        otp: otpValue,
        device_id: window.navigator.userAgent
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginWithEmailOTPVarificationid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWithEmailOtpvarification
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponse(responseJson: any) {
    if (responseJson?.meta?.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }
  }

  handleLoginWithMobileResponse(responseJson: any) {
    if (responseJson?.meta?.token) {
      this.setState({ loginWithMobileToken: responseJson.meta.token });

      window.localStorage.setItem('otpToken', responseJson.meta.token)
      this.setState({ showMobileOptScreen: true })
    } else {
      this.setState({ showMobileOptScreen: false })
      this.setState({mobileValidationError: responseJson.errors[0]?.email })
    }
  }
  handleMobileOtpVerificationResponse(responseJson: any) {
    if (responseJson.meta === 'Mobile Otp verified') {
      this.setState({ showLoginWithMobileSuccess: true });
      if (responseJson.data) {
        this.setState({ loginVerifyedToken: responseJson.data[0]?.token, role: responseJson.data[0]?.role })
        setStorageData("loginToken", responseJson.data[0]?.token)
        setStorageData("userRole", responseJson.data[0]?.role);
        this.getUserProfile(responseJson.data[0]?.token);

      }
    } else {
      window.alert('Enter correct otp');
    }
  }

  handleLoginWithEmailResponse(responseJson: any) {
    if (responseJson?.meta?.token) {
      this.setState({ loginWithEmailToken: responseJson.meta.token });
      this.setState({ showEmailOtpScreen: true });
      window.localStorage.setItem('emailOTPToken', responseJson.meta.token)
    } else if(responseJson.errors[0].message === "Please use Google login to access your account.") {
      this.setState({emailValidationError: configJSON.errorsMsgForManualLogin })
    } else {
      this.setState({emailValidationError: responseJson.errors[0]?.email })
    }
  }

  handleEmailOtpVerificationResponse(responseJson: any) {
    if (responseJson.meta === 'Email  Otp verified') {
      this.setState({ haveEmailAccout: false, showEmailOtpScreen: true, showLoginSuccess: true, });
      if (responseJson.data) {
        this.setState({ loginVerifyedToken: responseJson.data[0]?.token, role: responseJson.data[0]?.role })
        setStorageData("loginToken", responseJson.data[0]?.token)
        setStorageData("userRole", responseJson.data[0]?.role);
        this.getUserProfile(responseJson.data[0]?.token);
      }
    }  else {
      window.alert('Enter correct OTP');
    }
  }

  handleLoginWithMobileResendAPI() {

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    let mobile = window.localStorage.getItem('mobileToResend');

    const data = {
      attributes: {
        full_phone_number: '91' + mobile
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LoginWithMobileResendApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LoginWithMobileResendApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  LoginWithMobileResendApiIDResponse(responseJson: any) {
    if (responseJson.meta) {
      window.alert('New OTP Has been Sent to you');
      this.otpTimer()
      window.localStorage.setItem('loginResendToken', responseJson.meta.token);
      this.setState({ loginResendVerifyedToken: responseJson.meta.token })
    } else {
      window.alert('Something wend wrong')
    }
  }

  LoginWithEmailResendApiIDResponse(responseJson: any) {
    if (responseJson.meta) {
      window.alert('New OTP Has been Sent to your Email');
      this.otpTimer()
      window.localStorage.setItem('loginEmailResendToken', responseJson.meta.token);
      this.setState({ loginEmailResendVerifyedToken: responseJson.meta.token })
    } else {
      window.alert('Something wend wrong')
    }
  }


  handleLoginWithMobileResendverifyAPI() {

    let tk = window.localStorage.getItem('loginResendToken');
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": tk
    };

    let otpValue = this.state.otp && this.state.otp.join('');

    const data = {
      attributes: {
        otp: otpValue,
        device_id: window.navigator.userAgent
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LoginWithMobileResendOtpverifyApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginOtpVarificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMobileResendOtpVerificationResponse(responseJson: any) {
    if (responseJson.meta === 'Mobile Otp verified') {
      this.setState({ showLoginWithMobileSuccess: true, role: responseJson.data[0]?.role, });
      setStorageData("loginToken", responseJson.data[0]?.token)
      setStorageData("userRole", responseJson.data[0]?.role);
      this.getUserProfile(responseJson.data[0].token);
    } else {
      window.alert('Enter correct otp');
    }
  }


  handleEmailResendOtpVerificationResponse(responseJson: any) {
    if (responseJson.meta === 'Email  Otp verified') {
      this.setState({ showLoginSuccess: true });
      this.setState({ resendOtpToken: responseJson?.data[0]?.token, role: responseJson.data[0]?.role })
    } else {
      window.alert('Enter correct otp');
    }
  }

  handleLoginOtpValidate() {
    if (this.state.loginResendVerifyedToken) {
      this.handleLoginWithMobileResendverifyAPI()
    } else {
      let token = window.localStorage.getItem('otpToken');
      this.handleLoginWithMobileOtpVerifyAPI(token)
    }
  }

  handleLoginWithEmailOtpValidate() {
    if (this.state.loginEmailResendVerifyedToken) {
      this.handleLoginWithEmailResendverifyAPI()
      this.setState({ showLoginSuccess: true })
    } else {
      let token = window.localStorage.getItem('emailOTPToken')
      this.handleLoginWithEmailOtpVerifyAPI(token)
    }
  }


  handleLoginWithEmailResendAPI() {

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    let email = window.localStorage.getItem('loginEmail');

    const data = {
      attributes: {
        email: email
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LoginWithEmailResendApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LoginWithEmailResendApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleFirstName() {
    this.setState({ otp: ["true"] })
  }
  handleStateName() {
    this.setState({ emailTologIn: "false" })
  }
  handleCityName() {
    this.setState({ test: false })
  }
  handleLoginWithEmailResendverifyAPI() {

    let tk = window.localStorage.getItem('loginEmailResendToken');

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": tk
    };

    let otpValue = this.state.emailOtp && this.state.emailOtp.join('');

    const data = {
      attributes: {
        otp: otpValue,
        device_id: window.navigator.userAgent
      }
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LoginWithEmailResendOtpverifyApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWithEmailOtpvarification
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginWithMobileApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  loginWithGoogleResponse(responseJson: any) {
    if (responseJson?.data) {

      this.setState({ haveEmailAccout: true, showLoginSuccess: true, showEmailOtpScreen: true })
      this.setState({ loginVerifyedToken: responseJson.meta.token, role: responseJson?.data.attributes.role_name })
      setStorageData("loginToken", responseJson.meta?.token)
      setStorageData("userRole", responseJson?.data.attributes.role_name);
      this.getUserProfile(responseJson.meta.token);
    } else if(responseJson.errors === "Account already registered. Please use the registered email to login.") {
      window.alert(configJSON.errorsMsgForGoogleLogin)
    } else {
      window.alert('Some Went wrong ! Try again')
    }
  }

  getUserProfile = (token: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileIconApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileInfoApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfileResponse = (response: ProfileResponse) => {
    if (response && response.data && response.data.attributes) {
      setTimeout(() => {
        this.handleClose()
        }, 1000)
      setStorageData("profilePic", response.data.attributes.photo);
      setStorageData("profileUsername", response.data.attributes.full_name);
      setStorageData("profilePhoneNumber", response.data.attributes.full_phone_number);
      setStorageData("cartCount",response.meta.total_cart_items);
      setStorageData("wishlistCount",response.meta.wishlists_items);
    }
  }

  handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        const user: any = result.user;
        this.handleGoogleAuthLogin(user.uid, user.email, user.displayName,user.phoneNumber,user.photoURL);
      })
      .catch((error) => {
        window.alert('something went wrong !');

      });
  };

  otpTimer() {
    clearInterval(this.timer);
    this.setState({ seconds: 300 }, () => {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
        if (this.state.seconds == 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    });
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  handleEmailTest() {
    this.setState({ test: true })
  }
  handleMobileTest() {
    this.setState({ test: false })
  }
  handleUserNameTest() {
    this.setState({ test: false })
  }
  goTermsAndCondition() {
    window.open("/HelpCenter/termsandcondition", "_blank")
  }
  navigateToPravicyPolicy() {
    window.open("/HelpCenter/privacypolicy", "_blank")
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.validationApiCallId:
          const regexData = responseJson?.data?.[0];
          if (regexData && regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
          }

          break;

        case this.profileIconApiCallId:

          this.getUserProfileResponse(responseJson);
          break;

        case this.apiEmailLoginCallId:

          this.handleResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
          break;

        case this.createLoginWithMobile:

          this.handleLoginWithMobileResponse(responseJson)
          break;

        case this.loginWithMobileOTPVarification:

          this.handleMobileOtpVerificationResponse(responseJson)
          break;

        case this.createloginwithEmailId:

          this.handleLoginWithEmailResponse(responseJson);
          break;

        case this.loginWithEmailOTPVarificationid:

          this.handleEmailOtpVerificationResponse(responseJson)
          break;

        case this.loginWithGoodleAccountId:

          this.loginWithGoogleResponse(responseJson);
          break;

        case this.LoginWithMobileResendApiID:
          this.LoginWithMobileResendApiIDResponse(responseJson);
          break;

        case this.LoginWithMobileResendOtpverifyApiID:

          this.handleMobileResendOtpVerificationResponse(responseJson)
          break;

        case this.LoginWithEmailResendApiID:

          this.LoginWithEmailResendApiIDResponse(responseJson)
          break;

        case this.LoginWithEmailResendOtpverifyApiID:

          this.handleEmailResendOtpVerificationResponse(responseJson)
          break;

        default:
          // Handle default case if needed
          break;
      }

    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
