Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PaymentAdmin";
exports.labelBodyText = "PaymentAdmin Body";

exports.btnExampleTitle = "CLICK ME";
exports.getOrderManagementApiEndpoint = "bx_block_payment_admin/admin_order_management";
exports.tokenExpiredMessage = "Token has Expired";
exports.getPaymentManagementApiEndpoint = "bx_block_payment_admin/admin_payment_management";
exports.getRazorpayTransactionsApiEndpoint = "bx_block_payment_admin/admin_razorpay_transaction";
exports.sessionExpireMsg = "Session is expired. Please login again.";
exports.purchaseHistoryApiEndPoint = "/bx_block_cfrazorpayintegration64/purchases_list"
exports.purchaseHistoryTitle = "Purchase History Report";
exports.purchaseHistorySubTitle = "Quickly see and review all your purchases on CHSE Express through our curated historical data for you.";
exports.filterText = "FILTER";
exports.coursesText = "Courses";
exports.enrollNumber = "Enrolled on";
exports.validateTillText = "Validity Till";
exports.totalPrice = "Total Price (INR)";
exports.statusText = "Status";
exports.ActionText = "Action";
exports.downloadInvoice = "Download Invoice";
exports.showMoreButton = "Show More";
exports.orderNotFound = "No orders found";
// Customizable Area End