import React, { Component } from 'react';
import { Box, Drawer, styled } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import {
  IoHomeOutline,
  IoPersonOutline,
  IoReorderThreeOutline,
  IoBookOutline,
  IoCashOutline,
  IoVideocamOutline,
  IoLocationOutline,
  IoLogOutOutline,
} from 'react-icons/io5';
import {
  HiOutlineBuildingLibrary,
  HiOutlineGlobeAlt,
  HiOutlinePresentationChartLine,
  HiOutlineCurrencyRupee,
  HiOutlineChatBubbleBottomCenterText,
} from 'react-icons/hi2';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineShoppingCart,
} from 'react-icons/md';
import { TbExternalLink, TbBulb } from 'react-icons/tb';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';
import { FaRegBell } from 'react-icons/fa';

// Customizable Area Start

// Importing images

const imageLogo = require('./imageLogo.png');
const imageLogoText = require('./imageLogoText.png');

interface Route {
  route: string;
  icon: JSX.Element;
  link: string;
  isTitle?: boolean;
  addRoute?: string;
  subLinks?: { route: string; link: string; addRoute?: string }[];
}

interface CustomDrawerProps {
  isVisible: boolean;
}

interface CustomDrawerState {
  openedSubLinkIndex: number;
}

const routes: Route[] = [
  {
    route: '/Dashboard',
    icon: <IoHomeOutline width={18} height={18} />,
    link: 'Dashboard'
  },
  {
    route: '/user',
    icon: <IoPersonOutline width={18} height={18} />,
    link: 'User',
    subLinks: [
      { route: '/EducationalUserProfile', link: 'Student' },
      { route: '/InstructorUserProfile', link: 'Instructor' },
      { route: '/AdminProfile', link: 'Admin' },
    ],
  },
  {
    route: '/landingPage',
    icon: <IoReorderThreeOutline width={18} height={18} />,
    link: 'Landing Page',
    subLinks: [
      { route: '/Achievements', link: 'Our Achievements' },
      {
        route: '/Footer',
        link: 'Footer'
      },
      {
        route: '/HeaderBanner',
        link: 'Mobile Top Banners',
        addRoute: '/AddHeaderBanner'

      },
      {
        route: '/WebHeaderBanner',
        link: 'Web Top Banners',
        addRoute: '/AddWebHeaderBanner'
      },
      { route: '/MobileFooterBanner', 
      link: 'Mobile Footer Banners', 
      addRoute: '/AddMobileFooterBanner'
    },
      { 
        route: '/Banner', 
        link: 'Web Footer Banners',
        addRoute: '/AddBanner'
      },
    ],
  },
  {
    route: '/course',
    icon: <IoBookOutline width={18} height={18} />,
    link: 'Course',
    subLinks: [
      { route: '/AdminCourses', link: 'Main Courses', addRoute: '/AddAdminCourse' },
      {
        route: '/ContentManagement',
        link: 'Course Chapters'
      },
      {
        route: '/admincourseclasses',
        link: 'Course Classes'
      },
    ],
  },
  {
    route: '/BundleCourse',
    icon: <IoVideocamOutline width={18} height={18} />,
    link: 'Bundle Course',
    addRoute: '/AddBundleCourse'
  },
  {
    route: '/admininstitutes',
    icon: <HiOutlineBuildingLibrary width={18} height={18} />,
    link: 'Institute'
  },
  {
    route: '/AdminLanguage',
    icon: <HiOutlineGlobeAlt width={18} height={18} />,
    link: 'Language'
  },
  {
    route: '/discount',
    icon: <IoCashOutline width={18} height={18} />,
    link: 'Discount',
    subLinks: [
      {
        route: '/Vouchercodegenerator',
        link: 'Voucher Code',
        addRoute: '/AddVouchercodegenerator'
      },
      {
        route: '/Couponcodegenerator',
        link: 'Coupon',
        addRoute: '/AddCouponcodegenerator'
      },
      { route: '/Referral', link: 'Referral' },
      {
        route: '/FlatDiscount',
        link: 'Flat Discount',
        addRoute: '/AddFlatDiscount'
      },
    ],
  },
  {
    route: '/report',
    icon: <HiOutlinePresentationChartLine width={18} height={18} />,
    link: 'Report',
    subLinks: [
      { route: '/DailyReport', link: 'Daily Report' },
      {
        route: '/CustomReport',
        link: 'Custom Report'
      },
    ],
  },
  {
    route: '/locations',
    icon: <IoLocationOutline width={18} height={18} />,
    link: 'Locations',
    subLinks: [
      { route: '/AdminStateList', link: 'State' },
      {
        route: '/AdminDistrictList',
        link: 'District',
        addRoute: '/AddAdminDistrict'
      },
    ],
  },
  {
    route: '/category',
    icon: <IoReorderThreeOutline width={18} height={18} />,
    link: 'Category',
    subLinks: [
      {
        route: '/admincategory',
        link: 'Main Category',
        addRoute: '/addadmincategory'
      },
      {
        route: '/adminsubcategory',
        link: 'Sub Category',
        addRoute: '/addadminsubcategory'
      },
      {
        route: '/adminchildcategory',
        link: 'Child Category',
        addRoute: '/addadminchildcategory'
      },
    ],
  },
  { route: '', icon: <></>, link: 'FINANCIAL', isTitle: true },
  {
    route: '/OrderManagement',
    icon: <MdOutlineShoppingCart width={18} height={18} />,
    link: 'Order Management'
  },
  {
    route: '/PaymentManagement',
    icon: <IoCashOutline width={18} height={18} />,
    link: 'Payment Management'
  },
  {
    route: '/RazorpayTransactions',
    icon: <HiOutlineCurrencyRupee width={18} height={18} />,
    link: 'Razorpay Transactions'
  },
  { route: '', icon: <></>, link: 'CONTENT', isTitle: true },
  {
    route: '/Testimonials',
    icon: <HiOutlineChatBubbleBottomCenterText width={18} height={18} />,
    link: 'Testimonials'
  },
  {
    route: '/AdminReviewsAndRatings',
    icon: <HiOutlineChatBubbleBottomCenterText width={18} height={18} />,
    link: 'Reviews'
  },
  {
    route: '/Notifications',
    icon: <FaRegBell width={18} height={18} />,
    link: 'Notification',
    subLinks: [
      {
        route: '/PushNotifications',
        link: 'Push Notifications',
        addRoute: '/AddPushNotifications'
      },
      { 
        route: '/EmailNotifications', 
        link: 'Email Notifications',
        addRoute: '/AddEmailNotifications'
      },
    ],
  },
  {
    route: '/AboutUs',
    icon: <TbExternalLink width={18} height={18} />,
    link: 'About Us',
    addRoute: '/AddAboutUs'
  },
  {
    route: '/Faq',
    addRoute: '/AddFaq',
    icon: <AiOutlineQuestionCircle width={18} height={18} />,
    link: 'FAQ’s'
  },
  // {
  //   route: '/ContactUs',
  //   icon: <FiPhone width={18} height={18} />,
  //   link: 'Contact Us'
  // },
  { route: '', icon: <></>, link: 'SUPPORT', isTitle: true },
  {
    route: '/HelpCenter',
    icon: <TbBulb width={18} height={18} />,
    link: 'Help Center',
    subLinks: [
      {
        route: '/CancellationPolicy',
        link: 'Cancellation Policies',
        addRoute: '/AddCancellationPolicy'
      },
      { route: '/HowItWorks', link: 'How It Works',addRoute: '/AddHowItWork' },
      {
        route: '/TermsAndConditions',
        link: 'Terms & Conditions',
        addRoute: '/AddTermsAndConditions'
      },
      {
        route: '/AdminPrivacyPolicy',
        link: 'Privacy Policies',
        addRoute: '/AddAdminPrivacyPolicy'
      },
      {
        route: '/RefundPolicy',
        link: 'Refund Policies',
        addRoute: '/AddRefundPolicy'
      },
    ],
  },
];

class CustomDrawer extends Component<CustomDrawerProps, CustomDrawerState> {
  logoutUserApiCallId: string = '';

  constructor(props: CustomDrawerProps) {
    super(props);

    this.state = {
      openedSubLinkIndex: -1,
    };
  }

  componentDidMount() {
    this.scrollToCurrentScreen();
  }

  scrollToCurrentScreen() {
    const currentPage = window.location.pathname;

    routes.forEach(link => {
      const trimLink = link.link.replace(/\s+/g, '');
      const hasSubLinks = link.subLinks && link.subLinks.length > 0,
        isCurrentScreen =
          currentPage &&
          (hasSubLinks
            ? link.subLinks!.some(
                l => l.route === currentPage || l.addRoute === currentPage
              )
            : currentPage === link.route || currentPage === link.addRoute);
      if (!isCurrentScreen) {
        return;
      }

      const linkButton = document.querySelector(`#link_${trimLink}`);
      linkButton?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  handleSubLinkClick = (index: number) => {
    this.setState(prevState => ({
      openedSubLinkIndex: prevState.openedSubLinkIndex === index ? -1 : index,
    }));
  };

  renderLink = (link: Route, index: number, currentPage: string) => {
    const { openedSubLinkIndex } = this.state;
    const hasSubLinks = link.subLinks && link.subLinks.length > 0;
    const trimLink = link.link.replace(/\s+/g, '');
    let isActive =
      openedSubLinkIndex === index ||
      (currentPage &&
        (hasSubLinks
          ? link.subLinks!.some(
              l => l.route === currentPage || l.addRoute === currentPage
            )
          : currentPage === link.route || currentPage === link.addRoute));

    if (link.isTitle) {
      return (
        <div
          key={index}
          style={{
            marginBottom: 15,
            marginTop: 15,
            fontWeight: 'bold',
            fontSize: 13,
          }}
        >
          <span style={{ marginLeft: 20 }}>{link.link}</span>
        </div>
      );
    }

    return (
      <div key={index} id={`link_${trimLink}`}>
        {hasSubLinks ? (
          <Box
            className={`side_link_box ${isActive ? 'active-link' : ''}`}
            onClick={() => this.handleSubLinkClick(index)}
          >
            <Box
              className={`side_txt_box ${isActive ? 'active-link' : ''}`}
              style={{ width: '100%' }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {link.icon}
                <span
                  className={`side_link_txt ${isActive ? 'active-link' : ''}`}
                >
                  {link.link}
                </span>
              </Box>
              <MdOutlineKeyboardArrowDown
                style={{
                  transform: !isActive ? 'none' : 'rotate(180deg)'
                }}
              />
            </Box>
          </Box>
        ) : (
          <Link
            onClick={() => this.handleSubLinkClick(index)}
            to={link.route}
            className={`side_link_box ${isActive ? 'active-link' : ''}`}
          >
            <Box
              className={`side_txt_box ${isActive ? 'active-link' : ''}`}
              style={{ width: '100%' }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {link.icon}
                <span
                  className={`side_link_txt ${isActive ? 'active-link' : ''}`}
                >
                  {link.link}
                </span>
              </Box>
            </Box>
          </Link>
        )}
        {hasSubLinks && isActive && (
          <div style={{ backgroundColor: '#F1F5F9' }}>
            {link.subLinks!.map((subLink, subIndex) => (
              <Link
                to={subLink.route}
                onClick={() => {
                  localStorage.setItem('selectedSubLink', subLink.link);
                }}
                className={`side_link_sub_box ${
                  subLink.route === currentPage ||
                  subLink.addRoute === currentPage
                    ? 'active-sub-link'
                    : ''
                }`}
                key={subIndex}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '15px',
                    columnGap: '15px'
                  }}
                >
                  <span className={'side_link_txt'}>{subLink.link}</span>
                </Box>
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isVisible } = this.props;

    return (
      <CustomSideBar open={isVisible} variant="persistent">
        <Box className="side_img_box">
          <img src={imageLogo} alt="bgImg" style={{ marginRight: '14px' }} />
          <img src={imageLogoText} alt="bgImg" />
        </Box>
        <Box className="links_container">
          {routes.map((link, index) =>
            this.renderLink(link, index, window.location.pathname)
          )}
        </Box>
      </CustomSideBar>
    );
  }
}

const CustomSideBar = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '250px'
  },
  '& .side_img_box': {
    height: '70px',
    marginTop: '20px',
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    '& .side_head_txt': {
      fontSize: '16px',
      fontWeight: 'bold',
      marginRight: '15px'
    },
    '& img': {
      width: '30%',
      height: '90%',
      objectFit: 'contain'
    },
  },
  '& .links_container': { marginTop: '10px', marginLeft: '10px' },
  '& .side_link_sub_box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    columnGap: '15px',
    textDecoration: 'none',
    color: 'inherit',
    padding: '12px 35px',
    userSelect: 'none',
    '&.active-sub-link': {
      '& .side_link_txt': { fontWeight: 'bold', color: '#FD7100' },
    },
    '&.side_link_sub_box:hover': {
      backgroundColor: '#cfdcea'
    },
    '& .side_link_txt': {
      marginLeft: '14px',
      textDecoration: 'none',
      color: '#475569',
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      '&.active-link': {
        color: 'white'
      },
    },
  },
  '& .side_link_box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 20px',
    cursor: 'pointer',
    textDecoration: 'none',
    userSelect: 'none',
    '&.active-link': {
      backgroundColor: '#FD7100',
      color: 'white'
    },
    '&.side_link_box:hover:not(.active-link)': {
      backgroundColor: '#F1F5F9',
      color: '#475569',
      '& .side_txt_box': {
        color: '#475569'
      },
      '& .side_link_txt': {
        color: '#475569',
        '&.active-link': {
          color: '#475569'
        },
      },
    },
    '& .side_txt_box': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#475569',
      '&.active-link': {
        color: 'white'
      },
    },
    '& .side_link_txt': {
      marginLeft: '14px',
      textDecoration: 'none',
      color: '#475569',
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      '&.active-link': {
        color: 'white'
      },
    },
  },
});

export default CustomDrawer;
